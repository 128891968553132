import React, { useCallback, useState, createRef, useMemo } from 'react';
import moment from 'moment';
import {
  Button,
  GoBackButton,
  ListField,
  Loader,
  PizzaProgress,
  PlainField,
  Spacer,
  WrapperCard,
} from 'components/elements';

import { Milestone, MilestoneEvolution } from '../types';
import {
  ErrorDialog,
  Grid,
  Modal,
  ModalPDFViewer,
  Row,
  ScrollPanel,
} from 'components/containers';
import { Smooth2DGraph } from 'components/elements';
import { useFetch, useRequest } from 'apis';
import { useTranslationX } from 'i18n';
import { Form, FileUploader } from 'components/form';
import { Download, Eye } from 'components/icons';
import { getYearAndMonth } from 'utils/calendarUtils';

import { FormInstance } from 'components/form/types';
import { useHistory, useParams } from 'react-router-dom';
import { Profile } from 'pages/register/profile';
import { useAuthState } from 'store/auth';

import styles from './MilestoneEditor.module.scss';

const MilestoneEditor: React.FC = () => {
  const { tx } = useTranslationX('milestoneEditor', 'project');
  const { id, milestoneId } = useParams<{ id: string; milestoneId: string }>();

  const history = useHistory();
  const { profile } = useAuthState();

  const isResponsible = profile !== Profile.SUPERVISOR;

  const [error, setError] = useState<string | undefined>();
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [visible, setVisible] = useState(false);

  const form = createRef<FormInstance>();

  const { data: milestone, reload } = useFetch<Milestone>(
    `/projeto/${id}/macroentregas/${milestoneId}`
  );

  const { data: evolution = [] } = useFetch<MilestoneEvolution[]>(
    `/projeto/${id}/macroentregas/${milestoneId}/evolucao`
  );

  const isInAnalyzing = milestone?.estado === 'ANS';

  const request = useRequest();

  const handleClosePopup = () => {
    setError(undefined);
  };

  const sendDeclaracao = useCallback(
    (type = 'enviar') => {
      if (!isResponsible) {
        return;
      }
      const file = form.current?.context.getFieldValue('termo') as File[];
      let formData = new FormData();
      if (type === 'enviar') {
        formData.append('file', file[0]);
      }

      const source = request<any>({
        url: `/projeto/${id}/macroentregas/${milestoneId}/aceite`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          reload();
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
          history.goBack();
        },
        onError: data => console.error(data),
      });

      return () => source.cancel();
    },
    [isResponsible, form, request, id, milestoneId, reload, history]
  );

  const downloadDeclaracao = useCallback(() => {
    const source = request<any>({
      url: `/projeto/${id}/macroentregas/${milestoneId}/aceite`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
          setVisible(true);
        };
      },
      onError: data => console.error(data),
    });

    return () => source.cancel();
  }, [id, milestoneId, request]);

  const handleDownload = useCallback(
    (token: string) => {
      request<any>({
        url: `/resource/${token}`,
        method: 'GET',
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
            setVisible(true);
          };
        },
        onError: data => console.error(data),
      });
    },
    [request]
  );

  const getArchive = useCallback(
    (id: number) => {
      request<string>({
        url: `/arquivo/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'string',
        },
        onSuccess: handleDownload,
      });
    },
    [request, handleDownload]
  );

  const fileActions = useMemo(() => {
    if (!milestone) {
      return null;
    }
    const hasArchive = milestone?.arquivoTermo?.id;

    if (hasArchive) {
      return (
        <div className={styles.buttonHeader}>
          {isResponsible && !isInAnalyzing ? (
            <Button
              style={{
                root: { backgroundColor: '#fff', paddingLeft: 0 },
                text: { color: '#E61616', paddingLeft: '10px' },
              }}
              icon={Download}
              iconProps={{ color: '#E61616' }}
              onClick={() => sendDeclaracao('cancelar')}
            >
              {tx('cancelAceite')}
            </Button>
          ) : null}

          <Button
            style={{
              root: { backgroundColor: '#fff', color: '#333333' },
              text: { color: '#333333', paddingLeft: '10px' },
            }}
            icon={Eye}
            onClick={() => getArchive(milestone.arquivoTermo?.id as number)}
          >
            {tx('verAceiteEnviado')}
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.buttonHeader}>
        <Button
          style={{ root: { backgroundColor: '#fff' }, text: { color: '#333333' } }}
          icon={Download}
          onClick={() => downloadDeclaracao()}
        >
          {tx('aceiteDownload')}
        </Button>

        {!isInAnalyzing && isResponsible ? (
          <Form>
            <AceiteUploader isResponsible={isResponsible} reload={reload} />
          </Form>
        ) : null}
      </div>
    );
  }, [
    milestone,
    tx,
    isInAnalyzing,
    isResponsible,
    sendDeclaracao,
    getArchive,
    downloadDeclaracao,
    reload,
  ]);

  if (!milestone) {
    return <Loader type="spin" />;
  }
  const getDeliverables = () => {
    const deliverables = milestone.entregaveis
      .sort((a, b) => a.ordem - b.ordem)
      .map(e => e.titulo);
    return deliverables.length > 0 ? deliverables : ['???'];
  };

  const timestampStart = moment(milestone.inicio).set('date', 15).toDate().getTime();
  const timestampEnd = moment(milestone.dataEntrega).set('date', 15).toDate().getTime();

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <ErrorDialog
        visible={error !== undefined}
        title={tx('error.title')}
        message={error}
        volatile
        onClose={handleClosePopup}
        onBlur={handleClosePopup}
      />
      <Spacer direction="vertical" length={20} />
      <ScrollPanel>
        <div className={styles.milestoneEditor}>
          <div className={styles.header}>
            <div className={styles.backButton}>
              <GoBackButton />
            </div>

            <h1 className={styles.title}>
              {tx('name', {
                name: milestone.titulo,
              })}
            </h1>
          </div>
          {fileActions}

          <Modal visible={visible} onBlur={handleClose} onClose={handleClose}>
            <ModalPDFViewer
              pdfData={pdfData}
              onClose={handleClose}
              title={tx('aceiteMacro')}
            />
          </Modal>
          <WrapperCard padding="15px 20px 20px">
            <Grid spacing={0}>
              <Row width={[0.4, 0.3, 0.2, 0.2]} align="center">
                <PlainField
                  label={tx('name', {
                    ordem: milestone.ordem + 1,
                  })}
                  value={milestone.titulo}
                  padding="none"
                />
                <PlainField
                  label={tx('duration')}
                  value={tx('months', {
                    months: milestone.duracao,
                  })}
                  padding="none"
                />

                <div className={styles.conclusionGraph}>
                  <div className={styles.legend}>
                    <h3>{tx('conclusionPercentage')}</h3>
                    <strong>
                      {tx('conclusion', {
                        value: milestone.conclusao || '00',
                      })}
                    </strong>
                  </div>
                  <PizzaProgress value={milestone.conclusao || 0} size={90} />
                </div>
              </Row>
              <Spacer length={20} />
              <Row width={[6, 4]} align="top">
                <ListField
                  label={tx('deliverables')}
                  values={getDeliverables()}
                  padding="none"
                />
              </Row>
            </Grid>
            <h2 className={styles.sectionTitle}>{tx('progressHistory')}</h2>

            <Smooth2DGraph
              data={evolution
                .map(e => ({
                  y: e.conclusao,
                  id: e.id,
                  x: moment(e.data).set('date', 15).toDate().getTime(),
                }))
                .sort((a, b) => a.x - b.x)}
              scales={{
                y: {
                  min: 0,
                  max: 100,
                },
                x: {
                  ticks: milestone.duracao,
                  min: timestampStart,
                  max: timestampEnd,
                },
              }}
              parser={{
                x(value) {
                  return getYearAndMonth(value);
                },
              }}
              style={{
                width: 968,
                height: 201,
              }}
            />
          </WrapperCard>
        </div>
      </ScrollPanel>
    </>
  );
};

type AceiteUploaderProps = {
  isResponsible: boolean;
  reload: () => void;
};

const AceiteUploader = ({ isResponsible, reload }: AceiteUploaderProps) => {
  const { tx } = useTranslationX('milestoneEditor', 'project');
  const { id, milestoneId } = useParams<{ id: string; milestoneId: string }>();

  const request = useRequest();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [file, setFile] = useState<File>();

  const openAceiteUpload = useCallback((data: File[]) => {
    const file = data[0];

    setFile(file);

    file.arrayBuffer().then(arrayBuffer => {
      setPdfData(arrayBuffer);
      setVisible(true);
    });
  }, []);

  const handleClose = () => {
    setVisible(false);
    setPdfData(null);
    setFile(undefined);
  };

  const sendDeclaracao = useCallback(
    (type = 'enviar') => {
      if (!isResponsible || !file) {
        return;
      }
      let formData = new FormData();
      if (type === 'enviar') {
        formData.append('file', file as File);
      }

      const source = request<any>({
        url: `/projeto/${id}/macroentregas/${milestoneId}/aceite`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          reload();
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
          history.goBack();
        },
        onError: data => console.error(data),
      });

      return () => source.cancel();
    },
    [isResponsible, request, id, milestoneId, reload, history, file]
  );

  return (
    <>
      <FileUploader
        name="termo"
        type="button"
        message={tx('aceiteUpload')}
        metadata={{ rules: { accept: ['pdf'], maxfiles: 1, filesize: 5242880 } }}
        onFileUpload={openAceiteUpload}
      />
      <Modal visible={visible} onBlur={handleClose} onClose={handleClose}>
        <ModalPDFViewer
          pdfData={pdfData}
          onClose={handleClose}
          title={tx('aceiteMacro')}
          onCancel={handleClose}
          onUpload={() => sendDeclaracao('enviar')}
        />
      </Modal>
    </>
  );
};

export default MilestoneEditor;
