import { createContext } from 'react';

import { PaymentContextProps } from '../types';

const empty = () => {};

const PaymentContext = createContext<PaymentContextProps>({
  removePayment: empty,
  addPayment: empty,
  clearPayments: empty,
  payments: [],
  currentMonthPayments: [],
  defineCurrentMonthPayments: empty,
  contracts: {} as PaymentContextProps['contracts'],
  reset: empty,
  vinculos: '',
  updateVinculos: empty,
});

PaymentContext.displayName = 'PaymentContext';

export { PaymentContext };
