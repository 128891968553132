import React, { ReactNode } from 'react';

import { classes } from 'utils/components';
import styles from './Grid.module.scss';

type Props = {
  width?: number | string;
  className?: string;
  minWidth?: number | string;
  maxWidth?: number | string;
  spacing?: number | string;
  children?: ReactNode;
  gap?: number | string;
};

const Grid = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { width, minWidth, maxWidth, className, spacing = 2, children, gap } = props;

  return (
    <div
      ref={ref}
      className={classes(styles.grid, className)}
      style={{ width, minWidth, maxWidth, padding: spacing, gap }}
    >
      {children}
    </div>
  );
});

export default Grid;
