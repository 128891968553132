import React from 'react';

const Chart = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size - 0.2}em`}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08301 18.82C9.08301 19.46 8.49301 19.94 7.87301 19.8C3.40301 18.8 0.0830078 14.79 0.0830078 10C0.0830078 5.21002 3.40301 1.20002 7.87301 0.200015C8.49301 0.0600153 9.08301 0.540015 9.08301 1.18002V18.82ZM11.113 1.18002V7.99002C11.113 8.54002 11.563 8.99002 12.113 8.99002H18.903C19.543 8.99002 20.023 8.40001 19.883 7.77002C19.033 4.01002 16.083 1.05002 12.333 0.200015C11.703 0.0600153 11.113 0.540015 11.113 1.18002ZM11.113 18.82V12.01C11.113 11.46 11.563 11.01 12.123 11H18.913C19.543 11 20.033 11.6 19.893 12.22C19.043 15.98 16.093 18.95 12.333 19.8C11.703 19.94 11.113 19.46 11.113 18.82Z"
      fill="currentColor"
    />
  </svg>
);

export default Chart;
