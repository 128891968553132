import React from 'react';

const Expenditure = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 5.49986L12.0355 1.96436L15.571 5.49986L12.0355 9.03536L8.5 5.49986Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 11.9999L18.5355 8.46436L22.071 11.9999L18.536 15.5354L15 11.9999Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 11.9999L5.5355 8.46436L9.071 11.9999L5.536 15.5354L2 11.9999Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 18.4999L12.0355 14.9644L15.571 18.4999L12.036 22.0354L8.5 18.4999Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <g mask="url(#mask0)">
      <rect width={`${size}em`} fill="currentColor" />
    </g>
  </svg>
);

export default Expenditure;
