import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'store/auth';
import { PartData, RoleItem } from 'pages/project/types';

import { WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { ScrollPanel } from 'components/containers';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { CardRoleHire } from 'pages/project';
import { Profile } from 'pages/register/profile';

import styles from './RolesList.module.scss';

type Props = {
  customAction?(role: RoleItem): void;
  item: PartData;
  padding?: string;
  fullWidth?: boolean;
};

const RolesList: React.FC<Props> = props => {
  const { item, customAction, padding = '15px 20px 20px', fullWidth } = props;
  const { dispatch } = useProjectEditor();

  const { profile } = useAuthState();

  const isResponsible = profile !== Profile.SUPERVISOR;
  const history = useHistory();

  const { tx } = useTranslationX('rolesCards', 'project');

  const roles = ((item.ucs || []) as RoleItem[]).filter(
    r => r.status !== 'FIN' && r.status !== 'CON'
  );

  const getProfileType = (role: RoleItem) => {
    const studentCategories = [
      'Graduando',
      'Mestrando',
      'Doutorando',
      'Estudante Técnico',
      'Estudante FIC',
      'Aluno Técnico',
      'Estudante de especialização',
      'Aluno Especialização',
      'Estudante especialização',
    ];
    const isCLT = role.tipo.includes('CLT');

    const isStudent =
      studentCategories.some(category =>
        role.categoria.toLowerCase().includes(category.toLowerCase())
      ) || isCLT;

    return isCLT ? 'CLT' : !isStudent ? 'Servidor' : 'Estudante';
  };

  if (roles?.length) {
    return (
      <WrapperCard
        padding={padding}
        maxWidth={!fullWidth ? 'calc(100% - 125px)' : undefined}
      >
        <div className={styles.rolesList}>
          <div className={styles.header}>
            <span>{tx('title')}</span>
            <strong>{tx('subtitle')}</strong>
          </div>
          <ScrollPanel
            vBar={{ overlay: true }}
            style={{ content: { maxHeight: '400px' } }}
          >
            <div className={styles.grid}>
              {roles
                .sort((a, b) => a.ordem - b.ordem)
                .map((role, indexItem) => (
                  <CardRoleHire
                    showHiringRequest={isResponsible}
                    key={indexItem}
                    alteracao_exclusao={role.alteracao_exclusao}
                    role={role}
                    onItemSelected={() => {
                      if (customAction) {
                        customAction(role);
                      } else {
                        dispatch({ type: 'SET_ITEM', payload: role });
                        history.push(history.location.pathname + '/solicitacao', {
                          archivesUpload: role.status === 'EDT',
                          profileType: getProfileType(role),
                          id: role.id,
                        });
                      }
                    }}
                  />
                ))}
            </div>
          </ScrollPanel>
        </div>
      </WrapperCard>
    );
  }
  return null;
};

export default RolesList;
