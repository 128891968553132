import React from 'react';

import { Modal } from 'components/containers';

import styles from './ScheduleList.module.scss';
import { FileUploader, Form, SubmitButton } from 'components/form';
import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import { FormInstance } from 'components/form/types';
import { useParams } from 'react-router-dom';
import { StateButton } from '../ConclusionPanel/types';

type Props = {
  visible: boolean;
  onClose: () => void;
  state: StateButton;
  onSuccess: () => void;
};

export function Declaration({ visible, onClose, state, onSuccess }: Props) {
  const { tx } = useTranslationX('conclusionPanel', 'project');

  const request = useRequest();
  const form = React.createRef<FormInstance>();
  const { id: projectId } = useParams<{ id: string; contrapartidaId: string }>();

  const sendDeclaracao = () => {
    let formData = new FormData();
    const contratoId = state.conclusions[0].id.toString().split('-')[0];
    const mes = state.conclusions[0].mes;

    const file = (form.current?.context.getFieldValue('declaracao') as File[])[0];
    formData.append('declaracao', file);
    formData.append('mes', String(mes));

    request<any>({
      url: `/projeto/${projectId}/contrapartidas/${contratoId}/pdf/mensal`,
      method: 'PUT',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
      onSuccess,
      onError: data => console.error(data),
    });
  };

  return (
    <Modal visible={visible} width={400} onBlur={onClose} onClose={onClose}>
      <div className={styles.popup}>
        <Form ref={form} onSubmit={sendDeclaracao}>
          <FileUploader
            metadata={{
              rules: { accept: ['pdf'], maxfiles: 1, required: true, filesize: 5242880 },
            }}
            name="declaracao"
            type="sneaky"
            label={tx('makeUpload')}
            message={tx('makeUpload')}
          />
          <div>
            <SubmitButton autoDisable>{tx('send')}</SubmitButton>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
