import React from 'react';

const DocumentFile = ({ size = 1.2, color }: { size?: number; color?: string }) => {
  return (
    <svg
      width={`${size}em`}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6743_58107"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="12"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.59668 5.06303C6.98174 4.64056 7.50856 4.39893 8.0625 4.39893H13.0238C13.5694 4.399 14.0893 4.63361 14.473 5.04526L17.3867 8.1714C17.5822 8.38105 17.7364 8.63019 17.8413 8.90359C17.9462 9.17696 18 9.46978 18 9.76507V18.1589C18 18.7459 17.7877 19.3131 17.4033 19.7348C17.0183 20.1573 16.4914 20.3989 15.9375 20.3989H8.0625C7.50856 20.3989 6.98174 20.1573 6.59668 19.7348C6.21231 19.3131 6 18.7459 6 18.1589V6.63893C6 6.052 6.21231 5.48473 6.59668 5.06303ZM15.7819 8.71893L14.0625 6.87418V8.07893C14.0625 8.25582 14.1267 8.42109 14.2347 8.5395C14.3419 8.65713 14.4827 8.71893 14.625 8.71893H15.7819ZM12.5625 5.99893H8.0625C7.92024 5.99893 7.77939 6.06072 7.67217 6.17835C7.56425 6.29676 7.5 6.46203 7.5 6.63893V18.1589C7.5 18.3358 7.56425 18.5011 7.67217 18.6195C7.77939 18.7371 7.92024 18.7989 8.0625 18.7989H15.9375C16.0798 18.7989 16.2206 18.7371 16.3278 18.6195C16.4358 18.5011 16.5 18.3358 16.5 18.1589V10.3189H14.625C14.0711 10.3189 13.5442 10.0773 13.1592 9.65482C12.7748 9.23312 12.5625 8.66585 12.5625 8.07893V5.99893ZM9.28125 13.1189C9.28125 12.6771 9.61704 12.3189 10.0312 12.3189H13.9688C14.383 12.3189 14.7188 12.6771 14.7188 13.1189C14.7188 13.5608 14.383 13.9189 13.9688 13.9189H10.0312C9.61704 13.9189 9.28125 13.5608 9.28125 13.1189ZM9.28125 15.9989C9.28125 15.5571 9.61704 15.1989 10.0312 15.1989H12C12.4142 15.1989 12.75 15.5571 12.75 15.9989C12.75 16.4408 12.4142 16.7989 12 16.7989H10.0312C9.61704 16.7989 9.28125 16.4408 9.28125 15.9989Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_6743_58107)">
        <rect y="0.398926" width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default DocumentFile;
