import jwt from 'jwt-decode';

import {
  createAction,
  createAsyncThunk,
  createReducer,
  Middleware,
} from '@reduxjs/toolkit';

import { sippi } from 'apis';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

type JWTData = {
  id: number;
  pid?: number;
  profile?: string;
};

type Transition = 'selecting' | 'loading' | 'done';
type Profile = { profileName: string; profileId?: number; transition?: Transition };
type Prefs = { profile?: string };

export interface LoginResponse {
  token?: string;
  name?: string;
  userId?: number;
  personId?: number;
  profiles?: Profile[];
}

export interface State extends LoginResponse {
  status: 'loggedOut' | 'authenticated' | 'authorized';
  profile?: string;
  pid?: number;

  transitionStatus?: Transition;
  prefs: Prefs;
}

export const login = createAction<LoginResponse>('LOGIN');
export const logout = createAction('LOGOUT');
export const profileTransition = createAction<Transition | undefined>('TRANSITION');

export const refreshToken = createAsyncThunk('REFRESH_TOKEN', async () => {
  return (await sippi.post<LoginResponse>('/auth/refresh')).data;
});

export const setProfile = createAsyncThunk(
  'SET_PROFILE',
  async ({ profileName, profileId, transition }: Profile, { dispatch }) => {
    dispatch(profileTransition('loading'));
    const res = (
      await sippi.post<LoginResponse>('/auth/profile', { profileName, profileId })
    ).data;

    return { ...res, transition };
  }
);

const buildInitialState = (prefs?: Prefs): State => ({
  status: 'loggedOut',
  prefs: prefs || {},
});

const initialState = buildInitialState();

try {
  const prefs = localStorage.getItem('prefs');

  if (typeof prefs === 'string') {
    initialState.prefs = JSON.parse(prefs);
  }
} catch (_err) {}

try {
  const token = localStorage.getItem('auth');

  if (typeof token === 'string') {
    const { profile, pid } = jwt<JWTData>(token);

    initialState.profile = profile;
    initialState.pid = pid;
    initialState.token = token;

    sippi.defaults.headers.common.Authorization = token;
  }
} catch (_err) {}

const selector = (state: RootState) => state.auth;
export const useAuthState = () => useSelector(selector);

const actionTypes = [
  login.type,
  logout.type,
  refreshToken.fulfilled.type,
  refreshToken.rejected.type,
  setProfile.fulfilled.type,
  setProfile.rejected.type,
];

export const persistor: Middleware = store => next => action => {
  const result = next(action);

  if (actionTypes.includes(result.type)) {
    const { token, profile, prefs } = store.getState().auth as State;

    if (token) {
      localStorage.setItem('auth', token);
      sippi.defaults.headers.common.Authorization = token;
    } else {
      localStorage.removeItem('auth');
      delete sippi.defaults.headers.common.Authorization;
    }

    localStorage.setItem(
      'prefs',
      JSON.stringify({ ...prefs, profile: profile || prefs.profile })
    );
  }

  return result;
};

function setToken(state: State, res: LoginResponse & { transition?: Transition }): State {
  const { prefs } = state;
  const { token, name, profiles, transition, userId, personId } = res;

  if (token) {
    sippi.defaults.headers.common.Authorization = token;

    try {
      const { profile, pid } = jwt<JWTData>(token);

      return {
        userId,
        personId,
        token,
        name,
        profiles,
        profile,
        pid,
        status: profile ? 'authorized' : 'authenticated',
        transitionStatus: transition,
        prefs: { ...prefs, profile: profile || prefs.profile },
      };
    } catch (_err) {}
  }

  return buildInitialState(prefs);
}

export default createReducer(initialState, builder => {
  builder
    .addCase(login, (state, action) => setToken(state, action.payload))
    .addCase(logout, state => buildInitialState(state.prefs))
    .addCase(profileTransition, (state, action) => ({
      ...state,
      transitionStatus: action.payload,
    }))

    .addCase(refreshToken.rejected, state => buildInitialState(state.prefs))
    .addCase(refreshToken.fulfilled, (state, action) => setToken(state, action.payload))

    .addCase(setProfile.rejected, state => buildInitialState(state.prefs))
    .addCase(setProfile.fulfilled, (state, action) => setToken(state, action.payload));
});
