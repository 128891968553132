import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';

import {
  TextField,
  Select,
  MultiSelect,
  useFormObserver,
  Autocomplete,
  Checkbox,
  FieldSet,
  HiddenField,
  useForm,
} from 'components/form';

const Contributor = ({ index }: { index: number }) => {
  const { t } = useTranslation('register');

  const form = useForm();

  useFormObserver(`colaborador[${index}]`, true);

  const institution = useFormObserver(`colaborador[${index}].instituicao`, true);
  const bond = useFormObserver(`colaborador[${index}].bond`, true);

  const type1 = bond?.data?.value ? undefined : 'disabled';
  const type2 = institution?.data?.value === 1 ? undefined : 'disabled';

  return (
    <Grid>
      <FieldSet name="colaborador" index={index}>
        <HiddenField name="id" />

        <Row>
          <TextField name="areaFormacao" label={t('academic.major')} />
          <Select name="titulacao" label={t('researcher.level')} />
        </Row>

        <TextField name="lattes" label={t('academic.resume')} />

        <div>
          <Checkbox
            name="bond"
            label={t('contributor.bond')}
            omit={true}
            value={form.getFieldValue(`colaborador[${index}].instituicao`)}
          />
        </div>

        <Autocomplete
          url="/cadastro/instituicoes"
          name="instituicao"
          label={t('academic.institution')}
          type={type1}
        />

        <Row>
          <Select name="campus" label={t('researcher.campus')} type={type2} />
          <TextField name="siape" label={t('researcher.id')} type={type2} />
        </Row>

        <MultiSelect
          name="areaPesquisa"
          label={t('academic.areas')}
          modalTitle={t('academic.areas')}
        />
      </FieldSet>
    </Grid>
  );
};

export default Contributor;
