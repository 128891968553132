import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import { useAuthState } from 'store/auth';
import { messageService } from 'services';
import { API_SERVER_URL, UI_BUTTON_SPACING } from 'consts';
import { SuccessDialog, Modal, ModalPDFViewer, ErrorDialog } from 'components/containers';
import { Button, IconButton } from 'components/elements';
import { Form, SubmitButton, TextEditor } from 'components/form';
import { AddCheck, XRect, Replay, WarningAlt, PDF, Sei, XAlt } from 'components/icons';
import { Dispatch } from 'pages/proposal/ProposalEditor/types';
import { IconType, ProposalStatus } from 'pages/proposal/types';
import { Profile } from 'pages/register/profile';

import styles from './ActionButtons.module.scss';
export type Props = {
  id: number;
  state: ProposalStatus;
  title: string;
  simulacao: boolean;
  hasErrors?: boolean;
  dispatch: Dispatch;
  showErrors?: boolean;
};

export type ActionProps = {
  text: string;
  icon: (size: IconType) => JSX.Element;
  enable?: boolean;
  disabled?: boolean;
};

type Status =
  | 'confirm'
  | 'pre-confirm'
  | 'success'
  | 'init'
  | 'pdf'
  | 'pdf-financial'
  | 'sei'
  | 'error'
  | 'pdf-description';

const ActionButton: React.FC<Props> = props => {
  const { id, state, title, simulacao } = props;
  const { profile } = useAuthState();
  const [action, setAction] = useState<string>();
  const [status, setStatus] = useState<Status>('init');
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>();
  const history = useHistory();
  const request = useRequest();

  const anchorRef = useRef<HTMLDivElement>();

  const { t, tx } = useTranslationX('actions', 'proposal');

  useEffect(() => {
    if (status === 'confirm' && props.hasErrors === true) {
      setStatus('error');
    }
    if (
      status === 'pre-confirm' &&
      props.hasErrors === false &&
      props.hasErrors !== undefined
    ) {
      setStatus('confirm');
    }
  }, [props.hasErrors, status]);

  const supervisor: ActionProps[] = [
    ...(['ANS', 'ACC', 'APA'].includes(state)
      ? [{ text: 'check-errors', icon: WarningAlt, enable: props.showErrors }]
      : []),
    ...(['ACC', 'APA'].includes(state)
      ? [
          { text: 'approve', icon: AddCheck, disabled: props.hasErrors || simulacao },
          {
            text: 'approve-modifications',
            icon: Replay,
            disabled: props.hasErrors || simulacao,
          },
          { text: 'reprove', icon: XRect },
        ]
      : []),
    ...(['ANS'].includes(state)
      ? [
          { text: 'return', icon: Replay, disabled: props.hasErrors },
          { text: 'accept', icon: AddCheck, disabled: props.hasErrors || simulacao },
          { text: 'decline', icon: XRect },
        ]
      : []),
    ...(['APR'].includes(state) ? [{ text: 'contracted', icon: AddCheck }] : []),
    { text: 'sei', icon: Sei },
    { text: 'pdf', icon: PDF },
    { text: 'fin-pdf', icon: PDF },
    ...(['ACC'].includes(state) ? [{ text: 'pdf-description', icon: PDF }] : []),
  ];

  const pesquisador: ActionProps[] = [
    ...(['EDT', 'DEV', 'APE'].includes(state)
      ? [
          { text: 'check-errors', icon: WarningAlt, enable: props.showErrors },
          { text: 'submit', icon: AddCheck, disabled: props.hasErrors },
        ]
      : []),
    { text: 'pdf', icon: PDF },
  ];
  const actions = profile === Profile.SUPERVISOR ? supervisor : pesquisador;

  const handleAction = useCallback(
    (action: string) => {
      if (
        ['submit', 'approve', 'accept', 'return', 'approve-modifications'].includes(
          action
        )
      ) {
        props.dispatch({ type: 'SHOW_ERRORS', payload: true });
        if (props.showErrors === false || props.showErrors === undefined) {
          setStatus('error');
        }
        setStatus('pre-confirm');
        setAction(action);
      } else if (action !== 'check-errors' && action !== 'pdf' && action !== 'sei') {
        setStatus('confirm');
        setAction(action);
      }

      if (action === 'pdf') {
        setStatus('pdf');
      }

      if (action === 'fin-pdf') {
        setStatus('pdf-financial');
      }

      if (action === 'pdf-description') {
        setStatus('pdf-description');
      }

      if (action === 'sei') {
        setStatus('sei');
      }

      if (action === 'check-errors') {
        props.dispatch({ type: 'SHOW_ERRORS', payload: !props.showErrors });
      }
    },
    [props]
  );

  useEffect(() => {
    if (status === 'pdf') {
      const source = request({
        url: `${API_SERVER_URL}/proposta/${id}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
        },
        onError: () => null,
      });

      return () => source.cancel();
    } else if (status === 'pdf-financial') {
      const source = request({
        url: `${API_SERVER_URL}/proposta/${id}/pdf-financial`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
        },
        onError: () => null,
      });
      return () => source.cancel();
    } else if (status === 'pdf-description') {
      const source = request({
        url: `${API_SERVER_URL}/proposta/${id}/pdf-description`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
        },
        onError: () => null,
      });

      return () => source.cancel();
    } else if (status === 'sei') {
      const source = request({
        url: `${API_SERVER_URL}/proposta/${id}/html`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'text',
        onSuccess: async (text: any) => {
          await navigator.clipboard.writeText(text);
          messageService.success(tx('success-copied-message'), {
            duration: 3000,
          });
        },
        onError: () => {
          messageService.error(tx('error-copied-message'), {
            duration: 3000,
          });
        },
        onComplete: () => {
          setStatus('init');
        },
      });
      return () => source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, request, status]);

  const onClose = () => {
    setStatus('init');
    setAction(undefined);
  };

  const renderPopup = () => {
    return (
      <>
        <div className={styles.header}>
          <span>
            {`${t('actions.confirm-message', {
              actionConfirm: tx(`action-confirm.${action}`),
            })}:`}
            {' ' + title}
          </span>
          <IconButton
            icon={XAlt}
            type="dark"
            size={28}
            color="#333333"
            style={{
              root: {
                marginTop: 5,
              },
            }}
            onClick={onClose}
          />
        </div>

        <div className={styles.container}>
          <Form
            baseUrl={`proposta/${id}/action/${action}`}
            onDone={() => {
              setStatus('success');
            }}
          >
            <TextEditor
              name="motivo"
              width="100%"
              editorStyle={{
                background: '#EEEEEE',
                borderRadius: 6,
                padding: '7px 10px',
              }}
            />
            <div className={styles.footer}>
              <Button
                type="secondary"
                onClick={() => {
                  setStatus('init');
                  setAction(undefined);
                }}
              >
                {tx('button.cancel')}
              </Button>
              <SubmitButton style={{ root: { marginLeft: UI_BUTTON_SPACING } }}>
                {tx('button.send')}
              </SubmitButton>
            </div>
          </Form>
        </div>
      </>
    );
  };

  const handleClose = () => {
    anchorRef.current?.focus();
    setPdfData(null);
    setStatus('init');
  };

  return (
    <>
      <div className={styles.actionButtons}>
        {actions.map((data, index) => (
          <Button
            type={data.disabled !== undefined && data.disabled ? 'disabled' : undefined}
            icon={data.icon}
            theme="light"
            key={index}
            onClick={() => handleAction(data.text)}
            active={data.enable !== undefined ? data.enable : false}
          >
            {tx(data.text)}
          </Button>
        ))}
      </div>
      <Modal
        visible={status === 'confirm'}
        width={700}
        onBlur={() => {
          setStatus('init');
          setAction(undefined);
        }}
        onClose={() => anchorRef.current?.focus()}
      >
        <div className={styles.popup}>{renderPopup()}</div>
      </Modal>

      <Modal
        visible={
          status === 'pdf' || status === 'pdf-description' || status === 'pdf-financial'
        }
        width="auto"
        onBlur={() => {
          setStatus('init');
          setAction(undefined);
        }}
        onClose={handleClose}
      >
        <div className={styles.popup}>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('workplan')}
            onClose={handleClose}
          />
        </div>
      </Modal>

      <ErrorDialog
        visible={status === 'error' && !!props.hasErrors}
        onClose={() => {
          setStatus('init');
          setAction(undefined);
        }}
        message={t('actions.error-message', {
          action: tx(`${action}`).toLowerCase(),
        })}
        buttonLabel={tx('button.ok')}
      />

      <SuccessDialog
        onClose={() => {
          setStatus('init');
          setAction(undefined);
          history.push('/dashboard/propostas');
        }}
        visible={status === 'success' && !props.hasErrors}
        title={t('actions.success-message', {
          actionSuccess: tx(`action-success.${action}`),
        })}
        buttonLabel={tx('button.ok')}
      />
    </>
  );
};

export default ActionButton;
