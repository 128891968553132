import React, { useEffect, useMemo } from 'react';
import { useTranslationX } from 'i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { useDynamicHeader } from '.';

import styles from './DynamicHeader.module.scss';

const DynamicHeader: React.FC = () => {
  const { pathname } = useLocation();
  const { tx } = useTranslationX('breadcrumb', 'translation');

  const { visible, footer, breadcrumbs, setPaths, paths } = useDynamicHeader();
  const history = useHistory();
  const parts = useMemo(() => {
    return breadcrumbs !== undefined
      ? breadcrumbs
      : pathname
          .split('/')
          .filter(part => !part.match(/^[0-9]+$/))
          .slice(1);
  }, [breadcrumbs, pathname]);

  // Check if string has pattern [key] using regex
  const ignoreTranslation = (str: string) => {
    const pattern = /\[([^\]]+)\]/;
    return pattern.test(str);
  };

  useEffect(() => {
    if (!paths?.includes(pathname)) {
      setPaths([...(paths as string[]), pathname]);
    }
  }, [paths, pathname, setPaths]);

  const handleDynamicNavigation = (index: number) => {
    const prevPosition = paths.find((_, pathPosition) => pathPosition === index);
    setPaths(paths.slice(0, index));
    history.push(prevPosition as string);
  };

  if (!visible) return null;
  return (
    <div className={styles.header}>
      <div className={styles.breadcrumb}>
        {parts.map((part, index) => (
          <span key={index} onClick={() => handleDynamicNavigation(index)}>
            {ignoreTranslation(part) ? part.replace('[', '').replace(']', '') : tx(part)}
          </span>
        ))}
      </div>
      {footer && <h3 className={styles.footer}>{footer}</h3>}
    </div>
  );
};

export default DynamicHeader;
