import React, { useRef, useCallback, CSSProperties, useMemo } from 'react';

import { FieldMetadata, FieldType } from '../types';

import { hashValue } from '../useDiff';
import useField from './useField';
import { useTranslation } from 'i18n';

import InputControl, {
  ControlRef,
  ControlInstance,
  Props as ControlProps,
} from '../../inputs/InputControl/InputControl';

import { readAttr } from 'utils/object';
import { classes, scrollToRef, focusRef } from 'utils/components';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { formatFromMetadata } from '../helpers';

import styles from './Field.module.scss';

export type Props = {
  name: string;
  type?: FieldType;
  dataType?: string;
  forceActive?: boolean;
  clientSideDiff?: boolean;
  metadata?: FieldMetadata;
  value?: any;
  initialValue?: any;
  omit?: boolean;
  autosave?: boolean;
  style?: {
    root?: CSSProperties;
    header?: { label?: CSSProperties; optional?: CSSProperties };
    body?: CSSProperties;
  };
  overlay?: 'removed' | 'modified' | 'added';
  lastValue?: any;
  refField?: string;
} & (
  | { theme: 'compact' | 'transparent' | 'sneaky' | 'skinny'; label?: never }
  | { theme?: 'classic' | 'platinum' | 'button'; label: string | undefined }
);

const Field: React.FC<Props & { component: React.ElementType }> = props => {
  const {
    name,
    type,
    value,
    lastValue,
    form,
    section,
    metadata,
    setMetadata,
    refreshType,
    validationToken,
    refreshValidation,
  } = useField(props);

  const {
    label,
    component,
    theme = 'classic',
    style,
    overlay,
    clientSideDiff,
    ...rest
  } = props;

  const overwriteOverlay = useMemo(() => {
    if (overlay) return overlay;
    if (lastValue === undefined || value === undefined) return undefined;
    const oldValue = hashValue(lastValue);
    const newValue = hashValue(value);
    if (clientSideDiff && form.showDiff && oldValue !== newValue) return 'modified';
  }, [clientSideDiff, form.showDiff, lastValue, overlay, value]);

  const fieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<ControlRef>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const required = readAttr('rules.required', metadata, false);
  const showOptional = useMemo(
    () => readAttr('rules.required', metadata) !== undefined && form.display.optional,
    [form.display.optional, metadata]
  );

  const { t } = useTranslation();

  const onChange = useCallback(
    (instance: ControlInstance) => {
      form.updateFieldData(name, {
        ...instance,
        section,
        focus: () => {
          scrollToRef(fieldRef);
          focusRef(inputRef);
        },
        setMetadata,
        refreshType,
        dataType: props.dataType,
        refreshValidation,
        disabled: type === 'disabled',
        omit: props.omit,
        autosave: props.autosave,
      });
    },
    [
      form,
      name,
      props.dataType,
      props.autosave,
      props.omit,
      refreshType,
      refreshValidation,
      section,
      setMetadata,
      type,
    ]
  );

  const forwardProps: ControlProps = {
    ...rest,
    name,
    label,
    type,
    value,
    metadata,
    component,
    onChange,
    display: form.display,
    inputProps: {
      'aria-required': required,
      'aria-label': label,
    },
    validationToken,
    theme,
    controlState: form.getFieldData(name)?.controlState,
  };

  const formattedValue = useMemo(() => {
    if (value === undefined) return undefined;
    return formatFromMetadata(value, metadata);
  }, [metadata, value]);

  const formattedLastValue = useMemo(() => {
    if (lastValue === undefined) {
      return undefined;
    }
    return formatFromMetadata(lastValue, metadata);
  }, [metadata, lastValue]);

  const isRemovedItem = overwriteOverlay === 'removed';

  function toggleOverlay() {
    if (!isRemovedItem) {
      if (overlayRef.current) {
        overlayRef.current.style.pointerEvents = 'none';
        reactivateOverlay();
      }
    }
  }

  function reactivateOverlay() {
    setTimeout(() => {
      if (overlayRef.current) {
        overlayRef.current.style.pointerEvents = 'all';
      }
    }, 1000);
  }

  const isClassicTheme = theme === 'classic';

  return (
    <div
      className={classes(styles.field, styles[type || ''], styles[theme])}
      ref={fieldRef}
      style={style?.root}
    >
      {isClassicTheme && (
        <div className={styles.header}>
          <label className={styles.label} htmlFor={name} style={style?.header?.label}>
            {label}:
          </label>
          {required && <span className={styles.required}>*</span>}
          {!required && showOptional && (
            <span className={styles.optional} style={style?.header?.optional}>
              {`(${t('components.form.field.optional')})`}
            </span>
          )}
        </div>
      )}

      {overwriteOverlay && (
        <Tippy
          content={t('components.form.modified', {
            old: formattedLastValue ?? '-/-',
            new: formattedValue ?? '-/-',
          })}
          className={styles.popup}
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          <div
            className={classes(styles.overlay, styles[overwriteOverlay])}
            onClick={toggleOverlay}
            ref={overlayRef}
          />
        </Tippy>
      )}
      <div className={styles.body} style={style?.body}>
        <InputControl {...forwardProps} ref={inputRef} />
      </div>
    </div>
  );
};

export default Field;
