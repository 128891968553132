import React, { useRef, useState, Fragment } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import Tippy from '@tippyjs/react/headless';

import { useTranslation } from 'i18n';
import { Logo, UserBadge } from 'components/elements';
import { AngleDown, BellAlt, ConfigAlt } from 'components/icons';
import { logout, profileTransition, useAuthState } from 'store/auth';
import { DashBoardConfig } from '../types';
import { useProfileState } from 'store/profile';

import { DynamicHeader } from 'components/containers';
import { renderComponent } from 'utils/components';

import styles from './Topbar.module.scss';

type Props = {
  config: DashBoardConfig;
};

const Topbar = ({ config: { topMenu, gadgets, hideProfileName } }: Props) => {
  const [open, setOpen] = useState(false);

  const { name, profile, profiles } = useAuthState();
  const { notificationCount } = useProfileState();

  const correctProfile = profile === 'aluno' ? 'estudante' : profile;

  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation(['translation', profile!]);
  const { url } = useRouteMatch();

  const multiProfile = profiles && profiles.length > 1;

  const renderListContent = () => {
    return (
      <div className={styles.popup}>
        <ul>
          {multiProfile ? (
            <li onClick={() => dispatch(profileTransition('selecting'))}>
              <span>{t('topbar.change-profile')}</span>
            </li>
          ) : null}

          {topMenu &&
            topMenu.map(({ key, path }, index) =>
              key !== 'notifications' && key !== 'config' ? (
                <li key={index}>
                  <Link to={`${url}/${path || key}`}>
                    <span>{t(`${profile}:topbar.${key}`)}</span>
                  </Link>
                </li>
              ) : (
                ''
              )
            )}

          <li onClick={() => dispatch(logout())}>
            <span>{t('topbar.exit')}</span>
          </li>
        </ul>
      </div>
    );
  };

  const renderGadgets = () => {
    const items = [];

    if (gadgets) {
      for (const { key, path, component, itemProps } of gadgets) {
        switch (key) {
          case 'notification':
            items.push(
              <Link
                key={key}
                className={styles.notification}
                to={`${url}/${path || key}`}
              >
                {!!notificationCount && <span />}
                <BellAlt />
              </Link>
            );

            break;

          case 'config':
            items.push(
              <Link key={key} to={`${url}/${path || key}`}>
                <ConfigAlt size={1.1} />
              </Link>
            );
            break;
          default:
            items.push(
              <Fragment key={key}>{renderComponent(component, itemProps)}</Fragment>
            );
            break;
        }
      }
    }

    return items;
  };

  return (
    <div className={styles.topbar}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Logo width={133} />
        </div>
        <DynamicHeader />
      </div>
      <div className={styles.userContainer}>
        {renderGadgets()}

        <div ref={ref} onClick={() => setOpen(open => !open)}>
          <UserBadge name={name} />

          <div className={styles.user}>
            <span>{formatName(name)}</span>
            {!hideProfileName && <span>{correctProfile}</span>}
          </div>

          <Tippy
            appendTo={() => document.body}
            placement="bottom-end"
            offset={[0, 18]}
            render={renderListContent}
            interactive={true}
            visible={open}
            onClickOutside={(_instance, event) => {
              if (!ref.current?.contains(event.target as any)) {
                setOpen(false);
              }
            }}
          >
            <div className={`${styles.icon} ${open ? styles.open : ''}`}>
              <AngleDown />
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

export default Topbar;

function formatName(name?: string) {
  if (name) {
    const parts = name.replace(/\s+/g, ' ').trim().split(' ');

    if (parts.length === 1) {
      return parts[0];
    }

    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }
  }

  return '';
}
