import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { GenericObject, InputProps } from '../types';
import { IconButton } from 'components/elements';
import { FieldSet } from 'components/form';

import { Modal, TableList } from 'components/containers';
import { Triangle as Icon, X } from 'components/icons';
import { classes } from 'utils/components';
import { call } from 'utils';

import styles from './TableSelect.module.scss';
import { ColumnConfig } from 'components/containers/TableList/types';
import { ListType } from 'components/containers/TableList/TableList';
import { useTranslation } from 'i18n';
import { useFetch } from 'apis';
import { useFirstRender } from 'components/hooks';

type ModProps = Omit<InputProps, 'value' | 'initialValue'> & {
  value?: GenericObject;
  initialValue?: GenericObject;
};

export type Props = {
  title?: string;
  placeholder?: string;
  fetchUrl: string;
  preFetchUrl?: string;
  tableConfig?: ColumnConfig<ListType>;
  filterFixed?: any;
  formatOption?(arg0: any): string;
  formatValue?(arg0: GenericObject): Partial<GenericObject> | any;
  onPopulate?(arg0?: GenericObject): void;
};

const TableSelect = forwardRef<HTMLDivElement, ModProps & Props>((props, ref) => {
  const {
    value,
    initialValue,
    control,
    inputProps,
    title,
    fetchUrl,
    preFetchUrl,
    tableConfig = [],
    formatOption,
    placeholder,
    formatValue,
    onPopulate,
    filterFixed,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(initialValue ?? value);
  const { t } = useTranslation();

  const anchorRef = useRef<HTMLDivElement>();
  const { onChange, onBlur, onFocus, ...rest } = inputProps;

  const firstRender = useFirstRender();

  const { data } = useFetch(
    preFetchUrl,
    {},
    undefined,
    firstRender && preFetchUrl !== undefined
  );

  useEffect(() => {
    if (firstRender) return;
    if (value === undefined) {
      setSelected(undefined);
    }
  }, [value, firstRender]);

  useEffect(() => {
    onChange(formatValue ? (selected ? formatValue(selected) : undefined) : selected?.id);
    onPopulate && onPopulate(selected);
    if (anchorRef.current) {
      anchorRef.current.focus();
    }
  }, [selected, onChange, formatValue, onPopulate]);

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }
  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = {
    onClick: () => setExpanded(expanded => !expanded),
    onBlur: handleFocus,
    onFocus: handleFocus,
  };

  const getSelected = () => {
    if (selected) {
      return formatOption
        ? formatOption(data ?? selected)
        : Object.values(selected)[0]?.toString();
    }
  };

  const handleCleanSelected = () => {
    setSelected(undefined);
  };

  return (
    <div className={classes(styles.selectInput, styles[control.type || ''])}>
      <Modal
        visible={expanded}
        width={800}
        onBlur={() => setExpanded(false)}
        onClose={() => anchorRef.current?.focus()}
      >
        <div className={styles.popup}>
          <div className={styles.title}>
            <strong>{title}</strong>
            <IconButton icon={X} onClick={() => setExpanded(false)} color="#333333" />
          </div>
          <FieldSet name="select">
            <TableList
              baseUrl={fetchUrl}
              filterFixed={filterFixed}
              config={tableConfig}
              textSize="small"
              theme="light"
              style={{
                root: {
                  height: 420,
                },
                card: {
                  boxShadow: 'none',
                  padding: 0,
                },
                content: {
                  height: 420,
                },
              }}
              keyComposer={(item, index) => `${item.id}-${index}`}
              editIcon={null}
              editAction={item => {
                setExpanded(false);
                setSelected(item);
              }}
              refreshesOn={95}
              pageSize={40}
              notFoundProps={{}}
              filter
              infinityScroll
            />
          </FieldSet>
        </div>
      </Modal>
      <div
        {...rest}
        ref={attachRef}
        className={styles.button}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        {...handlers}
      >
        <div className={classes(styles.input, styles.placeholder)}>
          {selected !== undefined ? (
            getSelected()
          ) : (
            <div>{placeholder || t('components.select.placeholder')}</div>
          )}
        </div>
        <div className={styles.controls}>
          {selected && (
            <div
              onClick={e => {
                e.stopPropagation();
                handleCleanSelected();
              }}
            >
              <X />
            </div>
          )}
        </div>
        <div className={styles.icon}>
          <Icon size={1.2} />
        </div>
      </div>
    </div>
  );
});

TableSelect.displayName = 'TableSelectInput';
export default TableSelect;
