import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { API_SERVER_URL } from 'consts';
import { BsPaperclip as ClipIcon } from 'react-icons/bs';

import { useTranslationX } from 'i18n';
import { useFetch, useRequest } from 'apis';
import { IconButton, Loader, WrapperCard } from 'components/elements';
import { classes } from 'utils/components';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { ProposalCorrections } from '../ProposalEditor/types';

import styles from './Corrections.module.scss';
import { Modal, ModalPDFViewer } from 'components/containers';

const Corrections = ({ state: { id, titulo } }: SectionProps) => {
  const messageRef = useRef<HTMLDivElement>(null);

  const [showPdfModal, setShowPdfModal] = React.useState(false);
  const [pdfData, setPdfData] = useState<{
    pdfName: string;
    pdf: string | ArrayBuffer | null;
  }>();
  const request = useRequest();

  const { data } = useFetch<ProposalCorrections>(`/proposta/${id}/corrections`);
  const { tx, t } = useTranslationX('corrections', 'proposal');

  useEffect(() => {
    messageRef.current?.scrollIntoView();
  }, [data]);

  function parse(text: string) {
    const name = text?.split(' ') as string[];
    return name.length > 1 ? name[0] + ' ' + name[name.length - 1] : name;
  }

  const handlePdfClick = useCallback(
    (correctionId: number, timestamp: string) => {
      const source = request({
        url: `${API_SERVER_URL}/proposta/devolucao/${correctionId}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData({
              pdfName: tx('pdf_name', {
                title: titulo,
                timestamp: timestamp,
              }),
              pdf: base64data,
            });
          };
          setShowPdfModal(true);
        },
        onError: () => null,
      });

      return () => source.cancel();
    },
    [request, titulo, tx]
  );

  if (!data) {
    return <Loader />;
  }

  const handleClose = () => {
    setShowPdfModal(false);
  };

  return (
    <>
      <Modal
        visible={showPdfModal}
        onBlur={handleClose}
        width="auto"
        onClose={handleClose}
      >
        <div className={styles.popup}>
          <ModalPDFViewer
            pdfData={pdfData?.pdf}
            pdfDescription={pdfData?.pdfName}
            title={pdfData?.pdfName}
            onClose={handleClose}
          />
        </div>
      </Modal>
      <WrapperCard minWidth="850px" maxWidth="850px">
        <div className={styles.corrections}>
          {data.devolucoes.map(
            ({ id, dataDevolucao, estado, motivo, pessoa, versaoPdf }) => (
              <div key={id}>
                <div className={styles.status}>
                  <span>
                    {`${moment(dataDevolucao).format('DD-MM-YYYY HH:mm')} - ${t(
                      `status.${estado}`
                    )}`}
                  </span>
                  {versaoPdf && (
                    <div>
                      <IconButton
                        icon={ClipIcon}
                        size={20}
                        hint={tx('download_pdf')}
                        shape="circle"
                        overflow={true}
                        type="primary"
                        onClick={() => {
                          handlePdfClick(id, dataDevolucao);
                        }}
                      />
                    </div>
                  )}
                </div>
                {motivo && (
                  <div
                    className={classes(
                      styles.message,
                      data.estadosPesquisador.includes(estado) ? styles.invert : ''
                    )}
                  >
                    <span className={styles.author}>
                      {data.estadosPesquisador.includes(estado)
                        ? parse(pessoa.nome)
                        : tx('supervisor')}
                    </span>
                    <div
                      className={styles.content}
                      ref={messageRef}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(motivo) }}
                    ></div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </WrapperCard>
    </>
  );
};

export default Corrections;
