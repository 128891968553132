import React from 'react';

import {
  MdRadioButtonUnchecked as RadioIcon,
  MdCheckBoxOutlineBlank as CheckIcon,
} from 'react-icons/md';
import { renderComponent } from 'utils/components';

import styles from './Questions.module.scss';

export type QuestionType = {
  id: number;
  pergunta: string;
  tipo: 'MULTIPLE_CHOICE' | 'SELECTION_BOX' | 'TEXT';
  opcoes: string[];
  required?: boolean;
};

const Questions: React.FC<QuestionType> = ({ id, pergunta, tipo, opcoes }) => {
  const renderOptions = (options: string[], type: string) => {
    const icon = type === 'MULTIPLE_CHOICE' ? RadioIcon : CheckIcon;

    return (
      <ul className={styles.options}>
        {options.map((option, index) => (
          <li key={index}>
            <div className={styles.icon}>{renderComponent(icon)}</div>
            <span>{option}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.number}>{id}</span>
        <span className={styles.question}>{pergunta}</span>
      </div>

      {tipo !== 'TEXT' ? renderOptions(opcoes, tipo) : <div className={styles.text} />}
    </div>
  );
};

export default Questions;
