import React, { useState, useRef, useEffect, CSSProperties, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import useDelay from '../useDelay';
import { call } from 'utils';
import { classes } from 'utils/components';
import styles from './Modal.module.scss';

const ANIMATION_DURATION = 310;

type Props = {
  visible: boolean;
  frame?: boolean;
  minWidth?: boolean;
  onShow?: () => void;
  onClose?: () => void;
  onBlur?: () => void;
  delay?: number;
  width?: number | 'auto';
  children?: ReactNode;
  minHeight?: number;
};

type State = 'visible' | 'leaving' | 'hidden' | 'closed';

const Modal: React.FC<Props> = props => {
  const {
    visible,
    frame = true,
    minWidth = true,
    minHeight,
    onShow,
    onClose,
    children,
  } = props;

  const [state, setState] = useState<State>(visible ? 'visible' : 'hidden');
  const size = useRef<{ w: number; h: number }>();
  const mounted = useRef(false);
  const timer = useRef<NodeJS.Timeout>();
  const down = useRef<EventTarget>();
  const delay = useDelay(visible ? props.delay || 0 : 0);

  useEffect(() => {
    if (visible) {
      if (state !== 'visible') {
        setState('visible');
      }
    } else if (state === 'visible') {
      setState('leaving');
      timer.current = setTimeout(() => setState('hidden'), ANIMATION_DURATION);
    } else if (state === 'hidden' && mounted.current) {
      setState('closed');
    }
  }, [visible, state]);

  useEffect(() => {
    if (state === 'closed' && mounted.current) {
      mounted.current = false;
      size.current = undefined;
      call(onClose);
    } else if (state === 'visible' && !mounted.current) {
      mounted.current = true;
      call(onShow);
    }
  }, [state, onClose, onShow]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  if (state === 'hidden' || state === 'closed' || delay) {
    return null;
  }

  const container = document.querySelector('#modal');

  if (!container) {
    throw new Error('modal container not defined');
  }

  const style: CSSProperties = {};

  if (props.width) {
    style.width = style.minWidth = props.width;
  } else if (size.current) {
    style.width = style.minWidth = size.current.w;
    style.height = size.current.h;
  }

  if (minHeight) {
    style.minHeight = minHeight;
  }

  if (!minWidth) {
    style.minWidth = 0;
  }

  if (!minHeight) {
    style.minHeight = 0;
  }

  function handleModalKey(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      call(props.onBlur);
    }
  }

  return ReactDOM.createPortal(
    <div
      className={classes(styles.modal, styles[state])}
      role="dialog"
      aria-modal={true}
      tabIndex={-1}
      ref={el => el?.focus()}
      onClick={e => {
        e.stopPropagation();

        if (e.target === down.current) {
          call(props.onBlur);
        }
      }}
      onMouseDown={e => (down.current = e.target)}
      onKeyDown={handleModalKey}
    >
      {frame ? (
        <div
          className={styles.frame}
          style={style}
          ref={el => {
            if (el && visible) {
              size.current = { w: el.offsetWidth, h: el.offsetHeight };
            }
          }}
          onClick={e => e.stopPropagation()}
        >
          {children}
        </div>
      ) : (
        <div onClick={e => e.stopPropagation()}>{children}</div>
      )}
    </div>,
    container
  );
};

export default Modal;
