import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Tippy from '@tippyjs/react';

import { useTranslationX } from 'i18n';

import { DonutChart, KeyValueRow } from 'components/elements';
import { ItemCard } from 'components/containers';

import { getMasked } from 'utils/inputMask';
import { appendDots } from 'utils/stringUtils';
import { CostUnitItem } from 'pages/project/types';
import { useAuthState } from 'store/auth';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { classes } from '../../../../../utils/components';
import { Profile } from 'pages/register/profile';
import { MdLibraryAdd } from 'react-icons/md';

import styles from './CardPurchaseOrder.module.scss';

interface CardProps {
  data: CostUnitItem;
  showPurchaseOrder?: boolean;
  onItemSelected: any;
  onExecuteItem?: () => any | null;
}

const CardPurchaseOrder: React.FC<CardProps> = ({
  data,
  onItemSelected,
  showPurchaseOrder = false,
  onExecuteItem,
}) => {
  const {
    descricao,
    mesExecucao,
    total,
    disponivel,
    analise,
    aprovado,
    executado,
    alteracao_exclusao,
  } = data;
  const { tx } = useTranslationX('purchaseCards', 'project');

  const { profile } = useAuthState();
  const { state } = useProjectEditor();

  const isResponsible = profile !== Profile.SUPERVISOR;

  const chartData =
    state.section === 'outros'
      ? [
          {
            label: tx('chart.available'),
            value: disponivel,
            color: '#3FC75C',
          },

          {
            label: tx('chart.executed'),
            value: executado,
            color: '#FF9900',
          },
        ]
      : [
          {
            label: tx('chart.available'),
            value: disponivel,
            color: '#3FC75C',
          },
          {
            label: tx('chart.analysis'),
            value: analise,
            color: '#C98CFF',
          },
          {
            label: tx('chart.approved'),
            value: aprovado,
            color: '#007AFF',
          },
          {
            label: tx('chart.executed'),
            value: executado,
            color: '#FF9900',
          },
        ];

  return (
    <ItemCard
      width="440px"
      title={
        <Tippy
          content={descricao + (alteracao_exclusao ? tx('deleted') : '')}
          animation="perspective"
          touch={false}
          className={styles.popup}
        >
          <div>
            <KeyValueRow
              size="medium"
              margin="small"
              label={tx('description')}
              value={appendDots(
                descricao + (alteracao_exclusao ? tx('deleted') : ''),
                20
              )}
            />
          </div>
        </Tippy>
      }
    >
      <div
        className={classes(
          styles.container,
          alteracao_exclusao ? styles.deleted : undefined
        )}
      >
        <div>
          <KeyValueRow margin="small" label={tx('monthExecution')} value={mesExecucao} />
          <KeyValueRow
            margin="small"
            label={tx('totalValue')}
            value={getMasked((total || 0).toFixed(2), { pattern: 'currency' })}
          />
          {showPurchaseOrder && !alteracao_exclusao && (
            <>
              {isResponsible && (
                <div className={styles.order}>
                  <MdLibraryAdd />
                  <div onClick={() => onItemSelected(data)} className={styles.label}>
                    {tx('generateOrder')}
                  </div>
                </div>
              )}

              {state.section === 'outros' && !isResponsible && (
                <div className={styles.order}>
                  <AiOutlineCheckCircle />

                  <div onClick={onExecuteItem} className={styles.label}>
                    {tx('execute')}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.graph}>
          <DonutChart
            data={chartData}
            legend={{
              show: true,
            }}
            parseTippy={(value, percentage) =>
              `${percentage.toFixed(0)}% - R$${getMasked((value || 0).toFixed(2), {
                pattern: 'currency',
              })}`
            }
            graph={{
              size: 140,
            }}
          />
        </div>
      </div>
    </ItemCard>
  );
};

export default CardPurchaseOrder;
