import React, {
  useState,
  forwardRef,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from 'react';

import { useTranslationX } from 'i18n';
import { FieldOption } from 'components/form/types';
import { IconButton, Loader, Checklist, Checkbox, Button } from 'components/elements';
import { Bars, Triangle as Icon, X, XAlt } from 'components/icons';
import { Modal, ScrollPanel } from 'components/containers';
import { useAsyncOptions, useFirstRender } from 'components/hooks';
import { InputProps } from '../types';
import { call } from 'utils';
import { classes } from 'utils/components';
import Dropdown from './Dropdown';
import { appendDots } from 'utils/stringUtils';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './MultiSelect.module.scss';

type ModProps = Omit<InputProps, 'value'> & {
  value?: (string | number)[];
};

type SelectionShortcut = {
  label: string;
  default?: boolean;
  filter: (option: FieldOption) => boolean;
};

export type Props = {
  parseOption?: (value: any) => any;
  displayOptions?: 'dialog' | 'dropdown';
  fitWidth?: boolean;
  buttonIcon?: React.ElementType;
  fitHeight?: boolean;
  selectionShortcuts?: SelectionShortcut[];
  showBoxes?: boolean;
  modalTitle?: string;
};

const MultiSelect = forwardRef<HTMLDivElement, ModProps & Props>((props, ref) => {
  const {
    value,
    metadata,
    control,
    inputProps,
    parseOption = x => x,
    displayOptions = 'dialog',
    fitWidth,
    fitHeight,
    buttonIcon,
    selectionShortcuts,
    showBoxes = true,
    modalTitle,
  } = props;

  const [selectedValues, setSelectedValues] = useState(value || []);

  const [expanded, setExpanded] = useState(false);

  const button = control.theme === 'button';

  const anchorRef = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLDivElement>(null);

  const firstRender = useFirstRender();

  const { options, loading, active } = useAsyncOptions(metadata);
  const { onChange, onBlur, onFocus, ...rest } = inputProps;
  const { tx } = useTranslationX('components.multiselect');

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues, onChange]);

  useEffect(() => {
    if (value === undefined) {
      setSelectedValues(value || []);
    }
  }, [value]);

  useEffect(() => {
    if (firstRender) {
      if (selectionShortcuts) {
        const defaultShortcut = selectionShortcuts.find(s => s.default);
        if (defaultShortcut && options) {
          setSelectedValues(
            (options as FieldOption[])
              .filter(defaultShortcut.filter)
              .map(option => option.value)
          );
        }
      }
    }
  }, [firstRender, options, selectionShortcuts]);

  function renderBoxes(options: FieldOption[]) {
    const boxes: React.ReactNode[] = [];
    options.forEach(option => {
      if (selectedValues.includes(option.value)) {
        boxes.push(
          <Tippy
            key={option.value}
            content={option.text}
            className={styles.popup}
            animation="perspective"
            touch={false}
            followCursor="horizontal"
            plugins={[followCursor]}
          >
            <div key={option.value}>
              <span>{appendDots(parseOption(option.text), 10)}</span>

              <i
                onClick={e => {
                  e.stopPropagation();
                  setSelectedValues(val => val.filter(item => item !== option.value));
                }}
              >
                {button ? <XAlt /> : <X />}
              </i>
            </div>
          </Tippy>
        );
      }
    });

    return <div className={styles.boxContainer}>{boxes}</div>;
  }

  function renderAnchor(options: FieldOption[]) {
    return (
      <ScrollPanel
        style={{
          content: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          },
        }}
      >
        <Fragment>
          {button ? (
            <div ref={buttonRef} style={{ alignSelf: 'flex-start' }}>
              <Button
                theme="light"
                onClick={() => setExpanded(expanded => !expanded)}
                icon={buttonIcon}
              >
                {props.label}
              </Button>
            </div>
          ) : null}

          {showBoxes && (
            <>
              {renderBoxes(options)}

              {button ? (
                selectedValues.length > 1 ? (
                  <div className={styles.controls}>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedValues([]);
                      }}
                    >{`${tx('clear')} ${props.label?.toLocaleLowerCase()}`}</div>
                  </div>
                ) : null
              ) : (
                <div className={styles.controls}>
                  {selectedValues.length ? (
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedValues([]);
                      }}
                    >
                      <X />
                    </div>
                  ) : null}

                  <span />
                  <div className={styles.icon}>
                    <Icon size={1.2} />
                  </div>
                </div>
              )}
            </>
          )}
        </Fragment>
      </ScrollPanel>
    );
  }

  const handleChange = useCallback(
    (checked: boolean, value: string | number) => {
      if (checked) {
        if (!selectedValues.includes(value)) {
          setSelectedValues(values => [...values, value]);
        }
      } else if (selectedValues.includes(value)) {
        setSelectedValues(values => values.filter(item => item !== value));
      }
    },
    [selectedValues]
  );

  function handleFocus(event: React.FocusEvent) {
    if (!expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  function collapse() {
    anchorRef.current?.focus();
    setExpanded(false);
  }

  function handlePopupKey(event: React.KeyboardEvent) {
    event.preventDefault();

    switch (event.key) {
      case 'Escape':
      case 'Tab':
      case 'Enter':
        collapse();
        break;

      default:
        break;
    }
  }

  function handleButtonKey(event: React.KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
      case ' ':
        setExpanded(expanded => !expanded);
        break;

      default:
        break;
    }
  }

  const handlers = active
    ? {
        onClick: button ? undefined : () => setExpanded(expanded => !expanded),
        onBlur: handleFocus,
        onFocus: handleFocus,
        onKeyDown: handleButtonKey,
      }
    : {};

  return (
    <div
      className={classes(
        styles.multiSelect,
        styles[control.theme || ''],
        styles[control.type || '']
      )}
      style={{
        ...(active ? undefined : { pointerEvents: 'none' }),
        ...(fitHeight && { maxHeight: '34px' }),
      }}
    >
      {selectionShortcuts ? (
        <div
          style={{
            display: 'block',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          {selectionShortcuts.map((shortcut, index) => (
            <Button
              theme="light"
              style={{
                root: {
                  width: 'max-content',
                },
              }}
              icon={Bars}
              key={index}
              onClick={() => {
                const newValues = (options as FieldOption[])
                  .filter(option => shortcut.filter(option))
                  .map(option => option.value);
                setSelectedValues(newValues);
              }}
            >
              {shortcut.label}
            </Button>
          ))}
        </div>
      ) : null}
      <div
        {...rest}
        ref={attachRef}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        {...handlers}
      >
        {loading ? (
          <Loader size={26} type="spin" color={control.status} />
        ) : Array.isArray(options) ? (
          renderAnchor(options)
        ) : null}
      </div>

      {displayOptions === 'dropdown' ? (
        <Dropdown
          visible={expanded}
          options={options}
          anchor={button ? buttonRef.current : control.ref.current}
          renderOption={({ text, value }) => (
            <Checkbox
              label={parseOption(text)}
              value={value}
              selected={selectedValues.includes(value)}
              onChange={handleChange}
            />
          )}
          onBlur={() => {
            setExpanded(false);
            call(onBlur);
          }}
          onKeyDown={handlePopupKey}
          fitWidth={fitWidth}
        />
      ) : (
        <Modal
          visible={expanded}
          width={650}
          onBlur={() => setExpanded(false)}
          onClose={() => anchorRef.current?.focus()}
        >
          <div className={styles.popup}>
            <div className={styles.modalTitle}>
              <span>{modalTitle}</span>
              <IconButton icon={X} type="dark" onClick={() => setExpanded(false)} />
            </div>
            <Checklist
              options={options as FieldOption[]}
              selectedValues={selectedValues}
              onChange={handleChange}
              columns={2}
              parseOption={parseOption}
            />
          </div>
        </Modal>
      )}
    </div>
  );
});

MultiSelect.displayName = 'MultiSelect';
export default MultiSelect;
