import React, { useState } from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Modal, ModalPDFViewer, Row } from 'components/containers';
import { Button, PlainField, Spacer } from 'components/elements';
import { getMasked } from 'utils/inputMask';
import * as icons from 'components/icons';
import { formatDateToBrazilian } from 'utils/stringUtils';

import { useRequest } from 'apis';

import styles from './Counterpart.module.scss';

type Data = {
  nome: string;
  regime: number;
  mesInicio: number;
  mesFim: number;
  estado: string;
  chMes: number;
  rg: string;
  atividades: string;
  projetoTitulo: string;
  projetoSigla: string;
  projetoCoordenador: string;
  remuneracaoBruta: number;
  tipo: string;
  rgEmissor: string;
  rgEmissorUf: string;
  projetoId: number;
  projetoContrapartidaId: number;
  next?: Data;
  previous?: Data;
  ferias?: any[];
  remuneracao?: string;
  mesSolicitacaoDesligamento: number;
  tipoServidor: string;
  valorHora?: number;
  diffs?: string[];
};

type ArchivesProps = {
  projetoId: number;
  type: string;
  projetoTypeId: number;
  data: Data;
};

const Counterpart = ({ data }: { data: Data }) => {
  const { tipo, next, previous, diffs } = data;

  if (tipo === 'contrapartida_update' && next) {
    return (
      <CounterpartUpdate
        {...previous}
        {...next}
        tipo={tipo}
        diffs={diffs}
        previous={previous}
      />
    );
  }

  return <CounterpartUpdate {...data} tipo={tipo} />;
};

const CounterpartUpdate = (
  props: Data & { diffs?: string[]; previous?: Omit<Data, 'previous'> }
) => {
  const { tx } = useTranslationX('request', 'project');

  const {
    nome,
    mesInicio,
    mesFim,
    chMes,
    atividades,
    projetoTitulo,
    projetoSigla,
    regime,
    projetoCoordenador,
    remuneracaoBruta,
    tipo,
    projetoId,
    projetoContrapartidaId,
    ferias,
    remuneracao,
    mesSolicitacaoDesligamento,
    tipoServidor,
    valorHora,
    diffs,
  } = props;

  const diff = diffs?.reduce<Record<string, string>>((obj, item) => {
    if (item && props.previous) {
      const previousValue = props.previous[item as keyof Data] as string;
      obj[item] = previousValue;
    }
    return obj;
  }, {});

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={tipoServidor ? [3, 3] : [6]} align="top">
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />

        {tipoServidor && (
          <PlainField
            label={tx('form.tipoServidor')}
            value={tipoServidor}
            overlay={diff?.['tipoServidor']}
          />
        )}
      </Row>
      <Row width={[6, 3]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[3, 3, 3]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
        {mesSolicitacaoDesligamento && (
          <PlainField
            label={tx('form.desligamento')}
            value={String(mesSolicitacaoDesligamento)}
          />
        )}
      </Row>
      <Row width={[3, 3, 3]} align="top">
        <PlainField label={tx('form.name.label')} value={nome} overlay={diff?.['nome']} />
        <PlainField
          label={tx('form.mesInicio')}
          value={String(mesInicio)}
          overlay={diff?.['mesInicio']}
        />
        <PlainField
          label={tx('form.mesFinal')}
          value={String(mesFim)}
          overlay={diff?.['mesFim']}
        />
      </Row>

      <Spacer length={20} />
      <Row width={[3, 3, 3]} align="top">
        <PlainField
          label={tx('form.regime')}
          value={`${regime} ${tx('form.hours')}`}
          overlay={diff?.['regime'] ? `${diff?.['regime']} ${tx('form.hours')}` : null}
        />
        <PlainField
          label={tx('form.valueHour')}
          value={String(valorHora)}
          overlay={diff?.['valorHora']}
        />
      </Row>
      <Row align="top">
        <PlainField
          label={tx('form.atividades')}
          value={atividades}
          overlay={diff?.['atividades']}
        />
      </Row>
      <Spacer length={20} />
      <Row width={[3, 3, 3]} align="top">
        <PlainField
          label={tx('form.remuneration')}
          value={getMasked(
            tipo === 'contrapartida_update'
              ? parseInt(remuneracao as string).toFixed(2)
              : remuneracaoBruta.toFixed(2),
            { pattern: 'currency' }
          )}
          overlay={
            diff?.['remuneracaoBruta']
              ? getMasked(Number(diff?.['remuneracaoBruta']).toFixed(2), {
                  pattern: 'currency',
                })
              : undefined
          }
        />
        <PlainField
          label={tx('form.monthlyWorkload')}
          value={`${chMes} ${tx('form.hourspermonth')}`}
          overlay={
            diff?.['chMes'] ? `${diff?.['chMes']} ${tx('form.hourspermonth')}` : undefined
          }
        />
      </Row>
      <Spacer length={20} />

      <Row>
        {ferias && !!ferias.length && (
          <PlainField label={tx('form.vacationPeriod')} value="" />
        )}
      </Row>
      {ferias && !!ferias.length && (
        <>
          {ferias.map((item: any) => {
            return (
              <Row width={[3, 3]} align="top" key={ferias[item]}>
                <PlainField
                  label={tx(`form.dataInicio`)}
                  value={formatDateToBrazilian(item.dataInicio)}
                />
                <PlainField
                  label={tx(`form.dataFinal`)}
                  value={formatDateToBrazilian(item.dataFim)}
                />
              </Row>
            );
          })}
        </>
      )}
      <RenderArchivesButtons
        projetoId={projetoId}
        projetoTypeId={projetoContrapartidaId}
        type="contrapartidas"
        data={props}
      />
    </Grid>
  );
};

const RenderArchivesButtons = (props: ArchivesProps) => {
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const { projetoId, type, projetoTypeId, data } = props;

  const { mesSolicitacaoDesligamento, tipo } = data;

  const { tx } = useTranslationX('request', 'project');
  const { tx: tx2 } = useTranslationX('request.form', 'project');

  const request = useRequest();

  const downloadDeclaracao = (data: any, path = '/pdf/enviada') => {
    if (projetoId) {
      request<any>({
        url: `/projeto/${projetoId}/${type}/${projetoTypeId}${path}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
        },
        onError: data => console.error(data),
      });
    }
  };

  const showDocuments =
    projetoId && !mesSolicitacaoDesligamento && tipo !== 'contrapartida_update';

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <>
      {showDocuments && (
        <>
          <div className={styles.documents}>
            <Row align="top">
              <span className={styles.documentsTitle}>{tx2('uploadedDocuments')}</span>
            </Row>
            <div className={styles.uploadedDocuments}>
              <Row width={[3]} align="top">
                <Button
                  icon={icons.Eye}
                  theme="light"
                  onClick={() => downloadDeclaracao(data, '/pdf')}
                >
                  {tx('form.scienceDeclaration')}
                </Button>
              </Row>
              <Row width={[3]} align="top">
                <Button
                  icon={icons.Eye}
                  theme="light"
                  onClick={() => downloadDeclaracao(data)}
                >
                  {tx('form.sentDeclaration')}
                </Button>
              </Row>
            </div>
          </div>

          <Modal
            visible={pdfData !== null}
            width="auto"
            onBlur={handleClose}
            onClose={handleClose}
          >
            <div>
              <ModalPDFViewer
                pdfData={pdfData}
                title={tx('form.scienceDeclaration')}
                onClose={handleClose}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Counterpart;
