import { HiringStatus } from 'pages/project/types';

export type PaymentStatus = 'LAT' | 'EXE' | 'REQ' | 'PLA';
export const paymentStatusColors: { [key in PaymentStatus]: string } = {
  LAT: '#E51616',
  EXE: '#37A64A',
  REQ: '#BBEBC4',
  PLA: '#C4C4C4',
};

export type Payment = {
  id: string | number;
  estado: PaymentStatus;
  mes: number;
  valor: number;
  pagamento: Date;
  alteracao_pt_block: boolean;
};

export type PaymentContract = {
  prhId: number;
  nome: string;
  fonte: string;
  valor: number;
  cargo: string;
  estado: HiringStatus;
  pagamentos: Payment[];
  vinculo: string;
};

export type PaymentContracts = {
  inicioRelativo: Date;
  duracao: number;
  contratos: PaymentContract[];
};

export type PaymentContextProps = {
  addPayment: (payment: Payment) => void;
  removePayment: (id: string | number) => void;
  clearPayments: () => void;
  updateVinculos: (value: string) => void;
  payments: Payment[];
  vinculos: string;
  currentMonthPayments: Payment[];
  defineCurrentMonthPayments: (payments: Payment[]) => void;
  contracts: PaymentContracts;
  reset: () => void;
};
