import React from 'react';

import { useTranslation } from 'i18n';
import { FileUploader } from 'components/form';
import { FileDownloader } from 'components/elements';
import { SectionProps } from '../types';
import { useFetch } from 'apis';
import { getPath } from '../profile';

import styles from './Documents.module.scss';

const Documents = ({ state }: SectionProps) => {
  const perfil = getPath(state.profile);
  const ifce = state.ifce || false;

  const { data: id, error } = useFetch<number>('/cadastro/anexos', { perfil, ifce });
  const { t } = useTranslation('register');

  return (
    <div style={{ width: '100%', padding: '10px 0' }}>
      <FileUploader
        name="arquivos"
        label={'arquivos'}
        message={t('documents.uploader.message')}
        type="sneaky"
      />

      {!error && id && (
        <FileDownloader id={id} publicFile>
          <span className={styles.link}>{t('documents.attachment_message')}</span>
        </FileDownloader>
      )}
    </div>
  );
};

export default Documents;
