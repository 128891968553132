import React from 'react';
import { Conclusion, conclusionStatusColors } from '../../types';
import { classes } from 'utils/components';
import { Milestone } from '../../../types';

import styles from './ConclusionNode.module.scss';
import moment from 'moment';

type Props = {
  conclusion: Conclusion;
  milestone?: Milestone;
  handleClick: () => void;
};

const ConclusionNode: React.FC<Props> = ({ conclusion, milestone, handleClick }) => {
  const { estado } = conclusion;
  const { dataEntrega } = milestone || {};

  const isAccepted = !!milestone?.arquivoTermo;

  const isClickable = (() => {
    if (isAccepted) {
      return false;
    }
    if (
      (estado === 'PLA' && moment(dataEntrega).isBefore(moment())) ||
      ['UPT', 'TUP', 'LAT'].includes(estado)
    ) {
      return true;
    }
    return false;
  })();

  return (
    <div
      onClick={isClickable ? handleClick : undefined}
      className={classes(styles.conclusionNode, isClickable ? styles.clickable : '')}
      style={{
        backgroundColor: conclusionStatusColors[estado],
      }}
    />
  );
};

export default ConclusionNode;
