import React, { useState, useCallback, ReactNode } from 'react';
import { DynamicHeaderContext } from './DynamicHeaderContext';

type Props = {
  children?: ReactNode;
};

const DynamicHeaderProvider: React.FC<Props> = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [footer, setFooter] = useState<string>();
  const [paths, setPaths] = useState<string[]>([] as string[]);
  const [breadcrumbs, setBreadcrumbs] = useState<string[] | undefined>();

  const handleVisibility = useCallback((visibility: boolean) => {
    setVisible(visibility);
  }, []);

  const handleFooter = useCallback((footer?: string) => {
    setFooter(footer);
  }, []);

  const handleBreadcrumbs = useCallback((breadcrumbs?: string[]) => {
    setBreadcrumbs(breadcrumbs);
  }, []);

  const handlePaths = useCallback((paths: string[]) => {
    setPaths(paths);
  }, []);

  const appendBreadcrumb = useCallback(
    (breadcrumb: string) => {
      setBreadcrumbs(breadcrumbs => {
        if (breadcrumbs) {
          return [...breadcrumbs, breadcrumb];
        }

        return [breadcrumb];
      });
    },
    [setBreadcrumbs]
  );

  const clear = useCallback(() => {
    setVisible(false);
    setBreadcrumbs(undefined);
    setFooter(undefined);
  }, []);

  return (
    <DynamicHeaderContext.Provider
      value={{
        visible,
        footer,
        breadcrumbs,
        setVisibility: handleVisibility,
        setFooter: handleFooter,
        setPaths: handlePaths,
        setBreadcrumbs: handleBreadcrumbs,
        appendBreadcrumb,
        clear,
        paths,
      }}
    >
      {children}
    </DynamicHeaderContext.Provider>
  );
};

export default DynamicHeaderProvider;
