import React, { useState } from 'react';

import Tippy from '@tippyjs/react';

import { useTranslation, useTranslationX } from 'i18n';
import { Grid, Modal, ModalPDFViewer, Row } from 'components/containers';

import { Button, PlainField } from 'components/elements';

import { getMasked } from 'utils/inputMask';
import { appendDots, formatDateToBrazilian } from 'utils/stringUtils';
import { useRequest } from 'apis';
import { Download, Eye } from 'components/icons';
import { Adress, Data } from './types';

import styles from './execution.module.scss';

const Acquisition = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('request', 'project');
  const { tx: tx2 } = useTranslationX('request.form', 'project');
  const { t } = useTranslation();

  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const {
    titulo,
    tipo,
    fonte,
    termo_id,
    gpf,
    valor,
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    propostas,
    tipoServico,
    tipoPrestador,
    justificativa,
    criterio,
    descricao,
    endereco,
  } = data;

  const request = useRequest();

  const getArchive = (id: number) => {
    request<string>({
      url: `/arquivo/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'string',
      },
      onSuccess: handleDownload,
    });
  };

  const handleDownload = (token: string) => {
    request<any>({
      url: `/resource/${token}`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  if (tipo === 'passagem') {
    return <AcquisitionTicket data={data} />;
  }

  if (tipo === 'diaria') {
    return <AcquisitionDiary data={data} />;
  }

  const address = endereco as Adress;

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[6, 6]} align="top">
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
      </Row>
      <Row width={[6, 6]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[6, 6]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
      </Row>
      <Row width={[6, 6]} align="top">
        <PlainField
          label={tx('list.value')}
          value={`${t('currency')} ${getMasked(valor.toFixed(2), {
            pattern: 'currency',
          })}`}
        />
        <PlainField label={tx('list.source')} value={fonte} />
        <PlainField label={tx('list.gpf')} value={gpf} />
      </Row>
      {tipo === 'servico' && (
        <>
          <Row width={[6, 6]} align="top">
            <PlainField
              label={tx('list.tipoServico')}
              value={tipoServico?.nome as string}
            />
            <PlainField
              label={tx('list.tipoServicoText')}
              value={tipoServico?.text as string}
            />
            <PlainField
              label={tx('list.tipoPrestador')}
              value={tx(`list.prestadorTipo.${tipoPrestador}`)}
            />
          </Row>
          <Row width={[6, 6]} align="top">
            <PlainField label={tx('list.descricao')} value={descricao as string} />
            <PlainField label={tx('list.criterio')} value={criterio as string} />
          </Row>
          <Row align="top">
            <PlainField
              label={tx('list.justificativa')}
              value={justificativa as string}
            />
          </Row>
        </>
      )}

      {tipo === 'compra' && (
        <>
          <Row width={[6, 6]} align="top">
            <PlainField label={tx('descricao')} value={descricao as string} type="html" />
          </Row>

          <Row align="top">
            <span className={styles.rowLabel}>{tx2('enderecoEntrega')}</span>
          </Row>

          <Row width={[6, 6]} align="top">
            <PlainField label={tx('form.cep')} value={address.cep} />
            <PlainField label={tx('form.uf')} value={address.uf} />
            <PlainField label={tx('form.city')} value={address.cidade} />
          </Row>
          <Row width={[6, 6]} align="top">
            {address.logradouro && (
              <PlainField label={tx('form.logradouro')} value={address.logradouro} />
            )}
            <PlainField label={tx('form.number')} value={address.numero} />
            {address.complemento && (
              <PlainField label={tx('form.complement')} value={address.complemento} />
            )}
          </Row>
          <Row width={[6, 6]} align="top">
            <PlainField label={tx('form.neighborhood')} value={address.bairro} />
          </Row>
          <Row align="top">
            <PlainField
              label={tx('justificativa')}
              value={justificativa as string}
              type="html"
            />
          </Row>
        </>
      )}

      <div className={styles.documents}>
        {termo_id && (
          <>
            <Row align="top">
              <span className={styles.documentsTitle}>{tx2('uploadedDocuments')}</span>
            </Row>
            <Row align="top">
              <Button
                theme="light"
                icon={Eye}
                onClick={() => getArchive(termo_id)}
                style={{
                  root: {
                    margin: 0,
                  },
                }}
              >
                {tx('form.term')}
              </Button>
            </Row>
          </>
        )}
        {propostas.length > 0 && (
          <Row align="top">
            <span className={styles.documentsTitle}>{tx2('proposals')}</span>
          </Row>
        )}

        <div className={styles.uploadedDocuments}>
          {propostas && (
            <div className={styles.uploadedDocuments}>
              {propostas.map(proposta => (
                <Row width={[12]} align="top">
                  <div className={styles.document}>
                    <Download size={1.5} />
                    <Tippy
                      className={styles.popup}
                      content={proposta.nome}
                      placement="top-start"
                      animation="perspective"
                      offset={[0, 5]}
                      delay={[100, 0]}
                      hideOnClick={false}
                    >
                      <span onClick={() => getArchive(proposta.id)}>
                        <strong>{appendDots(proposta.nome, 20)}</strong>
                      </span>
                    </Tippy>
                  </div>
                </Row>
              ))}
            </div>
          )}
        </div>
      </div>

      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('form.archive')}
            onClose={handleClose}
          />
        </div>
      </Modal>
    </Grid>
  );
};

const AcquisitionTicket = ({ data }: { data: Data }) => {
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const { tx: tx2 } = useTranslationX('request.form', 'project');

  const { tx } = useTranslationX('request', 'project');
  const {
    titulo,
    tipo,
    valorSolicitado,
    nome,
    cpf,
    passaporte,
    dataIda,
    dataVolta,
    itinerario,
    trechos,
    termo_id,
    propostas_id,
  } = data;

  const request = useRequest();

  const getArchive = (id: number) => {
    request<string>({
      url: `/arquivo/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'string',
      },
      onSuccess: handleDownload,
    });
  };

  const handleDownload = (token: string) => {
    request<any>({
      url: `/resource/${token}`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
        <PlainField
          label={tx('form.valueSolicited')}
          value={getMasked(valorSolicitado.toFixed(2), { pattern: 'currency' })}
        />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.name.label')} value={nome} />
        <PlainField label={tx('form.cpf')} value={cpf} />
        <PlainField label="" value="" />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.passport')} value={passaporte} />
        <PlainField
          label={tx('form.departureDate')}
          value={formatDateToBrazilian(new Date(dataIda))}
        />
        <PlainField
          label={tx('form.dateBack')}
          value={formatDateToBrazilian(new Date(dataVolta))}
        />
      </Row>
      <Row width={[10]} align="top">
        <PlainField label={tx('form.itinerary')} value={itinerario} />
      </Row>
      {trechos.map((trecho, index) => {
        return (
          <Row width={[2, 2, 2, 2, 2, 2]} align="top" key={index}>
            <PlainField label={tx('form.trecho.codigo')} value={trecho.codigo} />
            <PlainField label={tx('form.trecho.origem')} value={trecho.origem} />
            <PlainField label={tx('form.trecho.destino')} value={trecho.destino} />
            <PlainField label={tx('form.trecho.embarque')} value={trecho.embarque} />
            <PlainField
              label={tx('form.trecho.partida')}
              value={formatDateToBrazilian(new Date(trecho.partida))}
            />
            <PlainField
              label={tx('form.trecho.chegada')}
              value={formatDateToBrazilian(new Date(trecho.chegada))}
            />
          </Row>
        );
      })}

      <div className={styles.documents}>
        <Row align="top">
          <span className={styles.documentsTitle}>{tx2('uploadedDocuments')}</span>
        </Row>
        <div className={styles.uploadedDocuments}>
          {termo_id && (
            <Row width={[3]} align="top">
              <Button theme="light" icon={Eye} onClick={() => getArchive(termo_id)}>
                {tx('form.term')}
              </Button>
            </Row>
          )}
          {propostas_id && (
            <Row width={[3]} align="top">
              <Button theme="light" icon={Eye} onClick={() => getArchive(propostas_id)}>
                {tx('form.proposals')}
              </Button>
            </Row>
          )}
        </div>
      </div>
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('form.archive')}
            onClose={handleClose}
          />
        </div>
      </Modal>
    </Grid>
  );
};

const AcquisitionDiary = ({ data }: { data: Data }) => {
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const { tx } = useTranslationX('request', 'project');

  const {
    titulo,
    tipo,
    valorSolicitado,
    nome,
    cpf,
    clt,
    telefone,
    email,
    banco,
    agencia,
    conta,
    objetivo,
    meioTransporte,
    valorDiaria,
    quantidade,
    endereco,
    servidorPublico,
    esfera,
    cargo,
    dataIda,
    dataVolta,
    destino,
  } = data;

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
        <PlainField
          label={tx('form.valueSolicited')}
          value={getMasked(valorSolicitado.toFixed(2), { pattern: 'currency' })}
        />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.name.label')} value={nome} />
        <PlainField label={tx('form.cpf')} value={cpf} />
        <PlainField label={tx('form.clt')} value={tx(`boolean.${Number(clt)}`)} />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.phone')} value={telefone} />
        <PlainField label={tx('form.email')} value={email} />
        <PlainField label="" value="" />
      </Row>
      <Row width={[6]} align="top">
        <PlainField label={tx('form.address')} value={endereco as string} />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField
          label={tx('form.servidorPublico')}
          value={tx(`boolean.${Number(servidorPublico)}`)}
        />
        <PlainField label={tx('form.esfera')} value={esfera} />
        <PlainField label={tx('form.cargo')} value={cargo} />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.banco')} value={banco} />
        <PlainField label={tx('form.agencia')} value={agencia} />
        <PlainField label={tx('form.conta')} value={conta} />
      </Row>
      <Row width={[6]} align="top">
        <PlainField label={tx('form.objective')} value={objetivo} />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField label={tx('form.destino')} value={destino} />
        <PlainField
          label={tx('form.departureDate')}
          value={formatDateToBrazilian(new Date(dataIda))}
        />
        <PlainField
          label={tx('form.dateBack')}
          value={formatDateToBrazilian(new Date(dataVolta))}
        />
      </Row>
      <Row width={[6]} align="top">
        <PlainField label={tx('form.meioTransporte')} value={meioTransporte} />
      </Row>
      <Row width={[4, 3, 3]} align="top">
        <PlainField
          label={tx('form.valueDiary')}
          value={getMasked(valorDiaria.toFixed(2), { pattern: 'currency' })}
        />
        <PlainField label={tx('form.diaryNumber')} value={String(quantidade)} />
        <PlainField
          label={tx('form.valueSolicited')}
          value={getMasked(valorSolicitado.toFixed(2), { pattern: 'currency' })}
        />
      </Row>
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('form.archive')}
            onClose={handleClose}
          />
        </div>
      </Modal>
    </Grid>
  );
};

export default Acquisition;
