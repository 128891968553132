import React from 'react';

import { classes, renderComponent } from 'utils/components';
import { Action } from './types';

import styles from './ActionColumn.module.scss';

type Props = {
  actions: Action[];
};

const ActionColumn = (props: Props) => {
  return (
    <div className={styles.container}>
      {props.actions.map(action => {
        return (
          <div
            key={action.label}
            className={classes(styles.action, action.disabled ? styles.disabled : null)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!action.disabled && action.callback) action.callback();
            }}
            style={{ color: action.color }}
          >
            {renderComponent(action.icon, { size: 1.5 })}
            <span>{action.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ActionColumn;
