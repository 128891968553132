import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ProposalListView, ProposalEditor } from 'pages/proposal';
import { Profile } from 'pages/register/profile';
import RouteData from 'pages/utils/RouteData';

const Proposta = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ProposalListView canCreate />
      </Route>

      <Route path={`${path}/:id`}>
        <RouteData>
          {props => <ProposalEditor {...props} profile={Profile.RESEARCHER} />}
        </RouteData>
      </Route>
    </Switch>
  );
};

export default Proposta;
