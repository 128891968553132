import React, {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useTranslationX } from 'i18n';
import { useAuthState } from 'store/auth';
import { ErrorDialog, Modal, ModalPDFViewer } from 'components/containers';
import { Button, IconButton } from 'components/elements';
import { AddCheck, PDF, Sei, WarningAlt, XAlt, XRect } from 'components/icons';
import { Profile } from 'pages/register/profile';

import { useRequest } from 'apis';
import { messageService } from 'services';

import { IconType } from 'pages/proposal/types';

import { Dispatch, EditorState } from '../../UpdateWpEditor/types';
import { Form, SubmitButton, TextEditor } from 'components/form';
import { API_SERVER_URL, UI_BUTTON_SPACING } from 'consts';
import Tippy from '@tippyjs/react';
import { UpdateWPInEdition } from 'pages/project/Sections/Milestones/types';
import { followCursor } from 'tippy.js';

import styles from './ActionButtons.module.scss';

export type Props = {
  id: number;
  title: string;
  hasErrors?: boolean;
  dispatch: Dispatch;
  state: EditorState;
  showErrors?: boolean;
  solicitacao: UpdateWPInEdition['solicitacao'];
};

export type ActionProps = {
  text: string;
  icon: (size: IconType) => JSX.Element;
  enable?: boolean;
  disabled?: boolean;
};

type Status =
  | 'pre-update'
  | 'update'
  | 'success'
  | 'init'
  | 'error'
  | 'pdf'
  | 'sei'
  | 'accept'
  | 'refuse';

const ActionButton: React.FC<Props> = props => {
  const { id, solicitacao, state } = props;
  const { profile } = useAuthState();
  const [action, setAction] = useState<string>();
  const [status, setStatus] = useState<Status>('init');
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>();

  const request = useRequest();

  const { t, tx } = useTranslationX('actions', 'update_wp');

  useEffect(() => {
    if (status === 'pre-update' && props.hasErrors === true) {
      setStatus('error');
    } else if (status === 'pdf') {
      const source = request({
        url: `${API_SERVER_URL}/projeto/${id}/alteracao-pt/pdf-description`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
        },
        onError: () => null,
      });

      return () => source.cancel();
    } else if (status === 'sei') {
      const source = request({
        url: `${API_SERVER_URL}/projeto/${id}/alteracao-pt/html`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'text',
        onSuccess: async (text: any) => {
          await navigator.clipboard.writeText(text);
          messageService.success(tx('success-copied-message'), {
            duration: 3000,
          });
        },
        onError: () => {
          messageService.error(tx('error-copied-message'), {
            duration: 3000,
          });
        },
        onComplete: () => {
          setStatus('init');
        },
      });
      return () => source.cancel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hasErrors, status, id]);

  const isSupervisor = profile === Profile.SUPERVISOR;

  const isFromSupervisor = solicitacao?.perfil === Profile.SUPERVISOR && isSupervisor;

  const supervisor: ActionProps[] = useMemo(() => {
    if (solicitacao?.perfil === Profile.SUPERVISOR) {
      return [
        {
          text: 'update',
          icon: AddCheck,
          disabled: props.hasErrors,
        },
        { text: 'check-errors', icon: WarningAlt, enable: props.showErrors },
        { text: 'pdf', icon: PDF },
        { text: 'sei', icon: Sei },
      ];
    }
    return [
      {
        text: 'accept',
        icon: AddCheck,
        disabled: props.hasErrors,
      },
      {
        text: 'refuse',
        icon: XRect,
        disabled: solicitacao?.estado !== 'ANS',
      },
      { text: 'check-errors', icon: WarningAlt, enable: props.showErrors },
      { text: 'pdf', icon: PDF },
      { text: 'sei', icon: Sei },
    ];
  }, [props.hasErrors, props.showErrors, solicitacao?.estado, solicitacao?.perfil]);

  const pesquisador: ActionProps[] = [
    {
      text: solicitacao?.aditivo ? 'requestAdditive' : 'requestUpdate',
      icon: AddCheck,
      disabled: props.hasErrors || state.declaration === 0,
    },
    { text: 'check-errors', icon: WarningAlt, enable: props.showErrors },
  ];

  const actions = isSupervisor ? supervisor : pesquisador;

  const handleAction = useCallback(
    (action: string) => {
      if (['requestUpdate', 'requestAdditive', 'update'].includes(action)) {
        if (!props.hasErrors && props.showErrors) {
          setAction('update');
          setStatus('pre-update');
          return;
        }

        props.dispatch({ type: 'SHOW_ERRORS', payload: true });
        if (props.showErrors === false || props.showErrors === undefined) {
          setStatus('error');
        }
        setAction('update');
      } else if (action === 'pdf') {
        setStatus('pdf');
      } else if (action === 'sei') {
        setStatus('sei');
      } else if (action === 'check-errors') {
        props.dispatch({ type: 'SHOW_ERRORS', payload: !props.showErrors });
      } else if (action === 'accept') {
        setStatus(action);
      } else if (action === 'refuse') {
        setStatus(action);
      }

      if (!['update', 'requestUpdate', 'requestAdditive'].includes(action)) {
        setAction(action);
      }
    },
    [props]
  );

  useEffect(() => {
    if (state.errors && status === 'error') {
      let errorsSum = 0;
      Object.keys(state.errors).forEach(key => {
        errorsSum += state.errors?.[key].errors as number;
      });

      if (errorsSum === 0) {
        setStatus('pre-update');
      }
    }
  }, [state.errors, props, status]);

  const updateAction = useCallback((action: string | undefined) => {
    setAction(action);
  }, []);

  const updateStatus = useCallback((status: Status) => {
    setStatus(status);
  }, []);

  const renderPopup = () => {
    if (!action || status === 'error') return <></>;

    const isRefuse = action === 'refuse';

    const actionValue = action === 'accept' ? 'update' : action;

    const titleMessage = isFromSupervisor
      ? `accept-confirm.message`
      : `${action}-confirm.message`;

    return (
      <>
        <div className={styles.header}>
          <span>{`${tx(titleMessage)}`}</span>
          <IconButton
            icon={XAlt}
            type="dark"
            size={28}
            color="#333333"
            style={{
              root: {
                marginTop: 5,
              },
            }}
            onClick={closeSubmitModal}
          />
        </div>

        <div className={styles.container}>
          <UpdateWpForm
            solicitacao={solicitacao}
            id={id}
            updateAction={updateAction}
            updateStatus={updateStatus}
            accept={!isRefuse}
            type={actionValue as ActionType}
          />
        </div>
      </>
    );
  };

  const updateWpModalStatus = ['pre-update', 'accept', 'refuse'].includes(status);

  const closeModal = () => {
    updateStatus('init');
    updateAction(undefined);
  };

  const closeSubmitModal = () => {
    if (!props.hasErrors) {
      updateStatus('init');
      updateAction(undefined);
    }
  };

  return (
    <>
      <div className={styles.actionButtons}>
        {actions.map((data, index) => {
          if (
            ['requestAdditive', 'requestUpdate'].includes(data.text) &&
            state.declaration === 0
          ) {
            return (
              <Tippy
                key={index}
                content={tx('acceptDeclaration')}
                className={styles.popup}
                animation="perspective"
                touch={false}
                followCursor="horizontal"
                plugins={[followCursor]}
              >
                <div>
                  <Button
                    type={
                      data.disabled !== undefined && data.disabled
                        ? 'disabled'
                        : undefined
                    }
                    icon={data.icon}
                    theme="light"
                    key={index}
                    onClick={() => handleAction(data.text)}
                    active={data.enable !== undefined ? data.enable : false}
                  >
                    {tx(data.text)}
                  </Button>
                </div>
              </Tippy>
            );
          }
          return (
            <Button
              type={data.disabled !== undefined && data.disabled ? 'disabled' : undefined}
              icon={data.icon}
              theme="light"
              key={index}
              onClick={() => handleAction(data.text)}
              active={data.enable !== undefined ? data.enable : false}
            >
              {tx(data.text)}
            </Button>
          );
        })}
      </div>

      <Modal visible={status === 'pdf'} width="auto" onBlur={closeModal}>
        <div className={styles.popup}>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('updateWpDocument')}
            onClose={closeModal}
          />
        </div>
      </Modal>

      <Modal
        visible={updateWpModalStatus}
        width={700}
        onBlur={closeSubmitModal}
        onClose={closeSubmitModal}
      >
        <div className={styles.popup}>{renderPopup()}</div>
      </Modal>

      <ErrorDialog
        visible={status === 'error' && !!props.hasErrors}
        onClose={closeModal}
        message={t('actions.error-message', {
          action: tx(`${action}`).toLowerCase(),
        })}
        buttonLabel={tx('button.ok')}
      />
    </>
  );
};

type ActionType = 'update' | 'accept' | 'refuse' | 'discard';

type UpdateWpFormProps = {
  id: number;
  updateStatus: (status: Status) => void;
  updateAction: (action: string | undefined) => void;
  accept: boolean;
  type: ActionType;
  solicitacao: UpdateWPInEdition['solicitacao'];
};

const UpdateWpForm = ({
  id,
  updateStatus,
  updateAction,
  accept,
  type,
  solicitacao,
}: UpdateWpFormProps) => {
  const history = useHistory();
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const request = useRequest();

  const { profile } = useAuthState();
  const supervisor = profile === Profile.SUPERVISOR;

  const { tx } = useTranslationX('actions', 'update_wp');

  const redirectToDashboard = () => {
    setTimeout(() => {
      history.push(`/dashboard/projetos/${id}/`);
    }, 1100);
  };

  const onSuccess = () => {
    if (type === 'update' && supervisor && accept && solicitacao) {
      request({
        url: `${API_SERVER_URL}/projeto/${id}/execucao/alteracao-pt/solicitacao/${solicitacao.id}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
            setShowPopup(true);
          };
        },
        onError: () => null,
      });
    } else {
      updateStatus('success');
      messageService.success(tx(`action-success.${type}`), {
        position: 'bottom',
        duration: 1000,
      });
      redirectToDashboard();
    }
  };

  const closeModal = () => {
    setShowPopup(false);
    updateStatus('success');
    messageService.success(tx(`action-success.${type}`), {
      position: 'bottom',
      duration: 1000,
    });
    redirectToDashboard();
  };

  const formProps: ComponentPropsWithoutRef<typeof Form> = {
    ...(!supervisor && {
      baseUrl: `projeto/${id}/alteracao-pt/action/${type}`,
      onDone: onSuccess,
    }),
    ...(supervisor && {
      onSubmit: ({ requestBody }) => {
        if (solicitacao) {
          const formData = new FormData();
          formData.append('notificationId', String(solicitacao.id));
          formData.append('tipo', 'project.alteration-pt');
          formData.append('accept', String(accept));
          formData.append('nonCanceled', String(true));
          if (get(requestBody, 'justificativa') !== undefined) {
            formData.append('textResponse', String(get(requestBody, 'justificativa')));
          }
          const source = request<{ error?: string }>({
            url: '/homologacao',
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
            onSuccess,
            onError: error => {
              updateStatus('init');
              messageService.error(error.response?.data.error as string);
            },
          });
          return () => source.cancel();
        }
      },
    }),
  };
  return (
    <>
      <Form {...formProps}>
        <TextEditor
          name="justificativa"
          width="100%"
          label={tx('reason')}
          editorStyle={{
            background: '#EEEEEE',
            borderRadius: 6,
            padding: '7px 10px',
          }}
        />
        <div className={styles.footer}>
          <Button
            type="secondary"
            onClick={() => {
              updateStatus('init');
              updateAction(undefined);
            }}
          >
            {tx('button.cancel')}
          </Button>
          <SubmitButton style={{ root: { marginLeft: UI_BUTTON_SPACING } }}>
            {tx('button.send')}
          </SubmitButton>
        </div>
      </Form>
      <Modal visible={showPopup} width="auto" onBlur={closeModal}>
        <div className={styles.popup}>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('updateWpDocument')}
            onClose={closeModal}
          />
        </div>
      </Modal>
    </>
  );
};

export default ActionButton;
