import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, Select, MultiSelect, Autocomplete } from 'components/form';
import { Checkbox, useFormFieldObserver } from 'components/form';
import { SectionProps } from '../types';
import useBond from './useBond';

const Contributor = ({ dispatch }: SectionProps) => {
  const { t } = useTranslation('register');

  const type1 = useFormFieldObserver('bond').value ? undefined : 'disabled';
  const type2 = useBond(dispatch) ? undefined : 'disabled';

  return (
    <Grid width={580}>
      <Row>
        <TextField name="areaFormacao" label={t('academic.major')} />
        <Select name="titulacao" label={t('researcher.level')} />
      </Row>

      <TextField name="lattes" label={t('academic.resume')} />

      <div>
        <Checkbox name="bond" label={t('contributor.bond')} omit />
      </div>

      <Autocomplete
        url="/cadastro/instituicoes"
        name="instituicao"
        label={t('academic.institution')}
        type={type1}
      />

      <Row>
        <Select name="campus" label={t('researcher.campus')} type={type2} />
        <TextField name="siape" label={t('researcher.id')} type={type2} />
      </Row>

      <MultiSelect
        name="areaPesquisa"
        label={t('academic.areas')}
        modalTitle={t('academic.areas')}
      />
    </Grid>
  );
};

export default Contributor;
