import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';
import { toDateDDMMYYY } from 'utils/calendarUtils';
import { GenericObject } from 'components/inputs/types';

export type Data = {
  nome: string;
  cpf: string;
  dataNascimento: Date;
  genero: string;
  rg: string;
  rgEmissor: string;
  rgUf: string;
  status: boolean;
  endereco: string;
  email: string;
  telefone: string;
};

const Person = ({ data, diff }: { data: Data; diff: GenericObject }) => {
  const { t } = useTranslation('register');
  const {
    nome,
    cpf,
    genero,
    dataNascimento,
    rg,
    rgEmissor,
    rgUf,
    endereco,
    email,
    telefone,
  } = data;

  return (
    <Grid spacing={0}>
      <Row width={[10, 5, 5, 10]} align="top">
        <PlainField label={t('personal.name')} value={nome} overlay={diff['nome']} />
        <PlainField label={t('personal.id')} value={cpf} overlay={diff['cpf']} />
        <PlainField
          label={t('personal.genero.label')}
          value={t(`personal.genero.${genero}`)}
          overlay={diff['genero']}
        />
        <PlainField
          label={t('personal.dataNascimento')}
          value={toDateDDMMYYY(dataNascimento)}
          overlay={
            diff['dataNascimento']
              ? toDateDDMMYYY(diff['dataNascimento'] as string)
              : undefined
          }
        />
      </Row>

      <Row width={[10, 10, 10]} align="top">
        <PlainField label={t('personal.rg')} value={rg} overlay={diff['rg']} />
        <PlainField
          label={t('personal.orgao')}
          value={rgEmissor}
          overlay={diff['rgEmissor']}
        />
        <PlainField label={t('personal.uf')} value={rgUf} overlay={diff['rgUf']} />
      </Row>

      <Row width={[40, 27, 33]} align="top">
        <PlainField
          label={t('address.title')}
          value={endereco}
          overlay={diff['endereco']}
        />
        <PlainField label={t('personal.email')} value={email} overlay={diff['email']} />
        <PlainField
          label={t('personal.phone')}
          value={t(telefone)}
          overlay={diff['telefone']}
        />
      </Row>
    </Grid>
  );
};

export default Person;
