import React from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { classes } from 'utils/components';

import styles from './PlainField.module.scss';

type Props = {
  label: string;
  value: string;
  type?: 'text' | 'url' | 'html';
  style?: React.CSSProperties;
  padding?: 'normal' | 'none';
  overlay?: unknown;
};

const PlainField = ({
  label,
  value,
  type = 'text',
  padding = 'normal',
  overlay,
}: Props) => {
  const content = (
    <>
      <span className={styles.label}>{label}</span>

      {type === 'html' && (
        <span className={styles.value} dangerouslySetInnerHTML={{ __html: value }} />
      )}

      {type === 'text' && <span className={styles.value}>{value}</span>}

      {type === 'url' && (
        <a href={value} target="_blank" rel="noopener noreferrer">
          <span className={styles.value}>{value}</span>
        </a>
      )}
    </>
  );
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {overlay !== undefined ? (
        <Tippy
          content={String(overlay ?? '-/-')}
          className={styles.popup}
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          <div className={classes(styles.plainField, styles[padding], styles.overlay)}>
            {content}
          </div>
        </Tippy>
      ) : (
        <div className={classes(styles.plainField, styles[padding])}>{content}</div>
      )}
    </div>
  );
};

export default PlainField;
