import { ScrollPanel } from 'components/containers';
import React, { useReducer } from 'react';
import {
  CounterPartCardGroup,
  CounterpartList,
  CounterpartScheduleList,
  CounterpartSolicitationList,
} from '..';
import { ActionCounterpart, CounterpartState } from './types';

import styles from './Dashboard.module.scss';

const reducer = (
  state: CounterpartState,
  action: ActionCounterpart
): CounterpartState => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, lastUpdate: new Date().getTime() };
    default:
      return state;
  }
};

const Dashboard = () => {
  const [state, dispatch] = useReducer(reducer, {
    lastUpdate: new Date().getTime(),
  });

  return (
    <div className={styles.dashboard}>
      <ScrollPanel>
        <div className={styles.content}>
          <CounterPartCardGroup state={state} />
          <CounterpartList state={state} dispatch={dispatch} />
          <CounterpartScheduleList state={state} dispatch={dispatch} />
          <CounterpartSolicitationList state={state} dispatch={dispatch} />
        </div>
      </ScrollPanel>
    </div>
  );
};

export default Dashboard;
