import React from 'react';

import { useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { useHistory } from 'react-router-dom';

import { Card, ScrollPanel } from 'components/containers';
import { ArchiveGraduationCap } from 'components/icons';
import { Button, Loader, PercentCardWithIcon } from 'components/elements';
import RecentProjectList from './RecentProjectList';
import { RequestTable } from '../index';
import { Profile } from 'pages/register/profile';
import { Form, TextField } from 'components/form';
import {
  FieldObserverResult,
  FormInstance,
  FormObserverResult,
} from 'components/form/types';

import styles from './Summary.module.scss';

export type Props = {
  profile: string;
};

const Dashboard: React.FC<Props> = ({ profile }: Props) => {
  const { tx } = useTranslationX('dashboard', 'project');
  const history = useHistory();

  const formRef = React.createRef<FormInstance>();

  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const [debouncedFilter, setDebouncedFilter] = React.useState<string>('');

  const isSupervisor = profile === Profile.SUPERVISOR;

  const { data } = useFetch<any>(`/dashboard/totals`);

  React.useEffect(() => {
    if (formRef) {
      const currentForm = formRef?.current;

      const getFilter = (result: FormObserverResult) => {
        const filter = (result as FieldObserverResult).data?.value;
        setSearchFilter(filter);
      };

      currentForm?.observer.subscribe(getFilter, { field: 'filter' });

      return () => {
        currentForm?.observer.unsubscribe(getFilter);
      };
    }
  }, [formRef]);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(searchFilter);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchFilter]);

  if (!data) {
    return <Loader />;
  }
  return (
    <div className={styles.summary}>
      <div className={styles.header}>
        <div className={styles.totals}>
          <PercentCardWithIcon
            label={tx('percentualCarts.project')}
            icon={ArchiveGraduationCap}
            value={data.projetos}
            onClick={() => history.replace('todos')}
          />
          {data.aprovadas !== undefined ? (
            <PercentCardWithIcon
              label={tx('percentualCarts.proposalApproved')}
              icon={ArchiveGraduationCap}
              value={data.aprovadas}
            />
          ) : null}
        </div>
        <div className={styles.addButton}>
          {isSupervisor && (
            <Button
              onClick={() => history.replace('criar-projeto')}
              style={{ root: { marginTop: 0 } }}
            >
              {tx('add')}
            </Button>
          )}
        </div>
      </div>

      <ScrollPanel>
        <div className={styles.body}>
          <Form ref={formRef}>
            <Card
              title={tx('recents.title')}
              width={{
                max: '90%',
                min: '90%',
              }}
              rightChildren={
                <Button
                  type="secondary"
                  onClick={() => history.replace('todos')}
                  style={{ root: { marginRight: 0 } }}
                >
                  {tx('recents.seeAll')}
                </Button>
              }
              bodyStyle={{ justifyContent: 'flex-start' }}
            >
              <div className={styles.filters}>
                <TextField
                  name="filter"
                  label={tx('filter')}
                  style={{
                    root: {
                      maxWidth: '260px',
                    },
                  }}
                />
                <RecentProjectList search={debouncedFilter} />
              </div>
            </Card>
          </Form>

          <RequestTable maxHeight="500px" general />
        </div>
      </ScrollPanel>
    </div>
  );
};

export default Dashboard;
