import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useFetch } from 'apis';
import { ProposalListView, ProposalEditor } from 'pages/proposal';
import { Profile } from 'pages/register/profile';
import RouteData from 'pages/utils/RouteData';
const Proposta = () => {
  const { path } = useRouteMatch();
  const { data } = useFetch<string[]>('/homologacao/filtros');

  if (!data) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <ProposalListView showManager />
      </Route>

      <Route path={`${path}/:id`}>
        <RouteData>
          {props => <ProposalEditor {...props} profile={Profile.SUPERVISOR} />}
        </RouteData>
      </Route>
    </Switch>
  );
};

export default Proposta;
