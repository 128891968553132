import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslationX } from 'i18n';
import { ContractAndPeople } from './RequestForm';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'apis';
import { messageService } from 'services';
import { Grid, Row } from 'components/containers';
import { FieldSet, HiddenField } from 'components/form';
import { FileUploader, Loader } from 'components/elements';

type ProfileType = 'Estudante' | 'CLT' | 'Servidor' | 'archives';

type ArchivesProps = {
  profileType?: ProfileType;
  projectId: number;
  handleArchives: (value?: boolean[]) => void;
  data?: ContractAndPeople;
  reload: () => void;
};

const archivesType = {
  Estudante: [
    ['rg', 'comprovante_residencia'],
    ['declaracao_matricula', 'historico_escolar'],
    ['comprovante_conta_corrente', 'descricao_processo_seletivo'],
    ['termo_confidencialidade', 'declaracao_pi'],
    ['outorga_e_concessao'],
  ],
  Servidor: [
    ['rg', 'contracheque'],
    ['declaracao_pi', 'termo_confidencialidade'],
    ['outorga_e_concessao', 'declaracao_vencimentos'],
  ],
  CLT: [
    ['rg', 'comprovante_titulacao_formacao'],
    ['declaracao_pi', 'termo_confidencialidade'],
    ['curriculo', 'ctps'],
    ['cpf', 'descricao_processo_seletivo'],
    ['titulo_eleitor', 'foto'],
    ['comprovante_residencia', 'comprovante_escolaridade'],
    ['certidao_casamento', 'reservista'],
  ],
  archives: [],
};

export const HumanResourcesArchives = (props: ArchivesProps) => {
  const { profileType, projectId, handleArchives, data, reload } = props;

  const { tx } = useTranslationX('request.form', 'project');

  const history = useHistory<{ profileType?: string; id?: number }>();
  const { id } = history.location.state || {};

  const request = useRequest();

  const isFemale = data?.contrato.genero === 'F';
  const isNotMarried = data?.contrato.estadoCivil !== 'CA';

  const filterCLTArchives = useCallback(
    (
      archives: {
        name: string;
        arquivoId: string | number | undefined;
      }[]
    ) => {
      if (isFemale) {
        archives = archives.filter(value => value.name !== 'reservista');
      }

      if (isNotMarried) {
        archives = archives.filter(value => value.name !== 'certidao_casamento');
      }
    },
    [isFemale, isNotMarried]
  );

  const updatedDocuments = useMemo(() => {
    const selectedArchives = archivesType[profileType as ProfileType];

    let archives = selectedArchives.flat().map(archive => {
      const archiveName = toCamelCase(
        `doc_${archive}`
      ) as keyof ContractAndPeople['contrato'];
      return {
        name: archive,
        arquivoId: data?.contrato[archiveName],
      };
    });

    if (profileType === 'CLT') {
      filterCLTArchives(archives);
    }

    return archives;
  }, [data, profileType, filterCLTArchives]);

  const informArchiveSuccessMessage = useCallback(
    (documentName: string) => {
      const hasFileAlreadyUpdated = updatedDocuments?.find(
        doc => !!(documentName === doc.name && doc.arquivoId !== null)
      );
      if (!hasFileAlreadyUpdated) {
        messageService.success(
          tx('archiveSuccess', {
            documentName: tx(`documentsUpload.${documentName}`),
          }),
          {
            duration: 2000,
          }
        );
      }
    },
    [tx, updatedDocuments]
  );

  const handleSubmitArchives = useCallback(
    (files: File[], documentName: string) => {
      if (files.length === 0) return;

      const formData = new FormData();
      formData.set('file', files[0]);
      const source = request({
        method: 'POST',
        url: `/projeto/${projectId}/recursos-humanos/${id}/documento/set_doc_${documentName}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: () => informArchiveSuccessMessage(documentName),
        onError: () => {
          messageService.error(
            tx('archiveError', {
              documentName: tx(`documentsUpload.${documentName}`),
            }),
            {
              duration: 2000,
            }
          );
        },
        onComplete: reload,
      });
      return () => source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, projectId, request, informArchiveSuccessMessage]
  );

  const rhArchiveStatusArray = useMemo(() => {
    return updatedDocuments?.map(doc => {
      return doc.arquivoId !== null;
    });
  }, [updatedDocuments]);

  useEffect(() => {
    handleArchives(rhArchiveStatusArray);
  }, [handleArchives, rhArchiveStatusArray]);

  if (!data) {
    return <Loader type="spin" />;
  }

  function getArchivesType() {
    const archives = archivesType[(profileType as ProfileType) || 'Servidor'];

    if (profileType === 'CLT') {
      if (isFemale && isNotMarried) {
        delete archives[6];
      } else if (isFemale) {
        archives[6] = ['certidao_casamento'];
      } else if (isNotMarried) {
        archives[6] = ['reservista'];
      }
    }

    return archives;
  }

  const archivesTypes = getArchivesType();
  const fiveGBSize = 5242880;

  return (
    <Grid minWidth="1100px">
      <HiddenField name="profileType" value={profileType} />
      <FieldSet name="contrato">
        {archivesTypes.map(archive => {
          const isUploadedArchive = archive.flat().map(arq => {
            return updatedDocuments?.find(doc => {
              if (doc.arquivoId !== null && doc.name === arq) {
                return doc.name;
              }
              return null;
            });
          });

          return (
            <Row key={archive[1]}>
              <FileUploader
                message={tx(`documentsUpload.${archive[0]}`)}
                metadata={{
                  rules: { accept: ['pdf'], maxfiles: 1, filesize: fiveGBSize },
                }}
                onChange={file => handleSubmitArchives(file, archive[0])}
                type="sneaky"
                hasFilesUploaded={isUploadedArchive[0]?.arquivoId !== undefined}
              />
              {archive[1] && (
                <FileUploader
                  message={tx(`documentsUpload.${archive[1]}`)}
                  metadata={{
                    rules: { accept: ['pdf'], maxfiles: 1, filesize: fiveGBSize },
                  }}
                  onChange={file => handleSubmitArchives(file, archive[1])}
                  type="sneaky"
                  hasFilesUploaded={isUploadedArchive[1]?.arquivoId !== undefined}
                />
              )}
            </Row>
          );
        })}
      </FieldSet>
    </Grid>
  );
};

function toCamelCase(str: string): keyof ContractAndPeople['contrato'] {
  return str.replace(/_([a-z])/g, function (_, group1) {
    return group1.toUpperCase();
  }) as keyof ContractAndPeople['contrato'];
}
