import React from 'react';

import useFetch from 'apis/useFetch';

import ProjectLatestCard, { ProjectLatestItem } from './ProjectLatestCard';
import { Loader, NotFoundMessage } from 'components/elements';
import { ScrollPanel } from 'components/containers';

import styles from './RecentProjectList.module.scss';

const RecentProjectList = ({ search }: { search: string }) => {
  const filterQuery = search ? `?search=${search}` : '';
  const { data } = useFetch<ProjectLatestItem[]>(`/projetos/recentes${filterQuery}`);

  if (!data) {
    return <Loader size={60} delay type="spin" />;
  }

  return data.length > 0 ? (
    <ScrollPanel vBar={{ overlay: true }} style={{ content: { maxHeight: '400px' } }}>
      <div className={styles.projectList}>
        {data.map((project: ProjectLatestItem) => (
          <ProjectLatestCard key={project.id} project={project} />
        ))}
      </div>
    </ScrollPanel>
  ) : (
    <NotFoundMessage />
  );
};

export default RecentProjectList;
