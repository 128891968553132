import { Button, FileDownloader } from 'components/elements';
import React from 'react';
import { readAttr } from 'utils/object';
import ArchiveList from './ArchiveList/ArchiveList';
import { formatDateToBrazilian } from 'utils/stringUtils';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';

export type ReadOnlyTableDataSection = {
  data?: any;
  config: ReadOnlyTableDataSectionRowConfig[];
};

export type ReadOnlyTableDataSectionRowConfig = {
  width: number;
  title: string;
  field: string;
  defaultValue?: string;
  type?: 'value' | 'archive' | 'archives' | 'date' | 'list' | 'link' | 'title' | 'html';
  parse?: (value: any) => any;
  data?: ReadOnlyTableDataSectionRowConfig[];
};

const PeopleDataTableRow: React.FC<ReadOnlyTableDataSection> = props => {
  const { config, data } = props;

  const history = useHistory();

  if (data == null) {
    return <></>;
  }
  return (
    <table>
      <colgroup>
        {config.map(item => (
          <col
            key={item.field}
            style={
              typeof item.width === 'number' ? { width: `${item.width}%` } : undefined
            }
          />
        ))}
      </colgroup>
      <thead>
        <tr>
          {config.map((item, i) => {
            if (item.type === 'title') {
              return (
                <th key={i} style={{ textAlign: 'center', fontSize: '14px' }}>
                  {item.title}
                </th>
              );
            }

            return <th key={i}>{item.type === 'link' ? '' : item.title}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {config.map((item, i) => {
            const type = item.type || 'value';
            const value =
              readAttr(item.field, data) !== undefined
                ? readAttr(item.field, data)
                : item.defaultValue || '-';

            const parsedValue = item.parse ? item.parse(value) : value;
            const renderTableDescription = {
              date: (
                <td key={i}>
                  {isNaN(Date.parse(parsedValue))
                    ? parsedValue
                    : formatDateToBrazilian(parsedValue)}
                </td>
              ),
              list: (
                <td key={i}>
                  <table>
                    <thead>
                      <tr>
                        {item.data?.map((item, i) => (
                          <th key={i}>{item.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {value.map
                        ? value.map((v: any) => {
                            return (
                              <tr>
                                {item.data?.map((item, i) => (
                                  <td key={i}>{v[item.field]}</td>
                                ))}
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </td>
              ),
              value: <td key={i}>{parsedValue}</td>,
              title: <td key={i}>{parsedValue}</td>,
              html: (
                <td
                  key={i}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parsedValue) }}
                />
              ),

              archive: parsedValue && (
                <td key={i}>
                  <FileDownloader id={parsedValue} />
                </td>
              ),
              archives: parsedValue && (
                <td key={i}>
                  <ArchiveList values={parsedValue} />
                </td>
              ),
              link: (
                <td key={i}>
                  <Button
                    theme="light"
                    style={{ root: { margin: 0 } }}
                    onClick={() => history.push(parsedValue)}
                  >
                    {item.title}
                  </Button>
                </td>
              ),
            };
            return renderTableDescription[type];
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default PeopleDataTableRow;
