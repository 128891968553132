import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, Select, MultiSelect } from 'components/form';

const Researcher = () => {
  const { t } = useTranslation('register');

  return (
    <Grid width={580}>
      <Select name="campus" label={t('researcher.campus')} />
      <TextField name="lattes" label={t('academic.resume')} />

      <Row>
        <TextField name="siape" label={t('researcher.id')} />
        <Select name="titulacao" label={t('researcher.level')} />
      </Row>

      <MultiSelect
        name="areaPesquisa"
        label={t('academic.areas')}
        modalTitle={t('academic.areas')}
      />
    </Grid>
  );
};

export default Researcher;
