import React from 'react';

export type CounterpartStatus = 'LAT' | 'SOL' | 'REJ' | 'COM' | 'PLA';
export const conclusionStatusColors: { [key in CounterpartStatus]: string } = {
  LAT: '#E51616',
  COM: '#37A64A',
  SOL: '#BBEBC4',
  PLA: '#C4C4C4',
  REJ: '#ffdd57',
};

export type Conclusion = {
  id: string | number;
  estado: CounterpartStatus;
  mes: number;
};

export type CounterpartConclusion = {
  id: number;
  nome: string;
  pagamentos: Conclusion[];
};

export type CounterpartConclusions = {
  inicioRelativo: Date;
  duracao: number;
  meses: string[];
  contratos: CounterpartConclusion[];
};

export type StateButton = {
  conclusions: Conclusion[];
  validDate: ValidDate[];
  pendingConclusions: Conclusion[];
};

type ValidDate = {
  [key: string]: boolean;
};

export type ActionButton =
  | {
      type: 'SHOW_BUTTON';
      payload: {
        conclusions: Conclusion[];
        validDate: ValidDate[];
      };
    }
  | {
      type: 'ADD_CONCLUSION';
      payload: {
        conclusions: Conclusion[];
        validDate: ValidDate[];
      };
    }
  | {
      type: 'REMOVE_CONCLUSION';
      payload: {
        conclusions: Conclusion[];
        validDate: ValidDate[];
      };
    }
  | {
      type: 'SET_PENDING_CONCLUSIONS';
      payload: {
        pendingConclusions: Conclusion[];
      };
    };

export type DispatchButton = React.Dispatch<ActionButton>;
