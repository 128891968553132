import React from 'react';

const Financial = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5745 8.00919L15.0667 12.5452V14.5828L20.5921 9.03159C20.7096 8.89385 20.7709 8.71668 20.7639 8.53547C20.757
        8.35427 20.6822 8.18237 20.5546 8.05414C20.4269 7.92592 20.2558 7.8508 20.0755 7.8438C19.8951 7.8368 19.7187 7.89844 19.5816
        8.01639L19.5745 8.00919Z"
        fill="currentColor"
      />
      <path
        d="M11.4833 17.8802C11.341 17.881 11.2016 17.8393 11.083 17.7602C10.9643 17.6811 10.8718 17.5684 10.8173
        17.4363C10.7627 17.3042 10.7485 17.1588 10.7765 17.0186C10.8045 16.8784 10.8734 16.7497 10.9745 16.649L15.0666
        12.545V6.3602C15.0666 5.78733 14.8401 5.23792 14.4369 4.83284C14.0337 4.42777 13.4869 4.2002 12.9166
        4.2002H5.74998C5.17976 4.2002 4.6329 4.42777 4.2297 4.83284C3.82649 5.23792 3.59998 5.78733 3.59998 6.3602L3.59998
        17.8802C3.59998 18.4531 3.82649 19.0025 4.2297 19.4075C4.6329 19.8126 5.17976 20.0402 5.74998 20.0402H12.9166C13.4869
        20.0402 14.0337 19.8126 14.4369 19.4075C14.8401 19.0025 15.0666 18.4531 15.0666 17.8802V14.5754L11.9921
        17.6642C11.9258 17.7322 11.8466 17.7863 11.7593 17.8234C11.6719 17.8605 11.5781 17.8798 11.4833 17.8802ZM6.46664
        7.0802H12.2C12.39 7.0802 12.5723 7.15605 12.7067 7.29108C12.8411 7.4261 12.9166 7.60924 12.9166 7.8002C12.9166
        7.99115 12.8411 8.17429 12.7067 8.30931C12.5723 8.44434 12.39 8.5202 12.2 8.5202H6.46664C6.27657 8.5202
        6.09428 8.44434 5.95988 8.30931C5.82548 8.17429 5.74998 7.99115 5.74998 7.8002C5.74998 7.60924 5.82548 7.4261
        5.95988 7.29108C6.09428 7.15605 6.27657 7.0802 6.46664 7.0802ZM6.46664 9.9602H12.2C12.39 9.9602 12.5723 10.0361
        12.7067 10.1711C12.8411 10.3061 12.9166 10.4892 12.9166 10.6802C12.9166 10.8712 12.8411 11.0543 12.7067
        11.1893C12.5723 11.3243 12.39 11.4002 12.2 11.4002H6.46664C6.27657 11.4002 6.09428 11.3243 5.95988
        11.1893C5.82548 11.0543 5.74998 10.8712 5.74998 10.6802C5.74998 10.4892 5.82548 10.3061 5.95988 10.1711C6.09428
        10.0361 6.27657 9.9602 6.46664 9.9602ZM5.74998 13.5602C5.74998 13.3692 5.82548 13.1861 5.95988 13.0511C6.09428
        12.9161 6.27657 12.8402 6.46664 12.8402H12.2C12.39 12.8402 12.5723 12.9161 12.7067 13.0511C12.8411 13.1861
        12.9166 13.3692 12.9166 13.5602C12.9166 13.7512 12.8411 13.9343 12.7067 14.0693C12.5723 14.2043 12.39 14.2802
        12.2 14.2802H6.46664C6.27657 14.2802 6.09428 14.2043 5.95988 14.0693C5.82548 13.9343 5.74998 13.7512 5.74998 13.5602Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Financial;
