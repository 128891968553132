import React from 'react';
import { useForm, useFormFieldObserver } from 'components/form';
import { BiCommentError as ErrorIcon } from 'react-icons/bi';
import styles from './InputControlAdapter.module.scss';

export type Props = {
  field: string;
};

const InputControlAdapter = (props: Props) => {
  const observer = useFormFieldObserver(props.field);
  const form = useForm();
  const issueObserver = observer.data?.issue;
  const display = form.display;

  const computeError = () => {
    if (form.editable && issueObserver) {
      if (display.error === 'always' || issueObserver.imperative) {
        if (issueObserver.type === 'error') {
          return issueObserver.message;
        }
      }
    }
  };

  const error = computeError();

  return (
    <div className={styles.body}>
      {error && (
        <div className={styles.error}>
          <ErrorIcon />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputControlAdapter;
