import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'i18n';
import { useFetch } from 'apis';
import { Logo } from 'components/elements';
import LoginForm from '../LoginForm/LoginForm';
import CredentialsForm from '../CredentialsForm/CredentialsForm';

import TokenDispatcher from '../TokenDispatcher';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';

import logo_ifce from 'assets/images/logo_ifce.svg';
import logo_embrapii from 'assets/images/logo_embrapii.png';
import banner from 'assets/images/login_banner.svg';

import styles from './Login.module.scss';
import { VersionControl } from 'components/containers';

type Footer = {
  nome: string;
  endereco: string;
  telefone: string;
};

const Login = () => {
  const { data: footer } = useFetch<Footer>('/login/metadata', { resource: 'footer' });
  const { t } = useTranslation();

  const location = useLocation<{ resetPassword: boolean; getCredentials: boolean }>();
  const { token } = useParams<{ token: string }>();

  if (!footer) {
    return null;
  }

  const isGetCredentials = location.state?.getCredentials;

  let content = location.state?.resetPassword ? (
    <ResetPasswordForm />
  ) : isGetCredentials ? (
    <CredentialsForm />
  ) : (
    <LoginForm />
  );

  if (token) {
    content = <TokenDispatcher token={token} />;
  }

  return (
    <div className={styles.login}>
      <div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <Logo />
            <p className={styles.loginTag}>
              {isGetCredentials ? t('login.getCredentials') : t('login.username')}
            </p>
            {!isGetCredentials && (
              <div className={styles.formInfo}>
                <span>
                  {t('login.loginDescription.login')}
                  <strong>{t('login.loginDescription.exclusive')} </strong>
                  {t('login.loginDescription.users')}
                  <br></br>
                  {t('login.loginDescription.credentials')}
                  <strong> {t('login.loginDescription.persons')}</strong>
                </span>
              </div>
            )}

            {content}
            <div className={styles.logos}>
              <img src={logo_ifce} alt="logo ifce" />
              <span />
              <img src={logo_embrapii} alt="logo embrapii" />
            </div>
          </div>

          <p className={styles.footer}>
            {`${footer?.nome}: ${footer?.endereco} - ${t('login.footer.phone')}: ${
              footer?.telefone
            }`}
          </p>
        </div>
        <div className={styles.banner}>
          <div className={styles.header}>
            <div>{t('login.welcome')}</div>
            <div>{t('sippi')}</div>
          </div>
          <img src={banner} alt="banner" />
        </div>
        <div className={styles.version}>
          <VersionControl type="footer" />
        </div>
      </div>
    </div>
  );
};

export default Login;
