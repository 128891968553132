import React, { useCallback } from 'react';

import { Button, IconButton } from 'components/elements';
import { useTranslationX } from 'i18n';

import * as icons from 'components/icons';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'apis';
import { messageService } from 'services';
import { EditorState } from '../types';
import { useMediaQuery } from 'components/hooks';

type Props = {
  state: EditorState;
};

const FixedButtons = ({ state }: Props) => {
  const { tx } = useTranslationX('actions', 'update_wp');

  const history = useHistory();
  const request = useRequest();

  const matches = useMediaQuery(`(min-width: 1920px)`);

  const redirectToProjectDashboard = useCallback(() => {
    history.push(`/dashboard/projetos/${state.id}/`);
  }, [history, state]);

  const handleDiscardChanges = useCallback(() => {
    const source = request({
      url: `projeto/${state.id}/alteracao-pt/action/discard`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
      onSuccess: () => {
        messageService.success(tx(`action-success.discard`), {
          position: 'bottom',
          duration: 1000,
        });

        setTimeout(() => {
          redirectToProjectDashboard();
        }, 1000);
      },
      onError: () => null,
    });

    return () => source.cancel();
  }, [tx, request, state, redirectToProjectDashboard]);

  return (
    <>
      <>
        {matches && (
          <Button
            icon={icons.Replay}
            onClick={handleDiscardChanges}
            theme="classic"
            style={{
              root: {
                maxWidth: 190,
                position: 'absolute',
                right: '4%',
              },
              text: {
                paddingLeft: 12,
              },
            }}
          >
            {tx('discard')}
          </Button>
        )}

        {!matches && (
          <IconButton
            size={30}
            hint={tx('discard')}
            type="danger"
            shape="square"
            icon={icons.Trash}
            onClick={handleDiscardChanges}
            style={{ root: { position: 'absolute', right: '4%' } }}
          />
        )}
      </>
    </>
  );
};

export default FixedButtons;
