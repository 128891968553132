import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { GoSync as SyncIcon } from 'react-icons/go';

import {
  FieldMetadata,
  Issue,
  FieldDisplaySettings,
  FieldTheme,
} from 'components/form/types';

import { FloatMessage } from 'components/elements';
import { isValidField } from 'components/form/helpers';
import { useValidation } from 'components/form/validate';
import { readAttr } from 'utils/object';
import { renderComponent, classes, focusRef } from 'utils/components';
import { isNil } from 'utils';
import { useTimer, useFirstRender } from 'components/hooks';
import useUpperCase from '../useUpperCase';

import styles from './InputControl.module.scss';
import { isEqual } from 'lodash';
import { useForm } from 'components/form';

export type ControlRef = {
  focus: () => void;
};

export type ControlState = { modified?: boolean; touched?: boolean; changed?: boolean };

export type ControlInstance = {
  value: any;
  valid?: boolean;
  issue?: Issue;
  setValue: (value: any) => void;
  reset: () => void;
  setLoading: (loading: boolean) => void;
  controlState?: ControlState;
};

export type Props = {
  name: string;
  label?: string;
  type?: 'readonly' | 'disabled';
  value: any;
  metadata: FieldMetadata;
  component: React.ElementType;
  onChange: (data: ControlInstance) => void;
  display: FieldDisplaySettings;
  inputProps: any;
  theme?: FieldTheme;
  validationToken: any;
  controlState?: ControlState;
};

type State = {
  value: any;
  initialValue: any;
  issue?: Issue;
  active?: boolean;
  loading?: boolean;
} & ControlState;

type Action = {
  type:
    | 'CHANGE_VALUE'
    | 'VALIDATE'
    | 'BLUR'
    | 'FOCUS'
    | 'ASYNC_VALIDATE'
    | 'RESET'
    | 'LOADING';
  payload?: any;
};

const InputControl = forwardRef<ControlRef, Props>((props, ref) => {
  const {
    name,
    label,
    type,
    value,
    metadata,
    component,
    onChange,
    display,
    inputProps,
    theme = 'classic',
    validationToken,
    controlState,
    ...rest
  } = props;

  const valueChanged = useRef(false);
  const stateRef = useRef<ControlState>({});
  const firstRender = useFirstRender();

  const form = useForm();

  const { validate, asyncValidate } = useValidation(metadata.validationUrl);

  const rules = readAttr('rules', metadata);

  const showErrors = rules?.showErrors ?? true;

  const [initialState] = useState<State>(() => {
    return {
      value,
      issue: validate(value, rules),
      initialValue: value,
      ...controlState,
    };
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const formattedValue = useUpperCase(isNil(state.value) ? '' : state.value, metadata);

  stateRef.current.changed = state.changed;
  stateRef.current.touched = state.touched;
  stateRef.current.modified = state.modified;

  const controlRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);

  const { timeout, startTimer } = useTimer(1000);
  const constraints = useRef({ type, rules, validationToken });

  const setValue = useCallback(
    (value: any) => dispatch({ type: 'CHANGE_VALUE', payload: value }),
    []
  );

  const setLoading = useCallback(
    (status: boolean) => dispatch({ type: 'LOADING', payload: status }),
    []
  );

  const reset = useCallback(() => dispatch({ type: 'RESET' }), []);

  useEffect(() => {
    if (!valueChanged.current && !stateRef.current.changed) {
      dispatch({ type: 'CHANGE_VALUE', payload: value });
    }
  }, [value]);

  // report the status to the form in the first rendering and
  // each time value or issue changes
  useEffect(() => {
    onChange({
      value: formattedValue,
      valid: isValidField(state.issue),
      issue: state.issue,
      setValue,
      setLoading,
      reset,
      controlState: stateRef.current,
    });
  }, [formattedValue, state.issue, onChange, setValue, setLoading, reset]);
  // run validation whenever:
  // the component has been mounted
  // the field has been blurred
  // the user stopped typing
  // the rules or type have changed
  useEffect(() => {
    if (
      firstRender ||
      type === 'readonly' ||
      (!state.active && state.touched) ||
      timeout ||
      rules !== constraints.current.rules ||
      type !== constraints.current.type ||
      validationToken !== constraints.current.validationToken
    ) {
      constraints.current.type = type;
      constraints.current.rules = rules;
      constraints.current.validationToken = validationToken;

      const ran = asyncValidate(
        name,
        state.value,
        rules,
        () => dispatch({ type: 'LOADING', payload: true }),
        issue => dispatch({ type: 'ASYNC_VALIDATE', payload: issue }),
        () => dispatch({ type: 'LOADING', payload: false })
      );

      if (!ran && !firstRender) {
        dispatch({ type: 'VALIDATE' });
      }
    }
  }, [
    asyncValidate,
    firstRender,
    name,
    rules,
    state.active,
    state.touched,
    state.value,
    timeout,
    type,
    validationToken,
  ]);

  useEffect(() => {
    if (constraints.current.rules?.async && !isNil(state.value) && state.changed) {
      startTimer();
    }
  }, [startTimer, state.changed, state.value]);

  useImperativeHandle(ref, () => ({
    focus: () => focusRef(errorRef),
  }));

  const update = useCallback(
    (value: { target: { value: any } }) =>
      dispatch({
        type: 'CHANGE_VALUE',
        payload: value && value.target ? value.target.value : value,
      }),
    []
  );

  const onFocus = useCallback(() => dispatch({ type: 'FOCUS' }), []);
  const onBlur = useCallback(() => dispatch({ type: 'BLUR' }), []);

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'CHANGE_VALUE':
        if (!isEqual(action.payload, state.value)) {
          valueChanged.current = true;
          let touched = action.payload !== state.initialValue;
          if (action.payload === undefined && state.initialValue === '') {
            touched = false;
          }
          return {
            ...state,
            value: action.payload,
            issue: validate(action.payload, rules),
            modified: state.modified || action.payload !== state.initialValue,
            touched,
            changed: true,
          };
        }

        return state;

      case 'VALIDATE':
        return {
          ...state,
          issue: validate(state.value, rules),
        };

      case 'BLUR':
        return {
          ...state,
          touched: state.touched || state.active,
          active: false,
        };

      case 'FOCUS':
        return { ...state, active: true };

      case 'ASYNC_VALIDATE':
        return { ...state, loading: false, issue: action.payload };

      case 'RESET':
        return initialState;

      case 'LOADING':
        return { ...state, loading: action.payload };

      default:
        return state;
    }
  }

  function computeStatus() {
    let badge = null;
    let status = 'plain';
    let errorMessage;

    if (showErrors) {
      const isClassicTheme = theme === 'classic';

      if (state.loading) {
        badge = (
          <div className={classes(styles.badge, styles.rotate)}>
            <SyncIcon />
          </div>
        );
      } else if (type) {
        status = type;
      }
      if (state.issue && type !== 'disabled' && form.editable) {
        if (
          state.issue.type !== 'pending' &&
          (display.error === 'always' ||
            (display.error === 'touched' && state.touched) ||
            state.issue.imperative)
        ) {
          status = state.issue.type;
          if (isClassicTheme) {
            errorMessage = state.issue.message;
          } else {
            badge = (
              <FloatMessage
                type={state.issue.type}
                text={state.issue.message}
                ref={errorRef}
              />
            );
          }
        }
      } else if (!isClassicTheme) {
        if (
          display.success === 'always' ||
          (display.success === 'modified' && state.modified && state.touched)
        ) {
          status = 'success';
        }
      }
    }
    return { status, badge, errorMessage };
  }

  const { status, badge, errorMessage } = computeStatus();

  const className = classes(
    styles.inputControl,
    styles[status],
    state.active ? styles.active : '',
    styles[theme]
  );

  const forwardProps = {
    ...rest,
    name,
    label,
    value: state.value,
    metadata,
    control: {
      type,
      theme,
      status,
      ref: controlRef,
      active: state.active,
      loading: state.loading,
    },
    inputProps: {
      ...inputProps,
      onFocus,
      onBlur,
      onChange: update,
      tabIndex: type === 'readonly' || type === 'disabled' ? -1 : 0,
    },
    ref: inputRef,
  };

  const alignLeft = inputProps.align && inputProps.align !== 'left';
  return (
    <div
      ref={controlRef}
      tabIndex={-1}
      className={className}
      onFocus={e => {
        if (
          inputRef.current &&
          controlRef.current?.contains(e.target) &&
          !inputRef.current.contains(e.target)
        ) {
          inputRef.current.focus();
        }
      }}
      onBlurCapture={e => {
        if (controlRef.current?.contains(e.relatedTarget as Node)) {
          e.stopPropagation();
        }
      }}
    >
      <div>
        {badge && alignLeft ? <div className={styles.badgeContainer}>{badge}</div> : null}
        {renderComponent(component, forwardProps)}
        {badge && !alignLeft ? (
          <div className={styles.badgeContainer}>{badge}</div>
        ) : null}
      </div>

      {theme === 'classic' ? (
        <div className={styles.errorMessage}>{errorMessage}</div>
      ) : null}
    </div>
  );
});

InputControl.displayName = 'InputControl';
export default InputControl;
