import React from 'react';

const SendEmailNotification = ({ size = 2.3 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_10539_3589"
      maskUnits="userSpaceOnUse"
      x="3"
      y="7"
      width="18"
      height="10"
    >
      <path
        d="M3.49252 9.11378L11.0825 16.7038C11.4725 17.0938 12.1025 17.0938 12.4925 16.7038L18.7925 10.4038V14.0038C18.7925 14.5538 19.2425 15.0038 19.7925 15.0038C20.3425 15.0038 20.7925 14.5538 20.7925 14.0038V8.00378C20.7925 7.45378 20.3425 7.00378 19.7925 7.00378H13.7925C13.2425 7.00378 12.7925 7.45378 12.7925 8.00378C12.7925 8.55378 13.2425 9.00378 13.7925 9.00378H17.3825L11.7925 14.5938L4.90252 7.70378C4.71569 7.51653 4.46204 7.4113 4.19752 7.4113C3.933 7.4113 3.67935 7.51653 3.49252 7.70378C3.11252 8.09378 3.11252 8.73378 3.49252 9.11378Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_10539_3589)">
      <rect width="24" height="24" fill="currentColor" />
    </g>
  </svg>
);

export default SendEmailNotification;
