import React, { useMemo } from 'react';

import { Data as PersonData } from './Person';

import * as updating from '.';
import { renderComponent } from 'utils/components';
import { GenericObject } from 'components/inputs/types';

type Data = PersonData & {
  matricula: string;
  curso: string;
  nivelCurso: string;
  instituicao: string;

  lattes: string;
  areas: string;
  arquivos: { nome: string; id: number }[];
};

type Body = {
  diffs: string[];
  old: Data;
  new: Data;
  perfil: 'pesquisador' | 'aluno' | 'colaborador';
};

const Wrapper = ({ data }: { data: Body; diff: GenericObject }) => {
  const { perfil, diffs, old } = data;

  const profiles: { [key: string]: React.ElementType<any> | undefined } = {
    pesquisador: updating.Researcher,
    aluno: updating.Student,
    colaborador: updating.Contributor,
  };

  const diff: GenericObject = useMemo(() => {
    return diffs.reduce((a, v) => ({ ...a, [v]: Object.assign(old)[v] ?? null }), {});
  }, [diffs, old]);

  return renderComponent(profiles[perfil], {
    data: data.new,
    diff,
  });
};

export default Wrapper;
