const CheckCircle = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_13347_24406"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="13"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 1.1665C4.05 1.1665 1.25 3.77984 1.25 6.99984C1.25 10.2198 4.05 12.8332 7.5 12.8332C10.95 12.8332 13.75 10.2198 13.75 6.99984C13.75 3.77984 10.95 1.1665 7.5 1.1665ZM7.5 11.6665C4.74375 11.6665 2.5 9.57234 2.5 6.99984C2.5 4.42734 4.74375 2.33317 7.5 2.33317C10.2563 2.33317 12.5 4.42734 12.5 6.99984C12.5 9.57234 10.2563 11.6665 7.5 11.6665ZM6.25 8.26567L9.925 4.83567C10.1687 4.60817 10.5687 4.60817 10.8125 4.83567C11.0562 5.06317 11.0562 5.43067 10.8125 5.65817L6.69375 9.50234C6.45 9.72984 6.05625 9.72984 5.8125 9.50234L4.19375 7.9915C3.95 7.764 3.95 7.3965 4.19375 7.169C4.31052 7.05977 4.46905 6.99839 4.63437 6.99839C4.7997 6.99839 4.95823 7.05977 5.075 7.169L6.25 8.26567Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_13347_24406)">
        <rect width="15" height="14" fill="#333333" />
      </g>
    </svg>
  );
};

export default CheckCircle;
