import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { Redirect, Mail } from 'components/icons';
import { ErrorDialog, TableList } from 'components/containers';
import { ColumnConfig } from 'components/containers/TableList/types';
import { Instance } from 'components/containers/TableList/TableList';
import { PersonSearchIcon } from 'consts/icons';
import { useAuthState } from 'store/auth';
import { Profile } from 'pages/register/profile';
import { useRequest } from 'apis';
import { Loader } from 'components/elements';

type Item = {
  id: number;
  rownum: number;
  nome: string;
  email: string;
  lattes: string;
  titulacao: string;
  area_formacao: string | undefined;
  area_pesquisa: string;
  titulacao_id: number;
  categoria: string;
};

const categories: { [key in any]: string } = {
  EST: 'Estudante',
  PES: 'Pesquisador',
  COL: 'Colaborador',
};

const titrations: { [key in any]: string } = {
  PEQA: 'Doutor',
  ED: 'Doutorando',
  PEQC: 'Especialista',
  EE: 'Estudante de especialização',
  EFIC: 'Estudante FIC',
  ET: 'Estudante Técnico',
  AT: 'Aluno Técnico',
  AE: 'Aluno Especialização',
  PEQD: 'Graduado',
  EG: 'Graduando',
  EM: 'Mestrando',
  PEQB: 'Mestre',
  PEQE: 'Técnico',
};

type areasPesquisasData = {
  content: areasPesquisasContent[];
};

type areasPesquisasContent = {
  nome: string;
};

const PeopleListView = () => {
  const { tx } = useTranslationX('list', 'people');

  const history = useHistory();
  const location = useLocation();
  const { profile } = useAuthState();

  const table = useRef<Instance>();
  const [error, setError] = useState<string>();
  const [areasPesquisa, setAreasPesquisa] = useState<string[]>();

  const request = useRequest();

  const config: ColumnConfig = [
    {
      title: tx('name'),
      field: 'nome',
      weight: 0.5,
      type: 'html',
      align: 'center',
      search: true,
      parse: value => `<strong>${value}</strong>`,
    },

    {
      title: tx('category'),
      field: 'categoria',
      weight: 0.6,
      search: true,
      align: 'center',
      metadata: {
        options: Object.keys(categories).map(status => {
          return {
            value: categories[status],
            text: tx(`categoryOptions.${status}`),
          };
        }),
      },
      searchType: 'status',
      parse: (status, data: Item) => {
        const newStatus = status.slice(0, 3).toUpperCase();
        return {
          value: tx(`categoryOptions.${newStatus}`),
        };
      },
    },

    {
      title: tx('formation_area'),
      field: 'area_formacao',
      weight: 0.51,
      align: 'center',
      search: true,
      parse: area_formacao => {
        return area_formacao === null ? tx('fields.none_verbose_alt') : area_formacao;
      },
    },
    {
      title: tx('titration'),
      field: 'titulacao',
      weight: 0.6,
      align: 'center',
      search: true,
      searchType: 'status',
      metadata: {
        options: Object.keys(titrations).map(status => {
          return {
            value: titrations[status],
            text: tx(`titrationOptions.${status}`),
          };
        }),
      },
      parse: (titulacao, data) => {
        const newStatus = Object.keys(titrations).filter(
          value => titrations[`${value}`] === titulacao
        );
        return {
          value: tx(`titrationOptions.${newStatus}`),
        };
      },
    },
    {
      title: tx('research_area'),
      field: 'area_pesquisa',
      weight: 0.8,
      align: 'center',
      search: true,
      searchType: 'status',
      metadata: {
        options: areasPesquisa?.map(areaPesquisa => {
          return {
            value: areaPesquisa,
            text: areaPesquisa,
          };
        }),
      },
      parse: area_pesquisa => {
        return area_pesquisa === null
          ? tx('fields.none_verbose')
          : area_pesquisa.length >= 50
          ? `${area_pesquisa.substring(0, 50)}...`
          : area_pesquisa;
      },
    },
    {
      title: tx('lattes'),
      field: 'lattes',
      type: 'icon',
      weight: 0.3,
      sort: false,
      align: 'center',

      parse: lattes => {
        return {
          onClick: () => {
            if (lattes !== null) {
              window.open(lattes, '_blank');
            } else {
              setError(tx('error.none_lattes'));
            }
          },
          icon: Redirect,
          rate: 0.5,
        };
      },
    },
    {
      title: tx('email'),
      field: 'email',
      type: 'icon',
      weight: 0.35,
      align: 'center',
      sort: false,
      parse: email => {
        return {
          onClick: () => {
            if (email !== null) {
              window.open(`mailto:${email}`, '_blank');
            } else {
              setError(tx('error.none_email'));
            }
          },
          icon: Mail,
          rate: 0.5,
        };
      },
    },
  ];

  const editAction = ({ id, ...rest }: Item) => {
    if (id !== undefined) {
      history.push(`${location.pathname}/${id}`, { ...rest });
    }
  };

  const getAreasPesquisas = useCallback(() => {
    const filter = request<areasPesquisasData>({
      url: 'pessoa/areas?sort=nome,asc',
      method: 'POST',
      onSuccess: data => {
        const areasPesquisa = data.content.map((content, index) => content.nome);

        setAreasPesquisa(areasPesquisa);
      },
    });

    return () => filter.cancel();
  }, [request]);

  useEffect(() => {
    getAreasPesquisas();
  }, [getAreasPesquisas]);

  if (!areasPesquisa) {
    return <Loader size={60} />;
  }

  return (
    <>
      {error && (
        <ErrorDialog
          message={tx('actions.error')}
          title={error}
          visible={!!error}
          onClose={() => setError(undefined)}
        />
      )}
      <TableList<Item>
        baseUrl="/pessoas"
        listHeader={{
          title: tx('title'),
          subtitle: tx('sub_title'),
        }}
        style={{
          root: {
            maxWidth: 'calc(100% - 20px)',
            maxHeight: 'calc(100vh - 150px)',
          },
        }}
        textSize="small"
        theme="light"
        config={config}
        reference={table}
        editAction={profile === Profile.SUPERVISOR ? editAction : undefined}
        keyComposer={(item, index) => `${item.id}-${index}`}
        editIcon={null}
        refreshesOn={95}
        pageSize={20}
        notFoundProps={{
          icon: PersonSearchIcon,
          title: tx('error.not_found.title'),
          description: tx('error.not_found.description'),
        }}
        filter
        infinityScroll
      />
    </>
  );
};

export default PeopleListView;
