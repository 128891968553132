import React from 'react';
import _ from 'lodash';
import { usePaymentContext } from '../../PaymentContext';
import { Payment, PaymentContract, paymentStatusColors } from '../../types';
import { CheckBox as CheckIcon, CheckBoxFrame as UncheckIcon } from 'components/icons';
import { classes } from 'utils/components';
import { useAuthState } from 'store/auth';
import { Profile } from 'pages/register/profile';

import styles from './PaymentNode.module.scss';

type Props = {
  payment: Payment;
  contract: PaymentContract;
  date?: Date;
};

const PaymentNode: React.FC<Props> = ({ payment, contract, date }) => {
  const { id, estado } = payment;
  const { payments, addPayment, removePayment, vinculos, updateVinculos } =
    usePaymentContext();
  const { profile } = useAuthState();

  const isResponsible = profile !== Profile.SUPERVISOR;

  const isSelected = _.some(payments, { id: id });

  const getCheckBox = () => {
    if (isSelected) {
      return <CheckIcon size={1.2} />;
    } else {
      return <UncheckIcon size={1.2} />;
    }
  };

  const togglePayment = () => {
    if (isSelected) {
      removePayment(payment.id);
      updateVinculos('');
    } else {
      if (vinculos.length === 0) {
        addPayment(payment);
        updateVinculos(contract.vinculo);
      }

      if (vinculos.length > 0 && vinculos === contract.vinculo) {
        addPayment(payment);
        updateVinculos(contract.vinculo);
      }
    }
  };

  const isClickable = (() => {
    if (
      ['LAT', 'PLA'].includes(estado) &&
      ['CON', 'TER', 'TRA', 'FIN', 'INA'].includes(contract.estado)
    ) {
      if (payment.alteracao_pt_block) return false;
      if (contract.vinculo === 'CELETISTA' && isResponsible) return false;
      if (payments.length > 0 && vinculos.length > 0 && vinculos !== contract.vinculo) {
        return false;
      }
      if (estado === 'PLA' && date) {
        // Check if date is in the future
        const today = new Date();
        if (date > today) {
          return false;
        }
        // Check if today is before the payment 15th
        const paymentDate = new Date(date);
        paymentDate.setDate(15);
        if (today < paymentDate && today.getMonth() !== 11) {
          return false;
        }
      }
      if (payments.length === 0) {
        return true;
      } else {
        const firstPayment = payments[0];
        return firstPayment.mes === payment.mes;
      }
    }
  })();

  return (
    <div
      className={classes(styles.paymentNode, isClickable ? styles.clickable : '')}
      style={{
        backgroundColor: paymentStatusColors[estado],
      }}
      onClick={isClickable ? togglePayment : undefined}
    >
      <div className={classes(styles.checkbox, isSelected ? styles.selected : '')}>
        {getCheckBox()}
      </div>
    </div>
  );
};

export default PaymentNode;
