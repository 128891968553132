import React, { useState } from 'react';

import { useTranslation, useTranslationX } from 'i18n';
import { Grid, Modal, ModalPDFViewer, Row } from 'components/containers';
import { Button, PlainField, Spacer } from 'components/elements';
import { getMasked } from 'utils/inputMask';
import { Eye } from 'components/icons';
import { useRequest } from 'apis';

type Data = {
  mes: number;
  ano: number;
  nome: string;
  regime: number;
  mesInicio: number;
  mesFim: number;
  estado: string;
  chMes: number;
  rg: string;
  atividades: string;
  projetoTitulo: string;
  projetoSigla: string;
  projetoCoordenador: string;
  remuneracaoBruta: number;
  tipo: string;
  rgEmissor: string;
  rgEmissorUf: string;
  arquivo?: number;
  valorHora: number;
};

const Counterpart = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('request', 'project');
  const { t } = useTranslation();
  const {
    mes,
    valorHora,
    ano,
    nome,
    mesInicio,
    regime,
    mesFim,
    chMes,
    atividades,
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    remuneracaoBruta,
    tipo,
    arquivo,
  } = data;

  const request = useRequest();
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const getArchive = (id: number) => {
    request<string>({
      url: `/arquivo/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'string',
      },
      onSuccess: handleDownload,
    });
  };

  const handleDownload = (token: string) => {
    request<any>({
      url: `/resource/${token}`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <Grid spacing={0}>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.itemName')} value={nome} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
      </Row>
      <Row width={[3, 3, 3]} align="top">
        <PlainField
          label={tx('form.mes')}
          value={t(`components.month_year_picker.months.${mes}`)}
        />
        <PlainField label={tx('form.ano')} value={String(ano)} />
      </Row>
      <Row width={[3, 3, 3]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[3, 3, 3]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
      </Row>
      <Row width={[3, 3, 3]} align="top">
        <PlainField label={tx('form.name.label')} value={nome} />
        <PlainField label={tx('form.mesInicio')} value={String(mesInicio)} />
        <PlainField label={tx('form.mesFinal')} value={String(mesFim)} />
      </Row>

      <Spacer length={20} />
      <Row width={[3, 3, 3]} align="top">
        <PlainField label={tx('form.regime')} value={`${regime} ${tx('form.hours')}`} />
        <PlainField
          label={tx('form.monthlyWorkload')}
          value={`${chMes} ${tx('form.hourspermonth')}`}
        />
      </Row>
      <Row align="top">
        <PlainField label={tx('form.atividades')} value={atividades} />
      </Row>
      <Spacer length={20} />
      <Row width={[3, 3, 3]} align="top"></Row>
      <Spacer length={20} />
      <Row width={[3, 3, 3]} align="top">
        <PlainField
          label={tx('form.remuneration')}
          value={`${t('currency')} ${getMasked(remuneracaoBruta.toFixed(2), {
            pattern: 'currency',
          })}`}
        />
        <PlainField
          label={tx('form.valueHour')}
          value={`${t('currency')} ${getMasked(valorHora.toFixed(2), {
            pattern: 'currency',
          })}`}
        />
      </Row>
      {arquivo && (
        <Row width={[3, 3]} align="top">
          <Button theme="light" icon={Eye} onClick={() => getArchive(arquivo)}>
            {tx('form.declaration')}
          </Button>
        </Row>
      )}
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div>
          <ModalPDFViewer
            pdfData={pdfData}
            title={tx('form.monthDeclaration')}
            onClose={handleClose}
          />
        </div>
      </Modal>
    </Grid>
  );
};

export default Counterpart;
