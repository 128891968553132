import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { useTranslationX } from 'i18n';
import { useForm } from 'components/form';
import { ScrollPanel, Clip } from 'components/containers';
import { Action, GridConfig, Milestone, State } from './types';
import { STEP_W, MARGIN, INNER_MARGIN } from './consts';
import EditDialog from './EditDialog';
import Grid from './Grid';
import { Button } from 'components/elements';
import Container from './Milestone';
import PlaceHolder from './PlaceHolder';
import AlertMessage from 'components/elements/AlertMessage/AlertMessage';

import styles from './Schedule.module.scss';
import { FormInstance } from 'components/form/types';

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_MODAL':
      return { ...state, modal: action.payload || undefined };

    case 'SET_OPERATION':
      return { ...state, operation: action.payload || undefined };

    case 'UPDATE_MARKER': {
      if (!state.config) {
        return state;
      }

      const markers: { id: number; pos: number }[] = [];
      const { oldValue, newValue } = action.payload;

      for (const key in state.config) {
        const value = parseInt(key);
        markers.push({
          id: state.config[key].id,
          pos: value === oldValue ? newValue : value,
        });
      }

      return { steps: state.steps, config: updateMarkers(markers, state.steps!) };
    }

    case 'UPDATE_ACTIVITY': {
      return { ...state, transit: action.payload };
    }
    case 'REFRESH': {
      const { milestones, steps } = action.payload;
      return initialize({ milestones, steps });
    }
    default:
      return state;
  }
};

const Schedule = ({
  editable,
  setCurrTab,
  removeFinal,
  instance,
}: {
  editable: boolean;
  setCurrTab?: React.Dispatch<React.SetStateAction<number>>;
  removeFinal?: boolean;
  instance?: FormInstance;
}) => {
  const form = useForm();

  const milestones = sanitizeMilestones(form.getFieldValue('macroentregas'));

  const steps = form.getFieldValue('duracao');
  const completion = form.getFieldValue('finalizacao');

  const conclusion = form.getFieldValue('conclusao') as number | undefined;

  const [state, dispatch] = useReducer(reducer, { milestones, steps }, initialize);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>();

  const active = !!state.operation || !!state.modal;
  const { tx } = useTranslationX('schedule', 'proposal');

  useEffect(() => {
    if (instance) {
      const callback = () => {
        const milestones = sanitizeMilestones(form.getFieldValue('macroentregas'));

        const steps = form.getFieldValue('duracao');
        dispatch({ type: 'REFRESH', payload: { milestones, steps } });
      };
      instance.observer.subscribe(callback, { onSave: true });
      return () => instance.observer.unsubscribe(callback);
    }
  }, [instance, form]);

  const onClipChange = useCallback(
    (clip: Clip) => {
      if (contentRef) {
        const rect = `rect(${clip.top}px, ${clip.right}px, ${clip.bottom - 15}px, ${
          clip.left
        }px)`;

        contentRef.style.clipPath = rect;
      }
    },
    [contentRef]
  );

  const renderContainers = () => {
    const data = [];
    let index = 0;

    for (const key in state.config) {
      const { id, size, offset } = state.config[parseInt(key)];

      data.push(
        <Container
          key={id}
          index={index++}
          size={size}
          offset={offset}
          active={active}
          dispatch={dispatch}
          transit={state.transit}
          editable={editable}
        />
      );
    }

    return data;
  };

  return (
    <div className={styles.schedule} data-op={state.operation}>
      {state.config ? (
        <ScrollPanel onClipChange={onClipChange}>
          <Grid
            steps={steps}
            config={state.config}
            dispatch={dispatch}
            active={active}
            reference={contentRef}
            completion={removeFinal ? 0 : completion}
            percentage={conclusion}
            editable={editable}
            inicioRelativo={state.inicioRelativo}
          />
          <div
            className={styles.containers}
            ref={setContentRef}
            style={{
              width: (steps + (removeFinal ? 0 : completion)) * STEP_W + MARGIN * 2,
            }}
          >
            {renderContainers()}

            {removeFinal ? (
              <></>
            ) : (
              <div className={styles.completion}>
                <div
                  style={{
                    width: completion * STEP_W - INNER_MARGIN * 2,
                    marginLeft: steps * STEP_W + MARGIN + INNER_MARGIN,
                  }}
                >
                  <PlaceHolder title={tx('completion')} />
                </div>
              </div>
            )}
          </div>
        </ScrollPanel>
      ) : (
        <div className={styles.invalid}>
          <AlertMessage title={tx('titleAlertMessage')} message={tx('alertMessage')}>
            <Button onClick={() => setCurrTab && setCurrTab(1)}>{tx('alertBtn')}</Button>
          </AlertMessage>
        </div>
      )}

      {state.modal ? <EditDialog config={state.modal} dispatch={dispatch} /> : null}
    </div>
  );
};

export default Schedule;

function sanitizeMilestones(milestones: any) {
  if (milestones.length > 0) {
    milestones.map((milestone: { duracao: any }) => {
      milestone.duracao = Number(milestone.duracao | 0);
      return milestone;
    });
  }
  return milestones;
}

function initialize(data: { milestones?: Milestone[]; steps?: number }): State {
  const { milestones, steps } = data;

  if (milestones && validate(milestones, steps)) {
    const inicioRelativo = milestones[0].inicio;

    const markers: { id: number; pos: number }[] = [];
    let pos = 0;

    for (const item of milestones) {
      pos += item.duracao;
      markers.push({ id: item.id, pos });
    }

    return { steps, config: updateMarkers(markers, steps!), inicioRelativo };
  }

  return {};
}

function updateMarkers(
  markers: { id: number; pos: number }[],
  steps: number
): GridConfig {
  let prev = markers[0];
  let curr = prev;

  const config = {
    [curr.pos]: { id: curr.id, min: 1, max: steps, size: curr.pos, offset: 0 },
  };

  for (let i = 1; i < markers.length; ++i) {
    curr = markers[i];
    config[prev.pos].max = curr.pos - 1;

    config[curr.pos] = {
      id: curr.id,
      min: prev.pos + 1,
      max: steps,
      size: curr.pos - prev.pos,
      offset: prev.pos,
    };

    prev = curr;
  }

  return config;
}

function validate(milestones?: Milestone[], steps?: number): boolean {
  if (!steps || !Array.isArray(milestones) || !milestones.length) {
    return false;
  }

  let sum = 0;

  for (const milestone of milestones) {
    sum += milestone.duracao;

    if (sum > steps) {
      return false;
    }
  }

  return sum === steps;
}
