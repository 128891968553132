import React, { useMemo, useRef, useState } from 'react';
import calendar, {
  getNextMonth,
  getPreviousMonth,
  isSameDay,
  isSameMonth,
  yearsDropdownList,
} from './helpers';

import { AngleDown } from 'components/icons';
import styles from './DatePickerComponent.module.scss';
import { classes } from 'utils/components';
import { call } from 'utils';
import { useTranslationX } from 'i18n';
import { isNotNull } from 'utils/object';
import Dropdown from 'components/inputs/Select/Dropdown';

export type CalendarProps = {
  month: number;
  year: number;
};

export type DatePickerData = {
  current: Date[];
  calendar: CalendarProps[];
};

type Props = {
  onChange?: (date: DatePickerData) => void;
  onClose?: () => void;
  value?: Date[];
  fitWidth?: boolean;
  tippyProps?: object;
  range?: boolean;
  attachDropdownRef?: (el: HTMLDivElement) => void;
};

const DatePickerComponent: React.FC<Props> = props => {
  const { value, tippyProps, range, onChange, onClose, attachDropdownRef } = props;

  const memoizedValue = useMemo(() => {
    let current: Date[] = [];
    let calendar: CalendarProps[] = [];
    if (value) {
      if (value.length === 2) {
        current = [value[0], value[1]];
      } else {
        current = [value[0], value[0]];
      }
      calendar = [
        {
          month: current[0].getMonth() + 1,
          year: current[0].getFullYear(),
        },
        {
          month: current[1].getMonth() + 1,
          year: current[1].getFullYear(),
        },
      ];
    } else {
      const _date = new Date();
      const isNextYear = _date.getMonth() + 2 > 12;

      calendar = [
        {
          month: _date.getMonth() + 1,
          year: _date.getFullYear(),
        },
        {
          month: isNextYear ? 1 : Math.min(_date.getMonth() + 2, 12),
          year: isNextYear ? _date.getFullYear() + 1 : _date.getFullYear(),
        },
      ];
    }
    return {
      current,
      calendar,
    };
  }, [value]);

  const [date, setDate] = useState<DatePickerData>(memoizedValue);

  const [expanded, setExpanded] = useState([false, false]);

  const { tx } = useTranslationX('components', 'translation');
  const yearRef = useRef<(HTMLDivElement | null)[]>([]);

  const optionRef = useRef<HTMLDivElement>(null);

  const toggleYearsDropdown = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !expanded[index];
    setExpanded(newExpanded);
  };

  const getCalendarDates = (calendarIndex: number) => {
    const { current, calendar: calendarProps } = date;
    const { month, year } = calendarProps[calendarIndex];
    const targetCurrent = current[calendarIndex];

    const calendarMonth =
      month || (current[calendarIndex] && +targetCurrent.getMonth() + 1);
    const calendarYear =
      year || (current[calendarIndex] && +targetCurrent.getMonth() + 1);
    return calendar(calendarMonth, calendarYear);
  };
  const addDateToState = (calendarIndex: number) => (newDate: Date) => {
    const { calendar, current } = date;
    const _date = newDate || new Date();
    const newCurrent = [...current];
    const newCalendar = [...calendar];
    newCurrent[calendarIndex] = _date;
    newCalendar[calendarIndex] = {
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
    };
    setDate({
      current: newCurrent,
      calendar: newCalendar,
    });
  };

  const handleDate =
    (calendarIndex: number) => (newDate: Date) => (evt: React.MouseEvent) => {
      evt && evt.preventDefault();
      const { current } = date;
      !(current[calendarIndex] && isSameDay(newDate, current[calendarIndex])) &&
        addDateToState(calendarIndex)(newDate);
    };

  const handleMonthChange = (calendarIndex: number) => (direction: 'next' | 'prev') => {
    const { calendar } = date;
    const { month, year } = calendar[calendarIndex];
    const newMonth =
      direction === 'next' ? getNextMonth(month, year) : getPreviousMonth(month, year);

    const newCalendar = [...calendar];
    newCalendar[calendarIndex] = {
      month: newMonth.month,
      year: newMonth.year,
    };
    setDate({
      ...date,
      calendar: newCalendar,
    });
  };

  const handleSetYear = (calendarIndex: number) => (newYear: number) => {
    const { calendar } = date;
    const { month } = calendar[calendarIndex];
    const newCalendar = [...calendar];
    newCalendar[calendarIndex] = {
      month,
      year: newYear,
    };
    date.current[calendarIndex]?.setFullYear(newYear);
    setDate({
      ...date,
      calendar: newCalendar,
    });
  };

  const handleYearChange = (calendarIndex: number) => (direction: 'next' | 'prev') => {
    const { calendar } = date;
    const { year } = calendar[calendarIndex];
    const newYear = direction === 'next' ? year + 1 : year - 1;
    handleSetYear(calendarIndex)(newYear);
  };

  function collapse(calendarIndex: number) {
    const ref = yearRef.current[calendarIndex];
    ref?.focus();
    toggleYearsDropdown(calendarIndex);
  }

  const renderYearDropdown = (calendarIndex: number) => {
    const ref = yearRef.current[calendarIndex];
    const selected = date.calendar[calendarIndex].year;
    return (
      ref && (
        <Dropdown
          attachRef={attachDropdownRef}
          anchor={ref}
          visible={expanded[calendarIndex]}
          options={yearsDropdownList().map(year => ({
            text: year,
            value: year,
          }))}
          renderOption={({ text, value }) => (
            <div
              ref={value === selected ? optionRef : null}
              className={classes(
                styles.option,
                value === selected ? styles.selected : ''
              )}
              onClick={() => {
                collapse(calendarIndex);
                handleSetYear(calendarIndex)(Number(value));
              }}
              tabIndex={0}
            >
              {text}
            </div>
          )}
          onBlur={() => {
            toggleYearsDropdown(calendarIndex);
          }}
          fitWidth
        />
      )
    );
  };

  const renderMonthAndYear = (calendarIndex: number) => {
    const { calendar } = date;
    const { month, year } = calendar[calendarIndex];

    const isOpen = expanded[calendarIndex];

    const monthname = tx(
      `date_picker.months.${Math.max(0, Math.min(month - 1, 11)) + 1}`
    );
    return (
      <div className={styles.header}>
        <div
          className={classes(styles.arrow, styles.left)}
          onClick={handleAction('prev', calendarIndex)}
        >
          <AngleDown size={0.7} />
        </div>
        <div className={styles.dateHeader}>
          <div className={styles.month}>{monthname}</div>
          <div
            ref={el => {
              yearRef.current[calendarIndex] = el;
            }}
          >
            <button
              className={classes(styles.year, isOpen && styles.open)}
              onClick={() => toggleYearsDropdown(calendarIndex)}
            >
              {year}
              <AngleDown />
            </button>

            {renderYearDropdown(calendarIndex)}
          </div>
        </div>
        <div
          className={classes(styles.arrow, styles.right)}
          onClick={handleAction('next', calendarIndex)}
        >
          <AngleDown size={0.7} />
        </div>
      </div>
    );
  };

  const renderDayLabel = (_: undefined, index: number) => {
    const daylabel = tx(`date_picker.week_days.${index + 1}`)
      .substring(0, 3)
      .toUpperCase();
    return (
      <div
        className={classes(styles.cell, styles.day)}
        style={{ ['--cell-column' as any]: (index % 7) + 1 }}
        key={daylabel}
      >
        {daylabel}
      </div>
    );
  };

  const renderCalendarDate =
    (calendarIndex: number) => (dateArray: (string | number)[], index: number) => {
      const { calendar, current } = date;
      const { month, year } = calendar[calendarIndex];
      const _date = new Date(dateArray.join('-'));

      const isToday = isSameDay(_date, new Date());
      const isCurrent = current[calendarIndex]
        ? isSameDay(_date, current[calendarIndex])
        : false;

      const inMonth =
        month && year && isSameMonth(_date, new Date([year, month, 1].join('-')));
      const onClick = handleDate(calendarIndex)(_date);
      return (
        <div
          key={index}
          onClick={onClick}
          className={classes(
            styles.cell,
            styles.date,
            inMonth && styles.inMonth,
            isToday && styles.today,
            isCurrent && styles.highlighted
          )}
          style={{ ['--cell-row' as any]: Math.floor(index / 7) + 2 }}
        >
          {_date.getDate()}
        </div>
      );
    };

  const handleAction =
    (direction: 'next' | 'prev', calendarIndex: number) => (evt: React.MouseEvent) => {
      evt && evt.preventDefault();
      const fn = evt.shiftKey
        ? handleYearChange(calendarIndex)
        : handleMonthChange(calendarIndex);
      call(fn, direction);
    };

  const renderCalendars = (amount = 1) => {
    return (
      <div className={styles.calendars}>
        {Array.from({ length: amount }, (_, calendarIndex) => (
          <div
            key={calendarIndex}
            style={{
              marginLeft: calendarIndex === 0 ? '0' : '60px',
            }}
          >
            <h3 className={styles.title}>
              {tx(
                `date_picker.title.${
                  range ? (calendarIndex === 0 ? `start` : `end`) : 'single'
                }`
              )}
            </h3>
            <div className={styles.container} {...tippyProps}>
              {renderMonthAndYear(calendarIndex)}
              <div className={styles.grid}>
                {[...new Array(7)].map(renderDayLabel)}
                {getCalendarDates(calendarIndex).map(renderCalendarDate(calendarIndex))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const targetLength = range ? 2 : 1;
  const isValid = date.current.filter(isNotNull).length >= targetLength;

  return (
    <div className={styles.wrapper}>
      {renderCalendars(range ? 2 : 1)}
      <hr />
      <div className={styles.actions}>
        <button
          className={classes(styles.button, !isValid && styles.disabled)}
          onClick={() => {
            if (isValid) {
              onChange && onChange(date);
              onClose && onClose();
            }
          }}
        >
          {tx('date_picker.select')}
        </button>
      </div>
    </div>
  );
};

export default DatePickerComponent;
