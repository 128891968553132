import React from 'react';
import { useHistory } from 'react-router';
import { useTranslationX } from 'i18n';

import { ItemCard, Row } from 'components/containers';
import { KeyValueRow, Tag } from 'components/elements';
import { capitalize } from 'utils/stringUtils';
import { ProjectStatus, projectStatusColors } from '../types';

import styles from './RecentProjectList.module.scss';

type Props = {
  project: ProjectLatestItem;
};

export type ProjectLatestItem = {
  id: number;
  sigla: string;
  coordinator: string;
  titulo: string;
  areas: string;
  maturidade: string;
  status: ProjectStatus;
  numero: string;
};

const ProjectLatestCard: React.FC<Props> = ({ project }) => {
  const history = useHistory();
  const { tx, t } = useTranslationX('dashboard', 'project');

  return (
    <ItemCard
      width="250px"
      title={`Projeto ${project.sigla}`}
      key={'prj-' + project.id}
      onClick={() => history.push(`/dashboard/projetos/${project.id}`, { ...project })}
    >
      <KeyValueRow
        label={tx('recents.coordinator')}
        value={capitalize(project.coordinator)}
      />
      <Row>
        <KeyValueRow label={tx('recents.sigla')} value={capitalize(project.sigla)} />
        <KeyValueRow label={tx('recents.number')} value={project.numero} />
      </Row>

      <div className={styles.statusTag}>
        <Tag
          text={t(`status.${project.status}`)}
          color={projectStatusColors[project.status]}
        />
      </div>
    </ItemCard>
  );
};

export default ProjectLatestCard;
