import React from 'react';

import { useTranslation, useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { FileList, PlainField } from 'components/elements';
import { GenericObject } from 'components/inputs/types';
import Person, { Data as PersonData } from './Person';

type Data = PersonData & {
  siape: string;
  campus: string;
  titulacao: string;
  areaFormacao?: string;
  lattes: string;
  areas: string;
  arquivos: { nome: string; id: number }[];
};

const Researcher = ({ data, diff }: { data: Data; diff: GenericObject }) => {
  const { tx } = useTranslationX('homologacao', 'supervisor');
  const { t } = useTranslation('register');

  const { arquivos, campus, lattes, siape, titulacao, areaFormacao, areas } = data;

  return (
    <div>
      <FileList label={tx('docs')} values={arquivos} />
      <Person data={data} diff={diff} />

      <Grid spacing={0}>
        <Row width={[27, 73]} align="top">
          <PlainField
            label={t('researcher.campus')}
            value={campus}
            overlay={diff['campus']}
          />
          <PlainField
            label={t('academic.resume')}
            value={lattes}
            type="url"
            overlay={diff['lattes']}
          />
        </Row>

        <Row width={[27, 27, 46]} align="top">
          <PlainField label={t('researcher.id')} value={siape} overlay={diff['siape']} />
          <PlainField
            label={t('researcher.level')}
            value={titulacao}
            overlay={diff['titulacao']}
          />
          <PlainField
            label={t('academic.major')}
            value={areaFormacao || t('na')}
            overlay={diff['areaFormacao']}
          />
        </Row>

        <PlainField label={t('academic.areas')} value={areas} overlay={diff['areas']} />
      </Grid>
    </div>
  );
};

export default Researcher;
