import React, { ReactNode } from 'react';
import { classes } from 'utils/components';

import styles from './ItemCard.module.scss';

type Props = {
  title?: string | React.ReactNode;
  width?: string;
  padding?: string;
  leftChildren?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  children?: ReactNode;
  large?: boolean;
};

const ItemCard: React.FC<Props> = props => {
  return (
    <div
      className={classes(
        styles.card,
        props.onClick !== undefined ? styles.clickable : undefined,
        props.large ? styles.large : undefined
      )}
      style={{ width: props.width, padding: props.padding, ...props.style }}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.title !== undefined && (
        <div className={styles.header}>
          {props.title !== undefined && <strong>{props.title}</strong>}
          {props.leftChildren}
        </div>
      )}

      {props.children}
    </div>
  );
};

export default ItemCard;
