import React, { useCallback, useEffect, useReducer } from 'react';

import { useTranslationX } from 'i18n';
import { Modal, Row } from 'components/containers';
import { Button, ErrorMessage, IconButton, Loader } from 'components/elements';
import { useVisible } from 'components/hooks';
import { XAlt, XCircle } from 'components/icons';
import { readAttr } from 'utils/object';

import {
  Action as ScheduleAction,
  Activity,
  Milestone,
  ModalConfig,
  Transit,
} from './types';

import {
  ColorPicker,
  Counter,
  Form,
  SubmitButton,
  TextArea,
  TextField,
  useForm,
} from 'components/form';

import styles from './EditDialog.module.scss';

type Props = {
  config: ModalConfig;
  dispatch: (action: ScheduleAction) => void;
};

type DataCollect = { cor: string; duracao: number; titulo: string };

type Action =
  | { type: 'CREATE'; payload: DataCollect }
  | { type: 'DELETE'; payload: number }
  | { type: 'ERROR' };

type State = {
  status: 'init' | 'creating' | 'deleting' | 'error';
  collected?: DataCollect;
  index?: number;
  loading?: boolean;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CREATE':
      return { status: 'creating', collected: action.payload, loading: true };

    case 'DELETE':
      return { status: 'deleting', index: action.payload, loading: true };

    case 'ERROR':
      return { status: 'error' };

    default:
      return state;
  }
};

const EditDialog = ({ config, dispatch: parentDispatch }: Props) => {
  const [state, dispatch] = useReducer(reducer, { status: 'init' });
  const form = useForm();

  const { tx } = useTranslationX('schedule.activity.editor', 'proposal');
  const { visible, setVisible } = useVisible(true);

  const pathMacro = `macroentregas[${config.milestone}]`;
  const pathActivity = `${pathMacro}.atividades`;
  const macro: Milestone | undefined = form.getFieldValue(pathMacro);

  const activity =
    config.mode === 'edit'
      ? macro && macro.atividades && (macro.atividades[config.activity] as Activity)
      : undefined;

  const macroTitle = macro?.titulo;
  const macroSize = macro?.duracao || 1;

  const update = useCallback(
    (data: Activity, create?: boolean) => {
      const path = `${pathActivity}[${data.ordem}]`;

      form.setFieldValue(`${path}.id`, data.id, create);
      form.setFieldValue(`${path}.mesInicio`, data.mesInicio, create);
      form.setFieldValue(`${path}.mesFim`, data.mesFim, create);
      form.setFieldValue(`${path}.titulo`, data.titulo, create);
      form.setFieldValue(`${path}.cor`, data.cor, create);

      setVisible(false);
    },
    [form, pathActivity, setVisible]
  );

  useEffect(() => {
    if (state.status === 'creating') {
      if (!state.collected) {
        dispatch({ type: 'ERROR' });
        return;
      }

      const collection = readAttr(pathActivity, form.getCollection(pathActivity));
      const { cor, duracao, titulo } = state.collected;

      form.addListItem(
        pathActivity,
        collection?.length || 0,
        (success, res) => {
          if (success && res) {
            const { id, ordem } = res.response as { id: number; ordem: number };

            const payload: Transit = {
              type: 'add',
              data: { id, cor, titulo, mesInicio: 1, mesFim: duracao, ordem },
            };

            parentDispatch({ type: 'UPDATE_ACTIVITY', payload });
            update(payload.data, true);
          } else {
            dispatch({ type: 'ERROR' });
          }
        },
        { cor, titulo, mesInicio: 1, mesFim: Math.min(duracao, macroSize) }
      );
    } else if (state.status === 'deleting') {
      if (state.index !== undefined) {
        const payload: Transit = {
          type: 'remove',
          data: form.getFieldValue(`${pathActivity}[${state.index}]`) as Activity,
        };

        form.removeListItem(pathActivity, state.index, success => {
          if (success) {
            parentDispatch({ type: 'UPDATE_ACTIVITY', payload });
            setVisible(false);
          } else {
            dispatch({ type: 'ERROR' });
          }
        });
      }
    }
  }, [form, macroSize, parentDispatch, pathActivity, setVisible, state, update]);

  const renderContent = () => {
    switch (state.status) {
      case 'init': {
        const metadata = form.getFieldMetadata('atividades');

        return (
          <Form
            metadata={{ rules: metadata.rules }}
            onSubmit={({ requestBody }) => {
              if (config.mode === 'add') {
                dispatch({ type: 'CREATE', payload: requestBody as DataCollect });
              } else if (activity) {
                const { cor, titulo, duracao } = requestBody as DataCollect;
                const { mesInicio, id } = activity;

                const size = Number(duracao);
                const fim = Math.min(mesInicio + size - 1, macroSize);
                const inicio = Math.max(1, fim - size + 1);

                update({
                  id,
                  cor,
                  titulo,
                  mesFim: fim,
                  mesInicio: inicio,
                  ordem: config.activity,
                });
              } else {
                dispatch({ type: 'ERROR' });
              }
            }}
          >
            <ColorPicker name="cor" label={tx('color')} value={activity?.cor} />
            <span className={styles.title}>{macroTitle}</span>

            <TextArea
              name="titulo"
              label={tx('title')}
              minRows={2}
              maxRows={2}
              value={
                activity?.titulo ||
                tx('defaultTitle', { count: macro?.atividades?.length || 1 })
              }
              style={{ root: { marginBottom: 0 } }}
              forceActive
            />
            <Counter target="titulo" />

            <Row width={[43, 57]} space={0}>
              <TextField
                name="duracao"
                label={tx('duration')}
                adornment={{ right: tx('months') }}
                value={activity ? activity.mesFim - activity.mesInicio + 1 : 1}
                metadata={{
                  rules: {
                    required: true,
                    min: 1,
                    max: macroSize,
                    mask: { pattern: macroSize < 10 ? '0' : '90' },
                  },
                }}
              />
            </Row>

            <div className={styles.buttons}>
              <SubmitButton>{tx(config.mode)}</SubmitButton>

              {config.mode === 'add' ? (
                <Button type="secondary" onClick={() => setVisible(false)}>
                  {tx('cancel')}
                </Button>
              ) : (
                <div
                  className={styles.delete}
                  onClick={() => dispatch({ type: 'DELETE', payload: config.activity })}
                >
                  <div className={styles.icon}>
                    <XCircle />
                  </div>

                  <span>{tx('delete')}</span>
                </div>
              )}
            </div>
          </Form>
        );
      }

      case 'creating':
      case 'deleting':
        return <Loader />;

      case 'error':
        return <ErrorMessage />;

      default:
        return null;
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={() => parentDispatch({ type: 'SET_MODAL', payload: null })}
      onBlur={() => (state.loading ? null : setVisible(false))}
    >
      <div className={styles.editor}>
        <div className={styles.closeButton}>
          {config.mode === 'edit' && <span>{activity?.titulo}</span>}
          {config.mode === 'add' && <span>{tx('add')}</span>}

          <IconButton
            icon={XAlt}
            onClick={() => setVisible(false)}
            type="dark"
            size={20}
          />
        </div>
        {renderContent()}
      </div>
    </Modal>
  );
};

export default EditDialog;
