import React, { ReactNode, useCallback, useState } from 'react';
import _ from 'lodash';
import { Payment, PaymentContracts } from '../types';
import { PaymentContext } from './PaymentContext';

type Props = {
  contracts: PaymentContracts;
  reload: () => void;
  children?: ReactNode;
};

const PaymentContextProvider: React.FC<Props> = ({ children, contracts, reload }) => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [currentMonthPayments, setCurrentMonthPayments] = useState<Payment[]>([]);

  const [vinculos, setVinculos] = useState<string>('');

  const addPayment = (payment: Payment) => {
    setPayments([...payments, payment]);
  };

  const defineCurrentMonthPayments = useCallback((payments: Payment[]) => {
    setCurrentMonthPayments(payments);
  }, []);

  const removePayment = (id: string | number) => {
    setPayments(_.reject(payments, { id }));
  };

  const updateVinculos = (value: string) => {
    setVinculos(value);
  };

  const clearPayments = () => {
    setPayments([]);
  };

  const reset = () => {
    setPayments([]);
    updateVinculos('');
    reload();
  };

  return (
    <PaymentContext.Provider
      value={{
        payments,
        addPayment,
        removePayment,
        clearPayments,
        reset,
        contracts,
        vinculos,
        updateVinculos,
        currentMonthPayments,
        defineCurrentMonthPayments,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
