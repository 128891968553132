import React from 'react';

import { Button, IconButton } from '../../elements';
import { PDFViewer } from '../';
import { appendDots, downloadFileFromData } from 'utils/stringUtils';
import { XAlt } from 'components/icons';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './ModalPDFViewer.module.scss';

type Props = {
  pdfDescription?: string;
  onLoad?: () => void;
  pdfData?: string | ArrayBuffer | null;
  type?: 'view' | 'download';
  title?: string;
  onClose?: () => void;
  onCancel?: () => void;
  onUpload?: () => void;
};

const ModalPDFViewer: React.FC<Props> = ({
  pdfData,
  onLoad,
  pdfDescription = 'file',
  type = 'download',
  title = '',
  onUpload,
  onClose,
  onCancel,
}) => {
  function onDownload() {
    downloadFileFromData(pdfData, pdfDescription);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <Tippy
            content={title}
            className={styles.popup}
            animation="perspective"
            touch={false}
            followCursor="horizontal"
            plugins={[followCursor]}
          >
            <span>{appendDots(title, 80)}</span>
          </Tippy>
          <IconButton icon={XAlt} type="dark" size={28} onClick={onClose} />
        </div>
        {pdfData && <PDFViewer file={pdfData} onLoad={onLoad} />}
        <div className={styles.button}>
          {type === 'download' && (
            <Button onClick={onUpload ?? onDownload}>
              {onUpload ? 'Upload' : 'Download'}
            </Button>
          )}
          {onCancel && (
            <Button onClick={onCancel} type="secondary">
              Cancelar
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalPDFViewer;
