import React, {
  useRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ReactNode,
  useReducer,
} from 'react';

import _, { isEqual } from 'lodash';

import { useTranslation } from 'i18n';
import { useFetch, RequestError } from 'apis';
import { FORM_TIMEOUT_MS } from 'consts';
import * as types from './types';
import * as helpers from './helpers';
import { FormContext, FormObserverContext } from './FormContext';
import Loader from '../elements/Loader/Loader';
import ErrorMessage from '../elements/ErrorMessage/ErrorMessage';
import Modal from '../containers/Modal/Modal';
import InfoDialog from '../containers/Dialog/InfoDialog';
import OptionDialog from '../containers/Dialog/OptionDialog';
import { call, isNil } from 'utils';
import { flattenObject, readAttr, writeAttr } from 'utils/object';
import useTrigger from './useTrigger';
import useAutoSave from './useAutoSave';
import { messageService } from 'services';
import useCallbackDebounce from 'components/hooks/useCallbackDebounce';
import { Patch } from './types';
import { ResponseType } from 'axios';

const DEFAULT_DISPLAY: types.FieldDisplaySettings = {
  error: 'touched',
  success: 'modified',
};

export type Props = {
  name?: string;
  baseUrl?: string;
  validateUrl?: string;
  fetchParams?: object;
  submit?: SubmitProps;
  onSubmit?: (data: { requestBody: object; section: string }) => void;
  onDone?: (data: { requestBody?: any; responseBody?: any; section?: string }) => void;
  onError?: (error: RequestError<any>, instance: types.FormInstance) => 'handled' | void;
  onStash?: (params: { editable: boolean }) => void;
  onPopulate?: (values: { [key: string]: any }, instance: types.FormInstance) => void;
  confirm?: (submit: (proceed: boolean) => void) => JSX.Element;
  populate?: number | string | { [key: string]: string };
  metadata?: types.FormMetadata;
  editable?: boolean;
  autosave?: boolean;
  display?: Partial<types.FieldDisplaySettings>;
  children?: ReactNode;
  showDiff?: boolean;
  hotReload?: boolean;
};

export type SubmitProps = {
  format?: 'json' | 'multipart';
  ignoreUrlId?: boolean;
  method?: 'post' | 'put';
  responseType?: ResponseType;
};

type ImmutableRef<T> = { readonly current: T };

type FormStatus =
  | 'loading'
  | 'loading-stealth'
  | 'loaded'
  | 'init'
  | 'idle'
  | 'pending'
  | 'confirming'
  | 'submitting'
  | 'warning'
  | 'error'
  | 'critical'
  | 'done';

type State = {
  status: FormStatus;
  editable: boolean;
  showDiff: boolean;
  display: types.FieldDisplaySettings;
  focusField?: types.FieldData;
};

type Action =
  | { type: 'CHANGE_STATUS'; payload: FormStatus }
  | { type: 'RESET' }
  | {
      type: 'SHOW_ISSUES';
      payload: { status: 'error' | 'warning'; focusField?: types.FieldData };
    }
  | { type: 'SET_EDITABLE'; payload: boolean }
  | { type: 'SET_SHOW_DIFF'; payload: boolean }
  | {
      type: 'SET_DISPLAY';
      payload: Partial<types.FieldDisplaySettings>;
    };

const reducer = (state: State, action: Action): State => {
  let newState: State;

  switch (action.type) {
    case 'CHANGE_STATUS':
      newState = {
        ...state,
        status: action.payload,
        focusField: action.payload === 'idle' ? undefined : state.focusField,
      };

      break;

    case 'RESET':
      newState = {
        ...state,
        status: 'idle',
        display: { ...state.display, ...DEFAULT_DISPLAY },
      };

      break;

    case 'SHOW_ISSUES':
      newState = {
        ...state,
        status: action.payload.status,
        focusField: action.payload.focusField,
        display: { ...state.display, error: 'always', success: 'always' },
      };

      break;

    case 'SET_EDITABLE':
      newState = { ...state, editable: action.payload };
      break;

    case 'SET_SHOW_DIFF':
      newState = { ...state, showDiff: action.payload };
      break;

    case 'SET_DISPLAY':
      newState = {
        ...state,
        display: { ...state.display, ...action.payload },
      };

      break;

    default:
      return state;
  }

  return _.isEqual(state, newState) ? state : newState;
};

const Form = forwardRef<types.FormInstance, Props>((props, ref) => {
  const [{ status, editable, showDiff, focusField, display }, dispatch] = useReducer(
    reducer,
    {
      status: isNil(props.populate) ? 'init' : 'loading',
      editable: props.editable === undefined ? true : props.editable,
      showDiff: props.showDiff === undefined ? false : props.showDiff,
      display: { ...DEFAULT_DISPLAY, optional: !props.autosave, ...props.display },
    }
  );

  const data: ImmutableRef<types.FormValues> = useRef<types.FormValues>({});
  const stashValues = useRef<{ [key: string]: any }>();

  const values: ImmutableRef<{ [key: string]: any }> = useRef<{
    [key: string]: any;
  }>({});

  const lastValues: ImmutableRef<{ [key: string]: any }> = useRef<{
    [key: string]: any;
  }>({});

  const observers = useRef<types.FormObserver[]>([]);
  const oldDisplay = useRef(display);
  const disableUpdates = useRef(false);

  const { baseUrl, fetchParams, populate, hotReload } = props;
  const { t } = useTranslation();

  const fetchUrl = baseUrl && !props.metadata ? baseUrl + '/metadata' : undefined;

  const {
    request,
    data: fetchMetadata,
    error,
  } = useFetch<types.FormMetadata>(fetchUrl, fetchParams);

  const metadata = props.metadata || fetchMetadata;

  const stashTrigger = useTrigger(props.onStash);
  const submitTrigger = useTrigger(props.onSubmit);
  const doneTrigger = useTrigger(props.onDone);

  const setFieldValue = useCallback(
    (fieldName: string, value: any, create?: boolean, update?: boolean) => {
      if (data.current[fieldName]) {
        if (!update && data.current[fieldName].setValue) {
          call(data.current[fieldName]?.setValue, value);
        } else if (create || update) {
          data.current[fieldName] = {
            value,
            valid: true,
            controlState: {
              touched: true,
            },
          };
        } else {
          console.warn(`setValue does not exist in ${fieldName}`);
        }
      } else if (create) {
        data.current[fieldName] = {
          value,
          valid: true,
          controlState: {
            touched: true,
          },
        };
      } else {
        console.warn(`Field name ${fieldName} does not exist`);
      }
    },
    []
  );

  const setFormValues = useCallback(
    (
      values: { [key: string]: any },
      create?: boolean,
      update?: boolean,
      ignoreNull?: boolean
    ) => {
      const newObject = flattenObject(values);

      for (const key in newObject) {
        let value = newObject[key];
        if (value === null && ignoreNull) continue;
        setFieldValue(key, value, create, update);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (status === 'loading' || status === 'loading-stealth') {
      let url, params;

      if (typeof populate === 'number' || typeof populate === 'string') {
        url = `${baseUrl}/${populate}`;
      } else {
        url = baseUrl;
        params = populate;
      }
      const source = request<{ [key: string]: any }>({
        url,
        params,
        onSuccess: data => {
          const currentData = data?.current !== undefined ? data.current : data;
          for (const key in currentData) {
            writeAttr(key, currentData[key], values.current, false, true);
          }
          if (status === 'loading-stealth') {
            setFormValues(values.current, false, true);
          }
          if (data?.last !== undefined) {
            const oldData = data.last;
            for (const key in oldData) {
              lastValues.current[key] = oldData[key];
            }
          }

          dispatch({ type: 'CHANGE_STATUS', payload: 'loaded' });
        },
        onError: () => {
          dispatch({ type: 'CHANGE_STATUS', payload: 'critical' });
        },
      });
      return () => source.cancel();
    }
  }, [baseUrl, populate, request, status, showDiff, setFormValues]);

  useEffect(() => {
    const key = {};
    for (const name in data.current) {
      call(data.current[name].refreshType, key);
    }

    stashTrigger({ editable });
  }, [editable, stashTrigger]);

  useEffect(() => {
    if (props.display) {
      dispatch({ type: 'SET_DISPLAY', payload: props.display });
    }
  }, [props.display]);

  useEffect(() => {
    if (metadata && fetchUrl && status === 'loaded') {
      dispatch({ type: 'CHANGE_STATUS', payload: 'init' });
      call(props.onPopulate, values.current, instance.current);
    }
  }, [fetchUrl, metadata, props.onPopulate, status]);

  const subscribe = useCallback(
    (callback: types.FormObserverCallback, options?: types.FormObserverOptions) => {
      observers.current.push({ callback, options });
    },
    []
  );

  const unsubscribe = useCallback((callback: types.FormObserverCallback) => {
    observers.current = observers.current.filter(
      observer => observer.callback !== callback
    );
  }, []);

  const checkAsync = useCallback(
    (pending: string[], check: (checkPending: boolean) => void) => {
      const complete = () => {
        unsubscribe(callback);
        check(false);
      };

      const callback = (res: types.FormObserverResult) => {
        if (res.name) {
          const field = data.current[res.name];

          if (field && (!field.issue || field.issue.type !== 'pending')) {
            const index = pending.indexOf(res.name);

            if (index >= 0) {
              pending.splice(index, 1);
            }

            if (pending.length === 0) {
              clearTimeout(timer);
              complete();
            }
          }
        }
      };

      const timer = setTimeout(complete, FORM_TIMEOUT_MS);
      subscribe(callback);
    },
    [subscribe, unsubscribe]
  );

  const reset = useCallback(() => {
    for (const name in data.current) {
      call(data.current[name].reset);
    }

    dispatch({ type: 'RESET' });
  }, []);

  const getFieldMetadata = useCallback(
    (fieldName: string, attributes?: types.FieldMetadata): types.FieldMetadata => {
      const fmd: types.FieldMetadata = { name: fieldName };
      const groupName = helpers.removeIndices(fieldName);

      fmd.rules = readAttr(`rules.${groupName}`, metadata);
      fmd.options = readAttr(`options.${groupName}`, metadata);

      if (fmd.rules && fmd.rules.async && props.baseUrl) {
        fmd.validationUrl =
          attributes?.validationUrl || props.validateUrl || `${props.baseUrl}/validate`;
      }

      return fmd;
    },
    [metadata, props.baseUrl, props.validateUrl]
  );

  const notifyError = useCallback(() => {
    for (const observer of observers.current) {
      helpers.notifyObserver(observer, {}, 'onError');
    }
  }, []);

  const submit = useCallback(
    (section?: string) => {
      const isMultipart = props.submit?.format === 'multipart';
      const requestBody = isMultipart
        ? helpers.getFormData(data.current, section)
        : helpers.getJsonBody(data.current, section);

      dispatch({ type: 'CHANGE_STATUS', payload: 'submitting' });
      submitTrigger({ requestBody, section });

      if (!props.baseUrl) {
        dispatch({ type: 'CHANGE_STATUS', payload: 'done' });
        doneTrigger({ requestBody, section });
        return;
      }

      const onSuccess = (res: any) => {
        if (res.status === 'failed' && !res.rejected?.section?.errors) {
          let focusField: types.FieldData | undefined;

          if (metadata) {
            const names = helpers.addRejected(res.rejected, metadata.rules);

            for (const name of names) {
              if (data.current[name]) {
                call(data.current[name].setMetadata, getFieldMetadata(name));

                if (!focusField) {
                  focusField = data.current[name];
                }
              }
            }
          }

          if (typeof res.rejected.error === 'string') {
            messageService.error(res.rejected.error, {
              duration: 2000,
            });
            notifyError();
            dispatch({ type: 'CHANGE_STATUS', payload: 'done' });
          } else {
            dispatch({ type: 'SHOW_ISSUES', payload: { status: 'error', focusField } });
          }
        } else {
          dispatch({ type: 'CHANGE_STATUS', payload: 'done' });
          doneTrigger({ requestBody, responseBody: res, section });
        }
      };

      const onError = (err: RequestError<any>) => {
        if (call(props.onError, err, instance.current) !== 'handled') {
          if (err.response?.status === 400) {
            const substrError = err.response?.data.error;

            let error = substrError;

            if (error?.code) {
              error = t(`validation.${error.code}`);
            }

            messageService.error(error, {
              duration: 10000,
            });

            dispatch({ type: 'CHANGE_STATUS', payload: 'done' });
            return;
          }
          dispatch({ type: 'CHANGE_STATUS', payload: 'critical' });
        }
      };

      const update = !isNil(data.current.id?.value);
      const method =
        props.submit?.method || (section ? 'patch' : update ? 'put' : 'post');

      request<any>({
        url:
          update && !props.submit?.ignoreUrlId
            ? `${props.baseUrl}/${data.current.id.value}`
            : props.baseUrl,
        method,
        data: requestBody,
        headers: {
          'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
        },
        onSuccess,
        responseType: props.submit?.responseType ?? undefined,
        onError,
      });
    },
    [
      props.submit?.format,
      props.submit?.method,
      props.submit?.ignoreUrlId,
      props.submit?.responseType,
      props.baseUrl,
      props.onError,
      submitTrigger,
      request,
      doneTrigger,
      metadata,
      getFieldMetadata,
      notifyError,
      t,
    ]
  );

  const check = useCallback(
    (checkPending: boolean) => {
      for (const field of Object.keys(data.current)) {
        if (data.current[field].issue && data.current[field].refreshValidation) {
          (data.current[field] as any)?.refreshValidation();
        }
      }

      const { errors, warnings, pending } = helpers.evaluate(data.current);
      if (errors.length) {
        dispatch({
          type: 'SHOW_ISSUES',
          payload: { status: 'error', focusField: errors[0] },
        });
      } else if (checkPending && pending.length) {
        dispatch({ type: 'CHANGE_STATUS', payload: 'pending' });
        checkAsync(pending, check);
      } else if (warnings.length) {
        dispatch({
          type: 'SHOW_ISSUES',
          payload: { status: 'warning', focusField: warnings[0] },
        });
      } else {
        submit();
      }
    },
    [checkAsync, submit]
  );

  const getLastFieldValue = useCallback((fieldName: string) => {
    return readAttr(fieldName, lastValues.current);
  }, []);

  const getSectionFieldValues = useCallback((sectionName: string, fieldName: string) => {
    const values = [];
    for (const key in data.current) {
      const value = data.current[key].value;
      if (
        value !== undefined &&
        data.current[key].section === sectionName &&
        key.endsWith(`.${fieldName}`)
      ) {
        values.push(data.current[key].value);
      }
    }
    return values;
  }, []);

  const getFieldValue = useCallback((fieldName: string) => {
    const fieldData = data.current[fieldName];

    if (fieldData) {
      return fieldData.value;
    }

    const collectionName = helpers.getCollectionName(fieldName, data.current);

    if (collectionName) {
      const collection = helpers.getCollection(collectionName, data.current);
      const value = readAttr(fieldName, collection);

      if (value !== undefined) {
        return value;
      }
    }

    return readAttr(fieldName, values.current);
  }, []);

  const setFieldMetadata = useCallback(
    (fieldName: string, value: types.FieldMetadata, merge?: boolean) => {
      if (metadata) {
        const attributes = value as { [key: string]: any };
        const groupName = helpers.removeIndices(fieldName);

        for (const attr in attributes) {
          writeAttr(`${attr}.${groupName}`, attributes[attr], metadata, merge);
        }

        call(
          data.current[fieldName]?.setMetadata,
          getFieldMetadata(fieldName, attributes)
        );
      }
    },
    [getFieldMetadata, metadata]
  );

  const addRule = useCallback(
    (fieldName: string, ruleName: string, rule: any) => {
      if (metadata) {
        const groupName = helpers.removeIndices(fieldName);
        const rules = readAttr(`rules.${groupName}`, metadata);

        if (rules?.[ruleName]) {
          delete rules[ruleName];
        }

        writeAttr(`rules.${groupName}`, { ...rules, [ruleName]: rule }, metadata);
        call(data.current[fieldName]?.setMetadata, getFieldMetadata(fieldName));
      }
    },
    [getFieldMetadata, metadata]
  );

  const notifySave = useCallback(() => {
    for (const observer of observers.current) {
      helpers.notifyObserver(observer, {}, 'onSave');
    }
  }, []);

  const stealthReload = useCallback(() => {
    dispatch({ type: 'CHANGE_STATUS', payload: 'loading-stealth' });
  }, []);

  const reload = useCallback((stealth?: boolean) => {
    if (!stealth) {
      for (const key in data.current) {
        delete data.current[key];
      }
    }
    dispatch({ type: 'CHANGE_STATUS', payload: stealth ? 'loading-stealth' : 'loading' });
  }, []);
  const onAutoSaveError = useCallback(() => {
    disableUpdates.current = false;
  }, []);

  const handleReload = useCallback(() => {
    reload(!hotReload);
  }, [hotReload, reload]);

  const reloadDebounce = useCallbackDebounce(handleReload, 300);

  const onAutoSaveSuccess = useCallback(
    (_: string, __: any, needsRefresh?: boolean, type?: types.Patch['type']) => {
      notifySave();
      disableUpdates.current = false;
      if (needsRefresh || (values.current['shouldRefresh'] && hotReload)) {
        reloadDebounce();
      }
      if (type === 'replace') {
        writeAttr(_, __, values.current, false, true);
      }
    },
    [hotReload, notifySave, reloadDebounce]
  );

  const notifyUnsaved = useAutoSave(
    props.autosave ? 800 : 0,
    baseUrl,
    data.current,
    onAutoSaveError,
    onAutoSaveSuccess
  );

  const notifyUnsavedWrapper = useCallback(
    (patch?: Patch) => {
      if (disableUpdates.current) {
        return;
      }
      if (values.current['shouldRefresh'] && hotReload) {
        disableUpdates.current = true;
        dispatch({ type: 'SET_EDITABLE', payload: false });
      }
      notifyUnsaved(patch);
    },
    [hotReload, notifyUnsaved]
  );

  const updateFieldData = useCallback(
    (name: string, newData?: types.FieldData) => {
      const oldData = data.current[name];

      if (newData) {
        if (newData.value === '') {
          newData.value = undefined;
        }

        newData.unsaved =
          (editable || (disableUpdates.current && hotReload)) &&
          props.autosave &&
          newData.autosave !== false &&
          !newData.omit &&
          oldData &&
          (!isEqual(oldData.value, newData.value) || oldData.unsaved) &&
          (oldData.unsaved !== undefined ||
            !isEqual(newData.value, readAttr(name, values.current)));

        data.current[name] = newData;

        if (newData.unsaved) {
          if (newData.valid) {
            notifyUnsavedWrapper();
          } else {
            notifyUnsaved();
          }
        }
      } else {
        delete data.current[name];
      }
      for (const observer of observers.current) {
        helpers.notifyObserver(observer, data.current, name, oldData, newData);
      }
      const oldValue = readAttr('value', oldData);
      const newValue = readAttr('value', newData);

      if (oldValue !== newValue) {
        const fieldName = helpers.removeIndices(name);
        const dependents = readAttr(`rules.${fieldName}.dependents`, metadata);

        if (dependents) {
          for (const el of dependents) {
            call(data.current[el]?.refreshValidation, { dependency: name });
          }
        }
      }
    },
    [editable, hotReload, props.autosave, notifyUnsavedWrapper, notifyUnsaved, metadata]
  );

  const clearSection = useCallback(
    (sectionName: string) => {
      const names: string[] = [];

      for (const key in data.current) {
        if (data.current[key].section === sectionName) {
          names.push(key);
        }
      }

      names.forEach(name => updateFieldData(name));
    },
    [updateFieldData]
  );

  const unmountField = useCallback((name: string) => {
    const field = data.current[name] as any;

    if (field) {
      const unmountedData: { [key: string]: any } = {};

      for (const prop in field) {
        if (typeof field[prop] !== 'function' || prop === 'setValue') {
          unmountedData[prop] = field[prop];
        }
      }
      data.current[name] = unmountedData;
    }
  }, []);

  const removeRejected = useCallback(
    (name: string) => {
      const fieldMetadata = { ...getFieldMetadata(name) };
      if (fieldMetadata?.rules && 'rejected' in fieldMetadata.rules) {
        delete fieldMetadata?.rules['rejected'];
        setFieldMetadata(name, fieldMetadata);
      }
    },
    [getFieldMetadata, setFieldMetadata]
  );

  const clearField = useCallback((fieldName: string) => {
    const validate = (val: any) => val !== undefined;

    if (validate(readAttr(fieldName, values.current, undefined, validate))) {
      writeAttr(fieldName, null, values.current, false, true);
    }

    const changes = helpers.removeField(fieldName, data.current);

    for (const observer of observers.current) {
      for (const key in changes) {
        const { oldData, newData } = changes[key];
        helpers.notifyObserver(observer, data.current, key, oldData, newData);
      }
    }
  }, []);

  const stash = useCallback(
    (op: types.FormStashOp) => {
      switch (op) {
        case 'push':
          stashValues.current = {};

          for (const key in data.current) {
            stashValues.current[key] = data.current[key].value;
          }

          break;

        case 'pop':
          if (stashValues.current) {
            for (const key in stashValues.current) {
              setFieldValue(key, stashValues.current[key]);
            }

            stashValues.current = undefined;
          }

          break;

        case 'clear':
          stashValues.current = undefined;
          break;
      }
    },
    [setFieldValue]
  );

  const addListItem = useCallback(
    (
      listName: string,
      index: number,
      onComplete: (success: boolean, res?: types.PatchResponse) => void,
      autosaveData?: object
    ) => {
      const apply = (index: number, id?: number) => {
        const listValue = readAttr(listName, values.current);

        if (listValue) {
          listValue.splice(index, 0, { id });
        }
        helpers.insertIndex(listName, data.current, index);
      };
      if (props.autosave) {
        const refId = data.current[`${listName}[${index - 1}].id`];
        const g = listName.match(/(.+\[\d*\])\.[^[]+$/);
        let query;

        if (Array.isArray(g) && g[1]) {
          const parentId = data.current[`${g[1]}.id`];

          if (parentId?.value) {
            query = { id: parentId.value as number };
          }
        }

        notifyUnsavedWrapper({
          type: 'add',
          path: listName,
          value: refId?.value,
          data: autosaveData,
          query,
          callback: res => {
            if (
              res?.status === 'success' &&
              !isNil(res.response?.id) &&
              !isNil(res.response?.ordem)
            ) {
              const { id, ordem } = res.response as {
                id: number;
                ordem: number;
              };
              const fieldName = `${listName}[${ordem}].id`;

              apply(ordem, id);

              if (!data.current[fieldName]) {
                data.current[fieldName] = {};
              }

              data.current[fieldName].value = id;
              if (autosaveData) {
                for (const key in autosaveData) {
                  const fieldName = `${listName}[${ordem}].${key}`;
                  data.current[fieldName] = {
                    value: readAttr(key, autosaveData),
                  };
                }
              }

              onComplete(true, res);
            } else {
              onComplete(false, res);
            }
          },
        });
      } else {
        apply(index);
        onComplete(true);
      }
    },
    [notifyUnsavedWrapper, props.autosave]
  );

  const removeListItem = useCallback(
    (
      listName: string,
      index: number,
      onComplete: (success: boolean, res?: types.PatchResponse) => void
    ) => {
      if (props.autosave) {
        const refId = data.current[`${listName}[${index}].id`];
        const g = listName.match(/(.+\[\d*\])\.[^[]+$/);
        let query;

        if (Array.isArray(g) && g[1]) {
          const parentId = data.current[`${g[1]}.id`];

          if (parentId?.value) {
            query = { id: parentId.value as number };
          }
        }

        notifyUnsavedWrapper({
          type: 'remove',
          path: listName,
          value: refId?.value,
          query,
          callback: res => {
            if (res?.status === 'success') {
              clearField(`${listName}[${index}]`);
              onComplete(true, res);
            } else {
              onComplete(false, res);
            }
          },
        });
      } else {
        clearField(`${listName}[${index}]`);
        onComplete(true);
      }
    },
    [clearField, notifyUnsavedWrapper, props.autosave]
  );

  const getCollection = useCallback(
    (name: string) => helpers.getCollection(name, data.current),
    []
  );

  const getLastCollection = useCallback((name: string) => {
    const collection = readAttr(name, lastValues.current);

    if (collection) {
      return {
        [name]: collection,
      };
    }
    return {
      [name]: undefined,
    };
  }, []);

  const getCurrentCollection = useCallback((name: string) => {
    const collection = readAttr(name, values.current);

    if (collection) {
      return {
        [name]: collection,
      };
    }
    return {
      [name]: undefined,
    };
  }, []);

  const isValid = useCallback(
    (callback: (res: boolean) => void, checkPending?: boolean) => {
      const { errors, pending } = helpers.evaluate(data.current);

      if (errors.length) {
        callback(false);
      } else if (checkPending && pending.length) {
        checkAsync(pending, () => {
          isValid(callback, false);
        });
      } else {
        callback(true);
      }
    },
    [checkAsync]
  );

  const hasLastValues = useCallback(() => {
    return lastValues.current !== undefined && !!Object.keys(lastValues.current).length;
  }, []);

  const getFieldData = useCallback((name: string) => data.current[name], []);

  const publicSubmit = useCallback(() => {
    if (props.confirm) {
      dispatch({ type: 'CHANGE_STATUS', payload: 'confirming' });
    } else {
      check(true);
    }
  }, [check, props.confirm]);

  const formObserverContextValue: ImmutableRef<types.FormObserverContextValue> = useRef({
    subscribe,
    unsubscribe,
    values: data.current,
    initialValues: values.current,
    display,
  });

  const formContextValue: ImmutableRef<types.FormContextValue> = useRef({
    submit: publicSubmit,
    display,
    getLastFieldValue,
    getFieldValue,
    getFieldMetadata,
    getSectionFieldValues,
    setFieldMetadata,
    updateFieldData,
    unmountField,
    clearSection,
    addRule,
    notifySave,
    setFieldValue,
    clearField,
    stash,
    editable,
    showDiff,
    setEditable: (value: boolean) => dispatch({ type: 'SET_EDITABLE', payload: value }),
    setShowDiff: (value: boolean) => dispatch({ type: 'SET_SHOW_DIFF', payload: value }),
    reset,
    addListItem,
    removeListItem,
    getCollection,
    getLastCollection,
    getCurrentCollection,
    reload,
    isValid,
    hasLastValues,
    getFieldData,
    setDisplay: (display: Partial<types.FieldDisplaySettings>) =>
      dispatch({ type: 'SET_DISPLAY', payload: display }),
    setFormValues,
    removeRejected,
    stealthReload,
  });

  formObserverContextValue.current.subscribe = subscribe;
  formObserverContextValue.current.unsubscribe = unsubscribe;
  formObserverContextValue.current.values = data.current;
  formObserverContextValue.current.initialValues = values.current;
  formObserverContextValue.current.display = display;

  formContextValue.current.submit = publicSubmit;
  formContextValue.current.display = display;
  formContextValue.current.getLastFieldValue = getLastFieldValue;
  formContextValue.current.getFieldValue = getFieldValue;
  formContextValue.current.getFieldMetadata = getFieldMetadata;
  formContextValue.current.setFieldMetadata = setFieldMetadata;
  formContextValue.current.updateFieldData = updateFieldData;
  formContextValue.current.unmountField = unmountField;
  formContextValue.current.clearSection = clearSection;
  formContextValue.current.addRule = addRule;
  formContextValue.current.setFieldValue = setFieldValue;
  formContextValue.current.clearField = clearField;
  formContextValue.current.stash = stash;
  formContextValue.current.editable = editable;
  formContextValue.current.showDiff = showDiff;
  formContextValue.current.reset = reset;
  formContextValue.current.addListItem = addListItem;
  formContextValue.current.removeListItem = removeListItem;
  formContextValue.current.getCollection = getCollection;
  formContextValue.current.getLastCollection = getLastCollection;
  formContextValue.current.getCurrentCollection = getCurrentCollection;
  formContextValue.current.reload = reload;
  formContextValue.current.stealthReload = stealthReload;
  formContextValue.current.isValid = isValid;
  formContextValue.current.getFieldData = getFieldData;
  formContextValue.current.notifySave = notifySave;
  formContextValue.current.setFormValues = setFormValues;
  formContextValue.current.removeRejected = removeRejected;

  const instance = useRef<types.FormInstance>({
    name: props.name,
    context: formContextValue.current,
    observer: formObserverContextValue.current,
  });

  instance.current.name = props.name;
  useImperativeHandle(ref, () => instance.current);

  if (error || status === 'critical') {
    return <ErrorMessage />;
  }

  if (status === 'loading' || (!metadata && fetchUrl)) {
    return <Loader type="bubbles" />;
  }

  function onDialogClose() {
    if (focusField) {
      call(focusField.focus);
    }

    dispatch({ type: 'CHANGE_STATUS', payload: 'idle' });
  }

  let refreshContext = false;

  if (oldDisplay.current !== display) {
    refreshContext = true;
    oldDisplay.current = display;
  }

  return (
    <FormContext.Provider
      value={refreshContext ? { ...formContextValue.current } : formContextValue.current}
    >
      <FormObserverContext.Provider value={formObserverContextValue.current}>
        {props.children}

        <Modal
          visible={status === 'pending' || status === 'submitting'}
          minWidth={false}
          delay={500}
        >
          <Loader
            type="grid"
            delay={false}
            message={t(`components.form.async.${status}`, { defaultValue: ' ' })}
          />
        </Modal>

        {status === 'confirming' &&
          typeof props.confirm === 'function' &&
          props.confirm(proceed => {
            proceed ? check(true) : dispatch({ type: 'CHANGE_STATUS', payload: 'idle' });
          })}

        <InfoDialog
          visible={status === 'error'}
          title={t('components.form.dialog.error.title')}
          buttonLabel={t('components.form.dialog.error.button')}
          message={t('components.form.dialog.error.message')}
          onClose={onDialogClose}
        />

        <OptionDialog
          visible={status === 'warning'}
          title={t('components.form.dialog.warning.title')}
          message={t('components.form.dialog.warning.message')}
          options={[
            { text: t('components.form.dialog.warning.option.cancel'), value: 1 },
            { text: t('components.form.dialog.warning.option.submit'), value: 2 },
          ]}
          onSelect={value => (value === 1 ? onDialogClose() : submit())}
        />
      </FormObserverContext.Provider>
    </FormContext.Provider>
  );
});

export default Form;
