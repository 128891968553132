import React, { CSSProperties } from 'react';

import { TableSortLabel, TableFilterLabel } from 'components/elements';
import { CellConfig, DispatchFilter, StateFilter } from './types';
import { Instance } from './TableList';
import { appendDots } from 'utils/stringUtils';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './HeaderCell.module.scss';

type Props = {
  stateFilter?: StateFilter;
  dispatchFilter?: DispatchFilter;
  instance?: React.MutableRefObject<Instance>;
  config: CellConfig;
  level: number;
  boldHeaders?: boolean;
};

const HeaderCell = ({
  config,
  stateFilter,
  level,
  dispatchFilter,
  instance,
  boldHeaders = false,
}: Props) => {
  const style: CSSProperties = {
    fontFamily: 'Open Sans',
    color: '#5D5A71',
    letterSpacing: '0.8px',
    fontWeight: '600',
  };

  if (config.headerBold) {
    style.fontWeight = '800';
  }

  if (level) {
    style.fontSize = '0.9em';
  }

  if (config.align) {
    style.textAlign = config.align;
  }

  const isEncargo = config.title === 'Encargos';

  const fontWeight = boldHeaders ? 800 : 600;

  const renderTitle = () => {
    return config.title &&
      config.weight &&
      config?.weight <= 0.5 &&
      config?.title?.length > 15 ? (
      <Tippy
        content={config.title}
        className={styles.popup}
        animation="perspective"
        touch={false}
        followCursor="horizontal"
        plugins={[followCursor]}
      >
        <span style={{ padding: '5px' }}>{appendDots(config.title as string, 10)}</span>
      </Tippy>
    ) : (
      <span style={{ padding: '5px' }}>{config.title}</span>
    );
  };

  const renderCell = () => {
    if (config.search) {
      return (
        <div style={{ textAlign: 'left', fontWeight }}>
          {dispatchFilter && stateFilter && config.field && config.sort !== false ? (
            <TableSortLabel
              field={config.field}
              stateFilter={stateFilter}
              dispatchFilter={dispatchFilter}
              instance={instance}
              initialDirection={config.sortDir}
            >
              {renderTitle()}
            </TableSortLabel>
          ) : (
            <Tippy
              content={config.title}
              className={styles.popup}
              animation="perspective"
              touch={false}
              followCursor="horizontal"
              plugins={[followCursor]}
            >
              {renderTitle()}
            </Tippy>
          )}
          {dispatchFilter && stateFilter && config.field && (
            <TableFilterLabel
              name={config.field}
              observerName={config.observerField}
              metadata={config.metadata}
              stateFilter={stateFilter}
              dispatchFilter={dispatchFilter}
              instance={instance}
              type={config.searchType}
              pattern={config.pattern}
            />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: isEncargo ? 'center' : config.align || 'left',
            fontWeight,
          }}
        >
          {dispatchFilter && stateFilter && config.field && config.sort !== false ? (
            <TableSortLabel
              field={config.field}
              stateFilter={stateFilter}
              dispatchFilter={dispatchFilter}
              instance={instance}
              initialDirection={config.sortDir}
            >
              {config.title}
            </TableSortLabel>
          ) : (
            <span style={{ fontWeight }}>{config.title}</span>
          )}
        </div>
      );
    }
  };

  return (
    <th
      rowSpan={config.rowspan}
      colSpan={config.colspan}
      style={style}
      ref={ref =>
        ref && ref.parentElement
          ? (ref.style.top = `${
              ref.parentElement!.getBoundingClientRect().top -
              ref.parentElement!.parentElement!.getBoundingClientRect().top
            }px`)
          : null
      }
    >
      {renderCell()}
    </th>
  );
};

export default HeaderCell;
