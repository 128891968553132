import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, Select, MultiSelect, Autocomplete } from 'components/form';
import { SectionProps } from '../types';
import useBond from './useBond';

const Student = ({ dispatch }: SectionProps) => {
  useBond(dispatch);
  const { t } = useTranslation('register');

  return (
    <Grid width={580}>
      <Autocomplete
        url="/cadastro/instituicoes"
        name="instituicao"
        label={t('academic.institution')}
      />

      <Row width={[7, 3]}>
        <TextField name="curso" label={t('student.program')} />
        <Select name="nivelCurso" label={t('student.level')} />
      </Row>

      <Row width={[7, 3]}>
        <TextField name="lattes" label={t('academic.resume')} />
        <TextField name="matricula" label={t('student.id')} />
      </Row>

      <MultiSelect
        name="areaPesquisa"
        label={t('academic.areas')}
        modalTitle={t('academic.areas')}
      />
    </Grid>
  );
};

export default Student;
